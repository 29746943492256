/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
};

export enum Access {
  PlatformAdmin = 'PLATFORM_ADMIN'
}

export type Activity = {
  __typename?: 'Activity';
  createdAt: Scalars['DateTime']['output'];
  entityId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  resourceId?: Maybe<Scalars['String']['output']>;
  type: ActivityType;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type ActivityCreateManyEntityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type: ActivityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type ActivityCreateManyEntityInputEnvelope = {
  data: Array<ActivityCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type: ActivityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type ActivityCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entityId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type: ActivityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityCreateManyUserInputEnvelope = {
  data: Array<ActivityCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutEntityInput>>;
  createMany?: InputMaybe<ActivityCreateManyEntityInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityCreateManyUserInputEnvelope>;
};

export type ActivityCreateOrConnectWithoutEntityInput = {
  create: ActivityCreateWithoutEntityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutUserInput = {
  create: ActivityCreateWithoutUserInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateWithoutEntityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type: ActivityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutActivityInput;
};

export type ActivityCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityCreateNestedOneWithoutActivityInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type: ActivityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityListRelationFilter = {
  every?: InputMaybe<ActivityWhereInput>;
  none?: InputMaybe<ActivityWhereInput>;
  some?: InputMaybe<ActivityWhereInput>;
};

export type ActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  resourceId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type ActivityResponse = {
  __typename?: 'ActivityResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Activity>;
};

export enum ActivityScalarFieldEnum {
  CreatedAt = 'createdAt',
  EntityId = 'entityId',
  Id = 'id',
  ResourceId = 'resourceId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  resourceId?: InputMaybe<UuidNullableFilter>;
  type?: InputMaybe<EnumActivityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export enum ActivityType {
  ArchiveAttachment = 'ARCHIVE_ATTACHMENT',
  ArchiveBankStatement = 'ARCHIVE_BANK_STATEMENT',
  ArchiveEntity = 'ARCHIVE_ENTITY',
  ArchiveExpense = 'ARCHIVE_EXPENSE',
  ArchiveInvoice = 'ARCHIVE_INVOICE',
  ArchiveReport = 'ARCHIVE_REPORT',
  CreateAttachment = 'CREATE_ATTACHMENT',
  CreateBankStatement = 'CREATE_BANK_STATEMENT',
  CreateEntity = 'CREATE_ENTITY',
  CreateExpense = 'CREATE_EXPENSE',
  CreateIncomeData = 'CREATE_INCOME_DATA',
  CreateInvite = 'CREATE_INVITE',
  CreateInvoice = 'CREATE_INVOICE',
  CreateManyAttachments = 'CREATE_MANY_ATTACHMENTS',
  CreateManyBankStatements = 'CREATE_MANY_BANK_STATEMENTS',
  CreateManyExpenses = 'CREATE_MANY_EXPENSES',
  CreateManyInvoices = 'CREATE_MANY_INVOICES',
  CreateReport = 'CREATE_REPORT',
  DeleteInvite = 'DELETE_INVITE',
  DownloadAttachment = 'DOWNLOAD_ATTACHMENT',
  DownloadBankStatement = 'DOWNLOAD_BANK_STATEMENT',
  DownloadExpense = 'DOWNLOAD_EXPENSE',
  DownloadIncomeData = 'DOWNLOAD_INCOME_DATA',
  DownloadInvoice = 'DOWNLOAD_INVOICE',
  DownloadReport = 'DOWNLOAD_REPORT',
  ForwardExpense = 'FORWARD_EXPENSE',
  ForwardInvoice = 'FORWARD_INVOICE',
  ProcessAttachment = 'PROCESS_ATTACHMENT',
  ProcessBankStatement = 'PROCESS_BANK_STATEMENT',
  ProcessExpense = 'PROCESS_EXPENSE',
  ProcessInvoice = 'PROCESS_INVOICE',
  ReadAttachment = 'READ_ATTACHMENT',
  ReadBankStatement = 'READ_BANK_STATEMENT',
  ReadEntity = 'READ_ENTITY',
  ReadExpense = 'READ_EXPENSE',
  ReadIncomeData = 'READ_INCOME_DATA',
  ReadInvoice = 'READ_INVOICE',
  ReadReport = 'READ_REPORT',
  UpdateAttachment = 'UPDATE_ATTACHMENT',
  UpdateBankStatement = 'UPDATE_BANK_STATEMENT',
  UpdateEntity = 'UPDATE_ENTITY',
  UpdateExpense = 'UPDATE_EXPENSE',
  UpdateIncomeData = 'UPDATE_INCOME_DATA',
  UpdateInvoice = 'UPDATE_INVOICE',
  UpdateReport = 'UPDATE_REPORT'
}

export type ActivityUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ActivityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityUpdateManyWithWhereWithoutEntityInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutUserInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutEntityInput>>;
  createMany?: InputMaybe<ActivityCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type ActivityUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ActivityUpdateWithWhereUniqueWithoutEntityInput = {
  data: ActivityUpdateWithoutEntityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutUserInput = {
  data: ActivityUpdateWithoutUserInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithoutEntityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ActivityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutActivityNestedInput>;
};

export type ActivityUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneWithoutActivityNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ActivityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityUpsertWithWhereUniqueWithoutEntityInput = {
  create: ActivityCreateWithoutEntityInput;
  update: ActivityUpdateWithoutEntityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutUserInput = {
  create: ActivityCreateWithoutUserInput;
  update: ActivityUpdateWithoutUserInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityWhereInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entity?: InputMaybe<EntityNullableRelationFilter>;
  entityId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  resourceId?: InputMaybe<UuidNullableFilter>;
  type?: InputMaybe<EnumActivityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type ActivityWhereUniqueInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entity?: InputMaybe<EntityNullableRelationFilter>;
  entityId?: InputMaybe<UuidNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  resourceId?: InputMaybe<UuidNullableFilter>;
  type?: InputMaybe<EnumActivityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type Attachment = {
  __typename?: 'Attachment';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  entityId: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['String']['output'];
  incomeData?: Maybe<IncomeData>;
  incomeDataId?: Maybe<Scalars['String']['output']>;
  incomeDataQuestion?: Maybe<Scalars['String']['output']>;
  isProcessed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<User>;
  uploaderId?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type AttachmentCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutAttachmentsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedOneWithoutAttachmentsInput>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutAttachmentsInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateManyEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataId?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateManyEntityInputEnvelope = {
  data: Array<AttachmentCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AttachmentCreateManyIncomeDataInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateManyIncomeDataInputEnvelope = {
  data: Array<AttachmentCreateManyIncomeDataInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AttachmentCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataId?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateManyUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataId?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateManyUploaderInputEnvelope = {
  data: Array<AttachmentCreateManyUploaderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AttachmentCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutEntityInput>>;
  createMany?: InputMaybe<AttachmentCreateManyEntityInputEnvelope>;
};

export type AttachmentCreateNestedManyWithoutIncomeDataInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutIncomeDataInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutIncomeDataInput>>;
  createMany?: InputMaybe<AttachmentCreateManyIncomeDataInputEnvelope>;
};

export type AttachmentCreateNestedManyWithoutUploaderInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<AttachmentCreateManyUploaderInputEnvelope>;
};

export type AttachmentCreateOrConnectWithoutEntityInput = {
  create: AttachmentCreateWithoutEntityInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentCreateOrConnectWithoutIncomeDataInput = {
  create: AttachmentCreateWithoutIncomeDataInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentCreateOrConnectWithoutUploaderInput = {
  create: AttachmentCreateWithoutUploaderInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentCreateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedOneWithoutAttachmentsInput>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutAttachmentsInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateWithoutIncomeDataInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutAttachmentsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutAttachmentsInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentCreateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutAttachmentsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedOneWithoutAttachmentsInput>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentListRelationFilter = {
  every?: InputMaybe<AttachmentWhereInput>;
  none?: InputMaybe<AttachmentWhereInput>;
  some?: InputMaybe<AttachmentWhereInput>;
};

export type AttachmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AttachmentOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrderInput>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incomeData?: InputMaybe<IncomeDataOrderByWithRelationInput>;
  incomeDataId?: InputMaybe<SortOrderInput>;
  incomeDataQuestion?: InputMaybe<SortOrderInput>;
  isProcessed?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploader?: InputMaybe<UserOrderByWithRelationInput>;
  uploaderId?: InputMaybe<SortOrderInput>;
  year?: InputMaybe<SortOrderInput>;
};

export enum AttachmentScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Date = 'date',
  EntityId = 'entityId',
  File = 'file',
  Id = 'id',
  IncomeDataId = 'incomeDataId',
  IncomeDataQuestion = 'incomeDataQuestion',
  IsProcessed = 'isProcessed',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UploaderId = 'uploaderId',
  Year = 'year'
}

export type AttachmentScalarWhereInput = {
  AND?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  OR?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  incomeDataId?: InputMaybe<UuidNullableFilter>;
  incomeDataQuestion?: InputMaybe<EnumIncomeDataQuestionNullableFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type AttachmentUpdateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutAttachmentsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateOneWithoutAttachmentsNestedInput>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutAttachmentsNestedInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentUpdateManyWithWhereWithoutEntityInput = {
  data: AttachmentUpdateManyMutationInput;
  where: AttachmentScalarWhereInput;
};

export type AttachmentUpdateManyWithWhereWithoutIncomeDataInput = {
  data: AttachmentUpdateManyMutationInput;
  where: AttachmentScalarWhereInput;
};

export type AttachmentUpdateManyWithWhereWithoutUploaderInput = {
  data: AttachmentUpdateManyMutationInput;
  where: AttachmentScalarWhereInput;
};

export type AttachmentUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutEntityInput>>;
  createMany?: InputMaybe<AttachmentCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AttachmentUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<AttachmentUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<AttachmentUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type AttachmentUpdateManyWithoutIncomeDataNestedInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutIncomeDataInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutIncomeDataInput>>;
  createMany?: InputMaybe<AttachmentCreateManyIncomeDataInputEnvelope>;
  delete?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AttachmentUpdateWithWhereUniqueWithoutIncomeDataInput>>;
  updateMany?: InputMaybe<Array<AttachmentUpdateManyWithWhereWithoutIncomeDataInput>>;
  upsert?: InputMaybe<Array<AttachmentUpsertWithWhereUniqueWithoutIncomeDataInput>>;
};

export type AttachmentUpdateManyWithoutUploaderNestedInput = {
  connect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AttachmentCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<AttachmentCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<AttachmentCreateManyUploaderInputEnvelope>;
  delete?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AttachmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  set?: InputMaybe<Array<AttachmentWhereUniqueInput>>;
  update?: InputMaybe<Array<AttachmentUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: InputMaybe<Array<AttachmentUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: InputMaybe<Array<AttachmentUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type AttachmentUpdateWithWhereUniqueWithoutEntityInput = {
  data: AttachmentUpdateWithoutEntityInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentUpdateWithWhereUniqueWithoutIncomeDataInput = {
  data: AttachmentUpdateWithoutIncomeDataInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentUpdateWithWhereUniqueWithoutUploaderInput = {
  data: AttachmentUpdateWithoutUploaderInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentUpdateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateOneWithoutAttachmentsNestedInput>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutAttachmentsNestedInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentUpdateWithoutIncomeDataInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutAttachmentsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutAttachmentsNestedInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentUpdateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutAttachmentsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateOneWithoutAttachmentsNestedInput>;
  incomeDataQuestion?: InputMaybe<IncomeDataQuestion>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentUpsertWithWhereUniqueWithoutEntityInput = {
  create: AttachmentCreateWithoutEntityInput;
  update: AttachmentUpdateWithoutEntityInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentUpsertWithWhereUniqueWithoutIncomeDataInput = {
  create: AttachmentCreateWithoutIncomeDataInput;
  update: AttachmentUpdateWithoutIncomeDataInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentUpsertWithWhereUniqueWithoutUploaderInput = {
  create: AttachmentCreateWithoutUploaderInput;
  update: AttachmentUpdateWithoutUploaderInput;
  where: AttachmentWhereUniqueInput;
};

export type AttachmentWhereInput = {
  AND?: InputMaybe<Array<AttachmentWhereInput>>;
  NOT?: InputMaybe<Array<AttachmentWhereInput>>;
  OR?: InputMaybe<Array<AttachmentWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  incomeData?: InputMaybe<IncomeDataNullableRelationFilter>;
  incomeDataId?: InputMaybe<UuidNullableFilter>;
  incomeDataQuestion?: InputMaybe<EnumIncomeDataQuestionNullableFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type AttachmentWhereUniqueInput = {
  AND?: InputMaybe<Array<AttachmentWhereInput>>;
  NOT?: InputMaybe<Array<AttachmentWhereInput>>;
  OR?: InputMaybe<Array<AttachmentWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataNullableRelationFilter>;
  incomeDataId?: InputMaybe<UuidNullableFilter>;
  incomeDataQuestion?: InputMaybe<EnumIncomeDataQuestionNullableFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type AttachmentsResponse = {
  __typename?: 'AttachmentsResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Attachment>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user: User;
};

export type BankStatement = {
  __typename?: 'BankStatement';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  entityId: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isProcessed: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<User>;
  uploaderId?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type BankStatementCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutBankStatementsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutBankStatementsInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementCreateManyEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementCreateManyEntityInputEnvelope = {
  data: Array<BankStatementCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BankStatementCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementCreateManyUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementCreateManyUploaderInputEnvelope = {
  data: Array<BankStatementCreateManyUploaderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BankStatementCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankStatementCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<BankStatementCreateWithoutEntityInput>>;
  createMany?: InputMaybe<BankStatementCreateManyEntityInputEnvelope>;
};

export type BankStatementCreateNestedManyWithoutUploaderInput = {
  connect?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankStatementCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<BankStatementCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<BankStatementCreateManyUploaderInputEnvelope>;
};

export type BankStatementCreateOrConnectWithoutEntityInput = {
  create: BankStatementCreateWithoutEntityInput;
  where: BankStatementWhereUniqueInput;
};

export type BankStatementCreateOrConnectWithoutUploaderInput = {
  create: BankStatementCreateWithoutUploaderInput;
  where: BankStatementWhereUniqueInput;
};

export type BankStatementCreateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutBankStatementsInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementCreateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutBankStatementsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementListRelationFilter = {
  every?: InputMaybe<BankStatementWhereInput>;
  none?: InputMaybe<BankStatementWhereInput>;
  some?: InputMaybe<BankStatementWhereInput>;
};

export type BankStatementOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BankStatementOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrderInput>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isProcessed?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  uploader?: InputMaybe<UserOrderByWithRelationInput>;
  uploaderId?: InputMaybe<SortOrderInput>;
  year?: InputMaybe<SortOrderInput>;
};

export enum BankStatementScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Date = 'date',
  EntityId = 'entityId',
  File = 'file',
  Id = 'id',
  IsProcessed = 'isProcessed',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UploaderId = 'uploaderId',
  Year = 'year'
}

export type BankStatementScalarWhereInput = {
  AND?: InputMaybe<Array<BankStatementScalarWhereInput>>;
  NOT?: InputMaybe<Array<BankStatementScalarWhereInput>>;
  OR?: InputMaybe<Array<BankStatementScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type BankStatementUpdateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutBankStatementsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutBankStatementsNestedInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementUpdateManyWithWhereWithoutEntityInput = {
  data: BankStatementUpdateManyMutationInput;
  where: BankStatementScalarWhereInput;
};

export type BankStatementUpdateManyWithWhereWithoutUploaderInput = {
  data: BankStatementUpdateManyMutationInput;
  where: BankStatementScalarWhereInput;
};

export type BankStatementUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankStatementCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<BankStatementCreateWithoutEntityInput>>;
  createMany?: InputMaybe<BankStatementCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BankStatementScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  set?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  update?: InputMaybe<Array<BankStatementUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<BankStatementUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<BankStatementUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type BankStatementUpdateManyWithoutUploaderNestedInput = {
  connect?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BankStatementCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<BankStatementCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<BankStatementCreateManyUploaderInputEnvelope>;
  delete?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BankStatementScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  set?: InputMaybe<Array<BankStatementWhereUniqueInput>>;
  update?: InputMaybe<Array<BankStatementUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: InputMaybe<Array<BankStatementUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: InputMaybe<Array<BankStatementUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type BankStatementUpdateWithWhereUniqueWithoutEntityInput = {
  data: BankStatementUpdateWithoutEntityInput;
  where: BankStatementWhereUniqueInput;
};

export type BankStatementUpdateWithWhereUniqueWithoutUploaderInput = {
  data: BankStatementUpdateWithoutUploaderInput;
  where: BankStatementWhereUniqueInput;
};

export type BankStatementUpdateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutBankStatementsNestedInput>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementUpdateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutBankStatementsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type BankStatementUpsertWithWhereUniqueWithoutEntityInput = {
  create: BankStatementCreateWithoutEntityInput;
  update: BankStatementUpdateWithoutEntityInput;
  where: BankStatementWhereUniqueInput;
};

export type BankStatementUpsertWithWhereUniqueWithoutUploaderInput = {
  create: BankStatementCreateWithoutUploaderInput;
  update: BankStatementUpdateWithoutUploaderInput;
  where: BankStatementWhereUniqueInput;
};

export type BankStatementWhereInput = {
  AND?: InputMaybe<Array<BankStatementWhereInput>>;
  NOT?: InputMaybe<Array<BankStatementWhereInput>>;
  OR?: InputMaybe<Array<BankStatementWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type BankStatementWhereUniqueInput = {
  AND?: InputMaybe<Array<BankStatementWhereInput>>;
  NOT?: InputMaybe<Array<BankStatementWhereInput>>;
  OR?: InputMaybe<Array<BankStatementWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
  year?: InputMaybe<IntNullableFilter>;
};

export type BankStatementsResponse = {
  __typename?: 'BankStatementsResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<BankStatement>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type CreateAdminInput = {
  access?: InputMaybe<Array<Access>>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CreateUpdatesAllUsersInput = {
  message: Scalars['String']['input'];
};

export type CreateUpdatesInput = {
  message: Scalars['String']['input'];
  users: Array<Scalars['String']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type EntitiesResponse = {
  __typename?: 'EntitiesResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Entity>;
};

export type Entity = {
  __typename?: 'Entity';
  activity: ActivityResponse;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  attachments: AttachmentsResponse;
  bankStatements: BankStatementsResponse;
  btw?: Maybe<Scalars['String']['output']>;
  btwId?: Maybe<Scalars['String']['output']>;
  clientManager?: Maybe<User>;
  clientManagerId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expenses: ExpensesResponse;
  forwardingEmail?: Maybe<Scalars['String']['output']>;
  hasUpcomingVatDeadline: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  incomeData: IncomeDataResponse;
  invitations: Array<Invitation>;
  invoices: InvoicesResponse;
  isCurrentUserManager: Scalars['Boolean']['output'];
  kvk?: Maybe<Scalars['String']['output']>;
  latestActivity?: Maybe<Activity>;
  name: Scalars['String']['output'];
  pendingIncomeData: Array<IncomeData>;
  pendingReports: Array<Report>;
  reports: ReportsResponse;
  rsin?: Maybe<Scalars['String']['output']>;
  type: EntityType;
  unprocessedDocuments: EntityUnprocessedDocumentsResponse;
  updatedAt: Scalars['DateTime']['output'];
  userEntities: Array<UserEntity>;
  users: Array<User>;
  vatPeriod?: Maybe<VatPeriod>;
};


export type EntityActivityArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type EntityAttachmentsArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type EntityBankStatementsArgs = {
  cursor?: InputMaybe<BankStatementWhereUniqueInput>;
  distinct?: InputMaybe<Array<BankStatementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankStatementWhereInput>;
};


export type EntityExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExpenseWhereInput>;
};


export type EntityIncomeDataArgs = {
  cursor?: InputMaybe<IncomeDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<IncomeDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IncomeDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IncomeDataWhereInput>;
};


export type EntityInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type EntityPendingReportsArgs = {
  userId: Scalars['String']['input'];
};


export type EntityReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};

export type EntityCreateInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateManyClientManagerInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateManyClientManagerInputEnvelope = {
  data: Array<EntityCreateManyClientManagerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EntityCreateNestedManyWithoutClientManagerInput = {
  connect?: InputMaybe<Array<EntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EntityCreateOrConnectWithoutClientManagerInput>>;
  create?: InputMaybe<Array<EntityCreateWithoutClientManagerInput>>;
  createMany?: InputMaybe<EntityCreateManyClientManagerInputEnvelope>;
};

export type EntityCreateNestedOneWithoutActivityInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<EntityCreateWithoutActivityInput>;
};

export type EntityCreateNestedOneWithoutAttachmentsInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<EntityCreateWithoutAttachmentsInput>;
};

export type EntityCreateNestedOneWithoutBankStatementsInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutBankStatementsInput>;
  create?: InputMaybe<EntityCreateWithoutBankStatementsInput>;
};

export type EntityCreateNestedOneWithoutExpensesInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<EntityCreateWithoutExpensesInput>;
};

export type EntityCreateNestedOneWithoutIncomeDataInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutIncomeDataInput>;
  create?: InputMaybe<EntityCreateWithoutIncomeDataInput>;
};

export type EntityCreateNestedOneWithoutInvoicesInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<EntityCreateWithoutInvoicesInput>;
};

export type EntityCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<EntityCreateWithoutReportsInput>;
};

export type EntityCreateNestedOneWithoutUserEntitiesInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutUserEntitiesInput>;
  create?: InputMaybe<EntityCreateWithoutUserEntitiesInput>;
};

export type EntityCreateOrConnectWithoutActivityInput = {
  create: EntityCreateWithoutActivityInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutAttachmentsInput = {
  create: EntityCreateWithoutAttachmentsInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutBankStatementsInput = {
  create: EntityCreateWithoutBankStatementsInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutClientManagerInput = {
  create: EntityCreateWithoutClientManagerInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutExpensesInput = {
  create: EntityCreateWithoutExpensesInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutIncomeDataInput = {
  create: EntityCreateWithoutIncomeDataInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutInvoicesInput = {
  create: EntityCreateWithoutInvoicesInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutReportsInput = {
  create: EntityCreateWithoutReportsInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateOrConnectWithoutUserEntitiesInput = {
  create: EntityCreateWithoutUserEntitiesInput;
  where: EntityWhereUniqueInput;
};

export type EntityCreateWithoutActivityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutAttachmentsInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutBankStatementsInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutClientManagerInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutExpensesInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutIncomeDataInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutInvoicesInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutReportsInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutEntityInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityCreateWithoutUserEntitiesInput = {
  activity?: InputMaybe<ActivityCreateNestedManyWithoutEntityInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutEntityInput>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutEntityInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserCreateNestedOneWithoutManagedEntitiesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutEntityInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutEntityInput>;
  invitations?: InputMaybe<InvitationCreateNestedManyWithoutEntityInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutEntityInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutEntityInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type: EntityType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityListRelationFilter = {
  every?: InputMaybe<EntityWhereInput>;
  none?: InputMaybe<EntityWhereInput>;
  some?: InputMaybe<EntityWhereInput>;
};

export type EntityNameTypeCompoundUniqueInput = {
  name: Scalars['String']['input'];
  type: EntityType;
};

export type EntityNullableRelationFilter = {
  is?: InputMaybe<EntityWhereInput>;
  isNot?: InputMaybe<EntityWhereInput>;
};

export type EntityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type EntityOrderByWithRelationInput = {
  activity?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  archivedAt?: InputMaybe<SortOrderInput>;
  attachments?: InputMaybe<AttachmentOrderByRelationAggregateInput>;
  bankStatements?: InputMaybe<BankStatementOrderByRelationAggregateInput>;
  btw?: InputMaybe<SortOrderInput>;
  btwId?: InputMaybe<SortOrderInput>;
  clientManager?: InputMaybe<UserOrderByWithRelationInput>;
  clientManagerId?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  expenses?: InputMaybe<ExpenseOrderByRelationAggregateInput>;
  forwardingEmail?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  incomeData?: InputMaybe<IncomeDataOrderByRelationAggregateInput>;
  invitations?: InputMaybe<InvitationOrderByRelationAggregateInput>;
  invoices?: InputMaybe<InvoiceOrderByRelationAggregateInput>;
  kvk?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  rsin?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userEntities?: InputMaybe<UserEntityOrderByRelationAggregateInput>;
  vatPeriod?: InputMaybe<SortOrderInput>;
};

export type EntityRelationFilter = {
  is?: InputMaybe<EntityWhereInput>;
  isNot?: InputMaybe<EntityWhereInput>;
};

export enum EntityScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  Btw = 'btw',
  BtwId = 'btwId',
  ClientManagerId = 'clientManagerId',
  CreatedAt = 'createdAt',
  ForwardingEmail = 'forwardingEmail',
  Id = 'id',
  Kvk = 'kvk',
  Name = 'name',
  Rsin = 'rsin',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  VatPeriod = 'vatPeriod'
}

export type EntityScalarWhereInput = {
  AND?: InputMaybe<Array<EntityScalarWhereInput>>;
  NOT?: InputMaybe<Array<EntityScalarWhereInput>>;
  OR?: InputMaybe<Array<EntityScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  btw?: InputMaybe<StringNullableFilter>;
  btwId?: InputMaybe<StringNullableFilter>;
  clientManagerId?: InputMaybe<UuidNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  forwardingEmail?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  kvk?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  rsin?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumEntityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vatPeriod?: InputMaybe<EnumVatPeriodNullableFilter>;
};

export enum EntityType {
  Bv = 'BV',
  Eenmanszaak = 'EENMANSZAAK',
  Personal = 'PERSONAL',
  Stichting = 'STICHTING',
  Vof = 'VOF'
}

export type EntityUnprocessedDocumentsResponse = {
  __typename?: 'EntityUnprocessedDocumentsResponse';
  bankStatementCount: Scalars['Float']['output'];
  expenseCount: Scalars['Float']['output'];
  invoiceCount: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type EntityUpdateInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateManyWithWhereWithoutClientManagerInput = {
  data: EntityUpdateManyMutationInput;
  where: EntityScalarWhereInput;
};

export type EntityUpdateManyWithoutClientManagerNestedInput = {
  connect?: InputMaybe<Array<EntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EntityCreateOrConnectWithoutClientManagerInput>>;
  create?: InputMaybe<Array<EntityCreateWithoutClientManagerInput>>;
  createMany?: InputMaybe<EntityCreateManyClientManagerInputEnvelope>;
  delete?: InputMaybe<Array<EntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EntityWhereUniqueInput>>;
  set?: InputMaybe<Array<EntityWhereUniqueInput>>;
  update?: InputMaybe<Array<EntityUpdateWithWhereUniqueWithoutClientManagerInput>>;
  updateMany?: InputMaybe<Array<EntityUpdateManyWithWhereWithoutClientManagerInput>>;
  upsert?: InputMaybe<Array<EntityUpsertWithWhereUniqueWithoutClientManagerInput>>;
};

export type EntityUpdateOneRequiredWithoutAttachmentsNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<EntityCreateWithoutAttachmentsInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutAttachmentsInput>;
  upsert?: InputMaybe<EntityUpsertWithoutAttachmentsInput>;
};

export type EntityUpdateOneRequiredWithoutBankStatementsNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutBankStatementsInput>;
  create?: InputMaybe<EntityCreateWithoutBankStatementsInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutBankStatementsInput>;
  upsert?: InputMaybe<EntityUpsertWithoutBankStatementsInput>;
};

export type EntityUpdateOneRequiredWithoutExpensesNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<EntityCreateWithoutExpensesInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutExpensesInput>;
  upsert?: InputMaybe<EntityUpsertWithoutExpensesInput>;
};

export type EntityUpdateOneRequiredWithoutIncomeDataNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutIncomeDataInput>;
  create?: InputMaybe<EntityCreateWithoutIncomeDataInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutIncomeDataInput>;
  upsert?: InputMaybe<EntityUpsertWithoutIncomeDataInput>;
};

export type EntityUpdateOneRequiredWithoutInvoicesNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<EntityCreateWithoutInvoicesInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutInvoicesInput>;
  upsert?: InputMaybe<EntityUpsertWithoutInvoicesInput>;
};

export type EntityUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<EntityCreateWithoutReportsInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutReportsInput>;
  upsert?: InputMaybe<EntityUpsertWithoutReportsInput>;
};

export type EntityUpdateOneRequiredWithoutUserEntitiesNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutUserEntitiesInput>;
  create?: InputMaybe<EntityCreateWithoutUserEntitiesInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutUserEntitiesInput>;
  upsert?: InputMaybe<EntityUpsertWithoutUserEntitiesInput>;
};

export type EntityUpdateOneWithoutActivityNestedInput = {
  connect?: InputMaybe<EntityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EntityCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<EntityCreateWithoutActivityInput>;
  delete?: InputMaybe<EntityWhereInput>;
  disconnect?: InputMaybe<EntityWhereInput>;
  update?: InputMaybe<EntityUpdateToOneWithWhereWithoutActivityInput>;
  upsert?: InputMaybe<EntityUpsertWithoutActivityInput>;
};

export type EntityUpdateToOneWithWhereWithoutActivityInput = {
  data: EntityUpdateWithoutActivityInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutAttachmentsInput = {
  data: EntityUpdateWithoutAttachmentsInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutBankStatementsInput = {
  data: EntityUpdateWithoutBankStatementsInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutExpensesInput = {
  data: EntityUpdateWithoutExpensesInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutIncomeDataInput = {
  data: EntityUpdateWithoutIncomeDataInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutInvoicesInput = {
  data: EntityUpdateWithoutInvoicesInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutReportsInput = {
  data: EntityUpdateWithoutReportsInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateToOneWithWhereWithoutUserEntitiesInput = {
  data: EntityUpdateWithoutUserEntitiesInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpdateWithWhereUniqueWithoutClientManagerInput = {
  data: EntityUpdateWithoutClientManagerInput;
  where: EntityWhereUniqueInput;
};

export type EntityUpdateWithoutActivityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutAttachmentsInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutBankStatementsInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutClientManagerInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutExpensesInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutIncomeDataInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutInvoicesInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutReportsInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutEntityNestedInput>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpdateWithoutUserEntitiesInput = {
  activity?: InputMaybe<ActivityUpdateManyWithoutEntityNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutEntityNestedInput>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutEntityNestedInput>;
  btw?: InputMaybe<Scalars['String']['input']>;
  btwId?: InputMaybe<Scalars['String']['input']>;
  clientManager?: InputMaybe<UserUpdateOneWithoutManagedEntitiesNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutEntityNestedInput>;
  forwardingEmail?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutEntityNestedInput>;
  invitations?: InputMaybe<InvitationUpdateManyWithoutEntityNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutEntityNestedInput>;
  kvk?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<ReportUpdateManyWithoutEntityNestedInput>;
  rsin?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EntityType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  vatPeriod?: InputMaybe<VatPeriod>;
};

export type EntityUpsertWithWhereUniqueWithoutClientManagerInput = {
  create: EntityCreateWithoutClientManagerInput;
  update: EntityUpdateWithoutClientManagerInput;
  where: EntityWhereUniqueInput;
};

export type EntityUpsertWithoutActivityInput = {
  create: EntityCreateWithoutActivityInput;
  update: EntityUpdateWithoutActivityInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutAttachmentsInput = {
  create: EntityCreateWithoutAttachmentsInput;
  update: EntityUpdateWithoutAttachmentsInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutBankStatementsInput = {
  create: EntityCreateWithoutBankStatementsInput;
  update: EntityUpdateWithoutBankStatementsInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutExpensesInput = {
  create: EntityCreateWithoutExpensesInput;
  update: EntityUpdateWithoutExpensesInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutIncomeDataInput = {
  create: EntityCreateWithoutIncomeDataInput;
  update: EntityUpdateWithoutIncomeDataInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutInvoicesInput = {
  create: EntityCreateWithoutInvoicesInput;
  update: EntityUpdateWithoutInvoicesInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutReportsInput = {
  create: EntityCreateWithoutReportsInput;
  update: EntityUpdateWithoutReportsInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityUpsertWithoutUserEntitiesInput = {
  create: EntityCreateWithoutUserEntitiesInput;
  update: EntityUpdateWithoutUserEntitiesInput;
  where?: InputMaybe<EntityWhereInput>;
};

export type EntityWhereInput = {
  AND?: InputMaybe<Array<EntityWhereInput>>;
  NOT?: InputMaybe<Array<EntityWhereInput>>;
  OR?: InputMaybe<Array<EntityWhereInput>>;
  activity?: InputMaybe<ActivityListRelationFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  bankStatements?: InputMaybe<BankStatementListRelationFilter>;
  btw?: InputMaybe<StringNullableFilter>;
  btwId?: InputMaybe<StringNullableFilter>;
  clientManager?: InputMaybe<UserNullableRelationFilter>;
  clientManagerId?: InputMaybe<UuidNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  forwardingEmail?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  incomeData?: InputMaybe<IncomeDataListRelationFilter>;
  invitations?: InputMaybe<InvitationListRelationFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  kvk?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  rsin?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumEntityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userEntities?: InputMaybe<UserEntityListRelationFilter>;
  vatPeriod?: InputMaybe<EnumVatPeriodNullableFilter>;
};

export type EntityWhereUniqueInput = {
  AND?: InputMaybe<Array<EntityWhereInput>>;
  NOT?: InputMaybe<Array<EntityWhereInput>>;
  OR?: InputMaybe<Array<EntityWhereInput>>;
  activity?: InputMaybe<ActivityListRelationFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  bankStatements?: InputMaybe<BankStatementListRelationFilter>;
  btw?: InputMaybe<StringNullableFilter>;
  btwId?: InputMaybe<StringNullableFilter>;
  clientManager?: InputMaybe<UserNullableRelationFilter>;
  clientManagerId?: InputMaybe<UuidNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  forwardingEmail?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataListRelationFilter>;
  invitations?: InputMaybe<InvitationListRelationFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  kvk?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  name_type?: InputMaybe<EntityNameTypeCompoundUniqueInput>;
  reports?: InputMaybe<ReportListRelationFilter>;
  rsin?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumEntityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userEntities?: InputMaybe<UserEntityListRelationFilter>;
  vatPeriod?: InputMaybe<EnumVatPeriodNullableFilter>;
};

export type EnumAccessNullableListFilter = {
  equals?: InputMaybe<Array<Access>>;
  has?: InputMaybe<Access>;
  hasEvery?: InputMaybe<Array<Access>>;
  hasSome?: InputMaybe<Array<Access>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EnumActivityTypeFilter = {
  equals?: InputMaybe<ActivityType>;
  in?: InputMaybe<Array<ActivityType>>;
  not?: InputMaybe<NestedEnumActivityTypeFilter>;
  notIn?: InputMaybe<Array<ActivityType>>;
};

export type EnumEntityTypeFilter = {
  equals?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  not?: InputMaybe<NestedEnumEntityTypeFilter>;
  notIn?: InputMaybe<Array<EntityType>>;
};

export type EnumIncomeDataQuestionNullableFilter = {
  equals?: InputMaybe<IncomeDataQuestion>;
  in?: InputMaybe<Array<IncomeDataQuestion>>;
  not?: InputMaybe<NestedEnumIncomeDataQuestionNullableFilter>;
  notIn?: InputMaybe<Array<IncomeDataQuestion>>;
};

export type EnumIncomeDataStatusFilter = {
  equals?: InputMaybe<IncomeDataStatus>;
  in?: InputMaybe<Array<IncomeDataStatus>>;
  not?: InputMaybe<NestedEnumIncomeDataStatusFilter>;
  notIn?: InputMaybe<Array<IncomeDataStatus>>;
};

export type EnumLanguageFilter = {
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<NestedEnumLanguageFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export type EnumPaymentMethodNullableFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumQuarterNullableFilter = {
  equals?: InputMaybe<Quarter>;
  in?: InputMaybe<Array<Quarter>>;
  not?: InputMaybe<NestedEnumQuarterNullableFilter>;
  notIn?: InputMaybe<Array<Quarter>>;
};

export type EnumReportStatusFilter = {
  equals?: InputMaybe<ReportStatus>;
  in?: InputMaybe<Array<ReportStatus>>;
  not?: InputMaybe<NestedEnumReportStatusFilter>;
  notIn?: InputMaybe<Array<ReportStatus>>;
};

export type EnumReportTypeFilter = {
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumUserEntityRoleFilter = {
  equals?: InputMaybe<UserEntityRole>;
  in?: InputMaybe<Array<UserEntityRole>>;
  not?: InputMaybe<NestedEnumUserEntityRoleFilter>;
  notIn?: InputMaybe<Array<UserEntityRole>>;
};

export type EnumUserEntityRoleNullableFilter = {
  equals?: InputMaybe<UserEntityRole>;
  in?: InputMaybe<Array<UserEntityRole>>;
  not?: InputMaybe<NestedEnumUserEntityRoleNullableFilter>;
  notIn?: InputMaybe<Array<UserEntityRole>>;
};

export type EnumVatPeriodNullableFilter = {
  equals?: InputMaybe<VatPeriod>;
  in?: InputMaybe<Array<VatPeriod>>;
  not?: InputMaybe<NestedEnumVatPeriodNullableFilter>;
  notIn?: InputMaybe<Array<VatPeriod>>;
};

export type Expense = {
  __typename?: 'Expense';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  file: Scalars['String']['output'];
  filePdf?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isProcessed: Scalars['Boolean']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<User>;
  uploaderId?: Maybe<Scalars['String']['output']>;
};

export type ExpenseCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityCreateNestedOneWithoutExpensesInput;
  file: Scalars['String']['input'];
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutExpensesInput>;
};

export type ExpenseCreateManyEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['String']['input'];
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
};

export type ExpenseCreateManyEntityInputEnvelope = {
  data: Array<ExpenseCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExpenseCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
};

export type ExpenseCreateManyUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExpenseCreateManyUploaderInputEnvelope = {
  data: Array<ExpenseCreateManyUploaderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExpenseCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutEntityInput>>;
  createMany?: InputMaybe<ExpenseCreateManyEntityInputEnvelope>;
};

export type ExpenseCreateNestedManyWithoutUploaderInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<ExpenseCreateManyUploaderInputEnvelope>;
};

export type ExpenseCreateOrConnectWithoutEntityInput = {
  create: ExpenseCreateWithoutEntityInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateOrConnectWithoutUploaderInput = {
  create: ExpenseCreateWithoutUploaderInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseCreateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['String']['input'];
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutExpensesInput>;
};

export type ExpenseCreateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityCreateNestedOneWithoutExpensesInput;
  file: Scalars['String']['input'];
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExpenseListRelationFilter = {
  every?: InputMaybe<ExpenseWhereInput>;
  none?: InputMaybe<ExpenseWhereInput>;
  some?: InputMaybe<ExpenseWhereInput>;
};

export type ExpenseOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ExpenseOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  filePdf?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isProcessed?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uploader?: InputMaybe<UserOrderByWithRelationInput>;
  uploaderId?: InputMaybe<SortOrderInput>;
};

export enum ExpenseScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Date = 'date',
  Description = 'description',
  EntityId = 'entityId',
  File = 'file',
  FilePdf = 'filePdf',
  Id = 'id',
  IsProcessed = 'isProcessed',
  PaymentMethod = 'paymentMethod',
  UpdatedAt = 'updatedAt',
  UploaderId = 'uploaderId'
}

export type ExpenseScalarWhereInput = {
  AND?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  NOT?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  OR?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  filePdf?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
};

export type ExpenseUpdateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutExpensesNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutExpensesNestedInput>;
};

export type ExpenseUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExpenseUpdateManyWithWhereWithoutEntityInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateManyWithWhereWithoutUploaderInput = {
  data: ExpenseUpdateManyMutationInput;
  where: ExpenseScalarWhereInput;
};

export type ExpenseUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutEntityInput>>;
  createMany?: InputMaybe<ExpenseCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  update?: InputMaybe<Array<ExpenseUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<ExpenseUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<ExpenseUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type ExpenseUpdateManyWithoutUploaderNestedInput = {
  connect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ExpenseCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<ExpenseCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<ExpenseCreateManyUploaderInputEnvelope>;
  delete?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ExpenseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  set?: InputMaybe<Array<ExpenseWhereUniqueInput>>;
  update?: InputMaybe<Array<ExpenseUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: InputMaybe<Array<ExpenseUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: InputMaybe<Array<ExpenseUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type ExpenseUpdateWithWhereUniqueWithoutEntityInput = {
  data: ExpenseUpdateWithoutEntityInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpdateWithWhereUniqueWithoutUploaderInput = {
  data: ExpenseUpdateWithoutUploaderInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpdateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutExpensesNestedInput>;
};

export type ExpenseUpdateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutExpensesNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  filePdf?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ExpenseUpsertWithWhereUniqueWithoutEntityInput = {
  create: ExpenseCreateWithoutEntityInput;
  update: ExpenseUpdateWithoutEntityInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseUpsertWithWhereUniqueWithoutUploaderInput = {
  create: ExpenseCreateWithoutUploaderInput;
  update: ExpenseUpdateWithoutUploaderInput;
  where: ExpenseWhereUniqueInput;
};

export type ExpenseWhereInput = {
  AND?: InputMaybe<Array<ExpenseWhereInput>>;
  NOT?: InputMaybe<Array<ExpenseWhereInput>>;
  OR?: InputMaybe<Array<ExpenseWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  filePdf?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
};

export type ExpenseWhereUniqueInput = {
  AND?: InputMaybe<Array<ExpenseWhereInput>>;
  NOT?: InputMaybe<Array<ExpenseWhereInput>>;
  OR?: InputMaybe<Array<ExpenseWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  filePdf?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<BoolFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
};

export type ExpensesResponse = {
  __typename?: 'ExpensesResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Expense>;
};

export type IncomeData = {
  __typename?: 'IncomeData';
  additionalInformation?: Maybe<Scalars['String']['output']>;
  attachments?: Maybe<Array<Attachment>>;
  charityDonationSpecification?: Maybe<Scalars['String']['output']>;
  childrenNamesAndBirthDates?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  didBreakUpWithPartner?: Maybe<Scalars['Boolean']['output']>;
  didBuyOrSellProperty?: Maybe<Scalars['Boolean']['output']>;
  didDonateToCharities?: Maybe<Scalars['Boolean']['output']>;
  didFileForDivorce?: Maybe<Scalars['Boolean']['output']>;
  didHaveOtherIncome?: Maybe<Scalars['Boolean']['output']>;
  didHaveSalary?: Maybe<Scalars['Boolean']['output']>;
  didLiveAtSameAddress?: Maybe<Scalars['Boolean']['output']>;
  didLiveSeparate?: Maybe<Scalars['Boolean']['output']>;
  didLiveTogether?: Maybe<Scalars['Boolean']['output']>;
  didMarryThisYear?: Maybe<Scalars['Boolean']['output']>;
  didPayAnnuityPremiums?: Maybe<Scalars['Boolean']['output']>;
  didPayOrReceiveSpousalSupport?: Maybe<Scalars['String']['output']>;
  didReceiveDonationForProperty?: Maybe<Scalars['String']['output']>;
  didReceiveOtherBenefits?: Maybe<Scalars['Boolean']['output']>;
  didRegisterDifferentAddress?: Maybe<Scalars['Boolean']['output']>;
  didRegisterInPensionSchemes?: Maybe<Scalars['Boolean']['output']>;
  didWorkRequiredHoursOnSoleOrParntership?: Maybe<Scalars['Boolean']['output']>;
  doesOwnProperty?: Maybe<Scalars['Boolean']['output']>;
  doesOwnPropertyOfResidence?: Maybe<Scalars['Boolean']['output']>;
  doesOwnPropertyTogether?: Maybe<Scalars['Boolean']['output']>;
  doesOwnSharesInCompany?: Maybe<Scalars['Boolean']['output']>;
  entityId: Scalars['String']['output'];
  groundLeaseAmount?: Maybe<Scalars['String']['output']>;
  hasNewOrIncreasedMortage?: Maybe<Scalars['Boolean']['output']>;
  hasNotarialCohabitationAgreement?: Maybe<Scalars['Boolean']['output']>;
  hasPartner?: Maybe<Scalars['Boolean']['output']>;
  hasPlanToUseProperty?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  immigrationDateAndOriginCountry?: Maybe<Scalars['String']['output']>;
  invoiceFromNotary?: Maybe<Scalars['String']['output']>;
  isChildOfTenantRegisteredAtAddress?: Maybe<Scalars['Boolean']['output']>;
  livingTogetherStartDate?: Maybe<Scalars['DateTime']['output']>;
  marriageDate?: Maybe<Scalars['DateTime']['output']>;
  namesOfCompanies?: Maybe<Scalars['String']['output']>;
  otherAllowances?: Maybe<Scalars['String']['output']>;
  otherPropertyAddresses?: Maybe<Scalars['String']['output']>;
  partnerName?: Maybe<Scalars['String']['output']>;
  registerDifferentAddressDate?: Maybe<Scalars['DateTime']['output']>;
  rentalIncomeForProperty?: Maybe<Scalars['String']['output']>;
  status: IncomeDataStatus;
  updatedAt: Scalars['DateTime']['output'];
  wasChildAknowledged?: Maybe<Scalars['Boolean']['output']>;
  wasMarriedEntireYear?: Maybe<Scalars['Boolean']['output']>;
  wasResidentOfNetherlands?: Maybe<Scalars['Boolean']['output']>;
  wasResidentOfOtherCountry?: Maybe<Scalars['String']['output']>;
  year: Scalars['Float']['output'];
};

export type IncomeDataCreateInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutIncomeDataInput>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator: UserCreateNestedOneWithoutIncomeDataInput;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entity: EntityCreateNestedOneWithoutIncomeDataInput;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status: IncomeDataStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IncomeDataCreateManyCreatorInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entityId: Scalars['String']['input'];
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status: IncomeDataStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IncomeDataCreateManyCreatorInputEnvelope = {
  data: Array<IncomeDataCreateManyCreatorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IncomeDataCreateManyEntityInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creatorId: Scalars['String']['input'];
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status: IncomeDataStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IncomeDataCreateManyEntityInputEnvelope = {
  data: Array<IncomeDataCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IncomeDataCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IncomeDataCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<IncomeDataCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<IncomeDataCreateManyCreatorInputEnvelope>;
};

export type IncomeDataCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IncomeDataCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<IncomeDataCreateWithoutEntityInput>>;
  createMany?: InputMaybe<IncomeDataCreateManyEntityInputEnvelope>;
};

export type IncomeDataCreateNestedOneWithoutAttachmentsInput = {
  connect?: InputMaybe<IncomeDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IncomeDataCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<IncomeDataCreateWithoutAttachmentsInput>;
};

export type IncomeDataCreateOrConnectWithoutAttachmentsInput = {
  create: IncomeDataCreateWithoutAttachmentsInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataCreateOrConnectWithoutCreatorInput = {
  create: IncomeDataCreateWithoutCreatorInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataCreateOrConnectWithoutEntityInput = {
  create: IncomeDataCreateWithoutEntityInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataCreateWithoutAttachmentsInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator: UserCreateNestedOneWithoutIncomeDataInput;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entity: EntityCreateNestedOneWithoutIncomeDataInput;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status: IncomeDataStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IncomeDataCreateWithoutCreatorInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutIncomeDataInput>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entity: EntityCreateNestedOneWithoutIncomeDataInput;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status: IncomeDataStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IncomeDataCreateWithoutEntityInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutIncomeDataInput>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator: UserCreateNestedOneWithoutIncomeDataInput;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status: IncomeDataStatus;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type IncomeDataListRelationFilter = {
  every?: InputMaybe<IncomeDataWhereInput>;
  none?: InputMaybe<IncomeDataWhereInput>;
  some?: InputMaybe<IncomeDataWhereInput>;
};

export type IncomeDataNullableRelationFilter = {
  is?: InputMaybe<IncomeDataWhereInput>;
  isNot?: InputMaybe<IncomeDataWhereInput>;
};

export type IncomeDataOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type IncomeDataOrderByWithRelationInput = {
  additionalInformation?: InputMaybe<SortOrderInput>;
  attachments?: InputMaybe<AttachmentOrderByRelationAggregateInput>;
  charityDonationSpecification?: InputMaybe<SortOrderInput>;
  childrenNamesAndBirthDates?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  creator?: InputMaybe<UserOrderByWithRelationInput>;
  creatorId?: InputMaybe<SortOrder>;
  didBreakUpWithPartner?: InputMaybe<SortOrderInput>;
  didBuyOrSellProperty?: InputMaybe<SortOrderInput>;
  didDonateToCharities?: InputMaybe<SortOrderInput>;
  didFileForDivorce?: InputMaybe<SortOrderInput>;
  didHaveOtherIncome?: InputMaybe<SortOrderInput>;
  didHaveSalary?: InputMaybe<SortOrderInput>;
  didLiveAtSameAddress?: InputMaybe<SortOrderInput>;
  didLiveSeparate?: InputMaybe<SortOrderInput>;
  didLiveTogether?: InputMaybe<SortOrderInput>;
  didMarryThisYear?: InputMaybe<SortOrderInput>;
  didPayAnnuityPremiums?: InputMaybe<SortOrderInput>;
  didPayOrReceiveSpousalSupport?: InputMaybe<SortOrderInput>;
  didReceiveDonationForProperty?: InputMaybe<SortOrderInput>;
  didReceiveOtherBenefits?: InputMaybe<SortOrderInput>;
  didRegisterDifferentAddress?: InputMaybe<SortOrderInput>;
  didRegisterInPensionSchemes?: InputMaybe<SortOrderInput>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<SortOrderInput>;
  doesOwnProperty?: InputMaybe<SortOrderInput>;
  doesOwnPropertyOfResidence?: InputMaybe<SortOrderInput>;
  doesOwnPropertyTogether?: InputMaybe<SortOrderInput>;
  doesOwnSharesInCompany?: InputMaybe<SortOrderInput>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  groundLeaseAmount?: InputMaybe<SortOrderInput>;
  hasNewOrIncreasedMortage?: InputMaybe<SortOrderInput>;
  hasNotarialCohabitationAgreement?: InputMaybe<SortOrderInput>;
  hasPartner?: InputMaybe<SortOrderInput>;
  hasPlanToUseProperty?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  immigrationDateAndOriginCountry?: InputMaybe<SortOrderInput>;
  invoiceFromNotary?: InputMaybe<SortOrderInput>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<SortOrderInput>;
  livingTogetherStartDate?: InputMaybe<SortOrderInput>;
  marriageDate?: InputMaybe<SortOrderInput>;
  namesOfCompanies?: InputMaybe<SortOrderInput>;
  otherAllowances?: InputMaybe<SortOrderInput>;
  otherPropertyAddresses?: InputMaybe<SortOrderInput>;
  partnerName?: InputMaybe<SortOrderInput>;
  registerDifferentAddressDate?: InputMaybe<SortOrderInput>;
  rentalIncomeForProperty?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wasChildAknowledged?: InputMaybe<SortOrderInput>;
  wasMarriedEntireYear?: InputMaybe<SortOrderInput>;
  wasResidentOfNetherlands?: InputMaybe<SortOrderInput>;
  wasResidentOfOtherCountry?: InputMaybe<SortOrderInput>;
  year?: InputMaybe<SortOrder>;
};

export enum IncomeDataQuestion {
  AnnualSalaryStatements = 'ANNUAL_SALARY_STATEMENTS',
  AssetAnnualStatements = 'ASSET_ANNUAL_STATEMENTS',
  BenefitsStatements = 'BENEFITS_STATEMENTS',
  DisabilityInsurance = 'DISABILITY_INSURANCE',
  HasResidualDebtAfterSelling = 'HAS_RESIDUAL_DEBT_AFTER_SELLING',
  InsurancePolicies = 'INSURANCE_POLICIES',
  InvoiceFromNotary = 'INVOICE_FROM_NOTARY',
  MedicalExpensesNotCoveredByInsurance = 'MEDICAL_EXPENSES_NOT_COVERED_BY_INSURANCE',
  MortageAnnualStatements = 'MORTAGE_ANNUAL_STATEMENTS',
  OtherIncomeStatements = 'OTHER_INCOME_STATEMENTS',
  WereDonationsPeriodic = 'WERE_DONATIONS_PERIODIC'
}

export type IncomeDataResponse = {
  __typename?: 'IncomeDataResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<IncomeData>;
};

export enum IncomeDataScalarFieldEnum {
  AdditionalInformation = 'additionalInformation',
  CharityDonationSpecification = 'charityDonationSpecification',
  ChildrenNamesAndBirthDates = 'childrenNamesAndBirthDates',
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  DidBreakUpWithPartner = 'didBreakUpWithPartner',
  DidBuyOrSellProperty = 'didBuyOrSellProperty',
  DidDonateToCharities = 'didDonateToCharities',
  DidFileForDivorce = 'didFileForDivorce',
  DidHaveOtherIncome = 'didHaveOtherIncome',
  DidHaveSalary = 'didHaveSalary',
  DidLiveAtSameAddress = 'didLiveAtSameAddress',
  DidLiveSeparate = 'didLiveSeparate',
  DidLiveTogether = 'didLiveTogether',
  DidMarryThisYear = 'didMarryThisYear',
  DidPayAnnuityPremiums = 'didPayAnnuityPremiums',
  DidPayOrReceiveSpousalSupport = 'didPayOrReceiveSpousalSupport',
  DidReceiveDonationForProperty = 'didReceiveDonationForProperty',
  DidReceiveOtherBenefits = 'didReceiveOtherBenefits',
  DidRegisterDifferentAddress = 'didRegisterDifferentAddress',
  DidRegisterInPensionSchemes = 'didRegisterInPensionSchemes',
  DidWorkRequiredHoursOnSoleOrParntership = 'didWorkRequiredHoursOnSoleOrParntership',
  DoesOwnProperty = 'doesOwnProperty',
  DoesOwnPropertyOfResidence = 'doesOwnPropertyOfResidence',
  DoesOwnPropertyTogether = 'doesOwnPropertyTogether',
  DoesOwnSharesInCompany = 'doesOwnSharesInCompany',
  EntityId = 'entityId',
  GroundLeaseAmount = 'groundLeaseAmount',
  HasNewOrIncreasedMortage = 'hasNewOrIncreasedMortage',
  HasNotarialCohabitationAgreement = 'hasNotarialCohabitationAgreement',
  HasPartner = 'hasPartner',
  HasPlanToUseProperty = 'hasPlanToUseProperty',
  Id = 'id',
  ImmigrationDateAndOriginCountry = 'immigrationDateAndOriginCountry',
  InvoiceFromNotary = 'invoiceFromNotary',
  IsChildOfTenantRegisteredAtAddress = 'isChildOfTenantRegisteredAtAddress',
  LivingTogetherStartDate = 'livingTogetherStartDate',
  MarriageDate = 'marriageDate',
  NamesOfCompanies = 'namesOfCompanies',
  OtherAllowances = 'otherAllowances',
  OtherPropertyAddresses = 'otherPropertyAddresses',
  PartnerName = 'partnerName',
  RegisterDifferentAddressDate = 'registerDifferentAddressDate',
  RentalIncomeForProperty = 'rentalIncomeForProperty',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  WasChildAknowledged = 'wasChildAknowledged',
  WasMarriedEntireYear = 'wasMarriedEntireYear',
  WasResidentOfNetherlands = 'wasResidentOfNetherlands',
  WasResidentOfOtherCountry = 'wasResidentOfOtherCountry',
  Year = 'year'
}

export type IncomeDataScalarWhereInput = {
  AND?: InputMaybe<Array<IncomeDataScalarWhereInput>>;
  NOT?: InputMaybe<Array<IncomeDataScalarWhereInput>>;
  OR?: InputMaybe<Array<IncomeDataScalarWhereInput>>;
  additionalInformation?: InputMaybe<StringNullableFilter>;
  charityDonationSpecification?: InputMaybe<StringNullableFilter>;
  childrenNamesAndBirthDates?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<UuidFilter>;
  didBreakUpWithPartner?: InputMaybe<BoolNullableFilter>;
  didBuyOrSellProperty?: InputMaybe<BoolNullableFilter>;
  didDonateToCharities?: InputMaybe<BoolNullableFilter>;
  didFileForDivorce?: InputMaybe<BoolNullableFilter>;
  didHaveOtherIncome?: InputMaybe<BoolNullableFilter>;
  didHaveSalary?: InputMaybe<BoolNullableFilter>;
  didLiveAtSameAddress?: InputMaybe<BoolNullableFilter>;
  didLiveSeparate?: InputMaybe<BoolNullableFilter>;
  didLiveTogether?: InputMaybe<BoolNullableFilter>;
  didMarryThisYear?: InputMaybe<BoolNullableFilter>;
  didPayAnnuityPremiums?: InputMaybe<BoolNullableFilter>;
  didPayOrReceiveSpousalSupport?: InputMaybe<StringNullableFilter>;
  didReceiveDonationForProperty?: InputMaybe<StringNullableFilter>;
  didReceiveOtherBenefits?: InputMaybe<BoolNullableFilter>;
  didRegisterDifferentAddress?: InputMaybe<BoolNullableFilter>;
  didRegisterInPensionSchemes?: InputMaybe<BoolNullableFilter>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<BoolNullableFilter>;
  doesOwnProperty?: InputMaybe<BoolNullableFilter>;
  doesOwnPropertyOfResidence?: InputMaybe<BoolNullableFilter>;
  doesOwnPropertyTogether?: InputMaybe<BoolNullableFilter>;
  doesOwnSharesInCompany?: InputMaybe<BoolNullableFilter>;
  entityId?: InputMaybe<UuidFilter>;
  groundLeaseAmount?: InputMaybe<StringNullableFilter>;
  hasNewOrIncreasedMortage?: InputMaybe<BoolNullableFilter>;
  hasNotarialCohabitationAgreement?: InputMaybe<BoolNullableFilter>;
  hasPartner?: InputMaybe<BoolNullableFilter>;
  hasPlanToUseProperty?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  immigrationDateAndOriginCountry?: InputMaybe<StringNullableFilter>;
  invoiceFromNotary?: InputMaybe<StringNullableFilter>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<BoolNullableFilter>;
  livingTogetherStartDate?: InputMaybe<DateTimeNullableFilter>;
  marriageDate?: InputMaybe<DateTimeNullableFilter>;
  namesOfCompanies?: InputMaybe<StringNullableFilter>;
  otherAllowances?: InputMaybe<StringNullableFilter>;
  otherPropertyAddresses?: InputMaybe<StringNullableFilter>;
  partnerName?: InputMaybe<StringNullableFilter>;
  registerDifferentAddressDate?: InputMaybe<DateTimeNullableFilter>;
  rentalIncomeForProperty?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumIncomeDataStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wasChildAknowledged?: InputMaybe<BoolNullableFilter>;
  wasMarriedEntireYear?: InputMaybe<BoolNullableFilter>;
  wasResidentOfNetherlands?: InputMaybe<BoolNullableFilter>;
  wasResidentOfOtherCountry?: InputMaybe<StringNullableFilter>;
  year?: InputMaybe<IntFilter>;
};

export enum IncomeDataStatus {
  Completed = 'COMPLETED',
  Filed = 'FILED',
  Pending = 'PENDING'
}

export type IncomeDataUpdateInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutIncomeDataNestedInput>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutIncomeDataNestedInput>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutIncomeDataNestedInput>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomeDataStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomeDataUpdateManyMutationInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomeDataStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomeDataUpdateManyWithWhereWithoutCreatorInput = {
  data: IncomeDataUpdateManyMutationInput;
  where: IncomeDataScalarWhereInput;
};

export type IncomeDataUpdateManyWithWhereWithoutEntityInput = {
  data: IncomeDataUpdateManyMutationInput;
  where: IncomeDataScalarWhereInput;
};

export type IncomeDataUpdateManyWithoutCreatorNestedInput = {
  connect?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IncomeDataCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<IncomeDataCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<IncomeDataCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IncomeDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  set?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  update?: InputMaybe<Array<IncomeDataUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<IncomeDataUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<IncomeDataUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type IncomeDataUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<IncomeDataCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<IncomeDataCreateWithoutEntityInput>>;
  createMany?: InputMaybe<IncomeDataCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<IncomeDataScalarWhereInput>>;
  disconnect?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  set?: InputMaybe<Array<IncomeDataWhereUniqueInput>>;
  update?: InputMaybe<Array<IncomeDataUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<IncomeDataUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<IncomeDataUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type IncomeDataUpdateOneWithoutAttachmentsNestedInput = {
  connect?: InputMaybe<IncomeDataWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IncomeDataCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<IncomeDataCreateWithoutAttachmentsInput>;
  delete?: InputMaybe<IncomeDataWhereInput>;
  disconnect?: InputMaybe<IncomeDataWhereInput>;
  update?: InputMaybe<IncomeDataUpdateToOneWithWhereWithoutAttachmentsInput>;
  upsert?: InputMaybe<IncomeDataUpsertWithoutAttachmentsInput>;
};

export type IncomeDataUpdateToOneWithWhereWithoutAttachmentsInput = {
  data: IncomeDataUpdateWithoutAttachmentsInput;
  where?: InputMaybe<IncomeDataWhereInput>;
};

export type IncomeDataUpdateWithWhereUniqueWithoutCreatorInput = {
  data: IncomeDataUpdateWithoutCreatorInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataUpdateWithWhereUniqueWithoutEntityInput = {
  data: IncomeDataUpdateWithoutEntityInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataUpdateWithoutAttachmentsInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutIncomeDataNestedInput>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutIncomeDataNestedInput>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomeDataStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomeDataUpdateWithoutCreatorInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutIncomeDataNestedInput>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutIncomeDataNestedInput>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomeDataStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomeDataUpdateWithoutEntityInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutIncomeDataNestedInput>;
  charityDonationSpecification?: InputMaybe<Scalars['String']['input']>;
  childrenNamesAndBirthDates?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutIncomeDataNestedInput>;
  didBreakUpWithPartner?: InputMaybe<Scalars['Boolean']['input']>;
  didBuyOrSellProperty?: InputMaybe<Scalars['Boolean']['input']>;
  didDonateToCharities?: InputMaybe<Scalars['Boolean']['input']>;
  didFileForDivorce?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveOtherIncome?: InputMaybe<Scalars['Boolean']['input']>;
  didHaveSalary?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveAtSameAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveSeparate?: InputMaybe<Scalars['Boolean']['input']>;
  didLiveTogether?: InputMaybe<Scalars['Boolean']['input']>;
  didMarryThisYear?: InputMaybe<Scalars['Boolean']['input']>;
  didPayAnnuityPremiums?: InputMaybe<Scalars['Boolean']['input']>;
  didPayOrReceiveSpousalSupport?: InputMaybe<Scalars['String']['input']>;
  didReceiveDonationForProperty?: InputMaybe<Scalars['String']['input']>;
  didReceiveOtherBenefits?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterDifferentAddress?: InputMaybe<Scalars['Boolean']['input']>;
  didRegisterInPensionSchemes?: InputMaybe<Scalars['Boolean']['input']>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnProperty?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyOfResidence?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnPropertyTogether?: InputMaybe<Scalars['Boolean']['input']>;
  doesOwnSharesInCompany?: InputMaybe<Scalars['Boolean']['input']>;
  groundLeaseAmount?: InputMaybe<Scalars['String']['input']>;
  hasNewOrIncreasedMortage?: InputMaybe<Scalars['Boolean']['input']>;
  hasNotarialCohabitationAgreement?: InputMaybe<Scalars['Boolean']['input']>;
  hasPartner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPlanToUseProperty?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<Scalars['String']['input']>;
  invoiceFromNotary?: InputMaybe<Scalars['String']['input']>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<Scalars['Boolean']['input']>;
  livingTogetherStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  marriageDate?: InputMaybe<Scalars['DateTime']['input']>;
  namesOfCompanies?: InputMaybe<Scalars['String']['input']>;
  otherAllowances?: InputMaybe<Scalars['String']['input']>;
  otherPropertyAddresses?: InputMaybe<Scalars['String']['input']>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  registerDifferentAddressDate?: InputMaybe<Scalars['DateTime']['input']>;
  rentalIncomeForProperty?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<IncomeDataStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  wasChildAknowledged?: InputMaybe<Scalars['Boolean']['input']>;
  wasMarriedEntireYear?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfNetherlands?: InputMaybe<Scalars['Boolean']['input']>;
  wasResidentOfOtherCountry?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type IncomeDataUpsertWithWhereUniqueWithoutCreatorInput = {
  create: IncomeDataCreateWithoutCreatorInput;
  update: IncomeDataUpdateWithoutCreatorInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataUpsertWithWhereUniqueWithoutEntityInput = {
  create: IncomeDataCreateWithoutEntityInput;
  update: IncomeDataUpdateWithoutEntityInput;
  where: IncomeDataWhereUniqueInput;
};

export type IncomeDataUpsertWithoutAttachmentsInput = {
  create: IncomeDataCreateWithoutAttachmentsInput;
  update: IncomeDataUpdateWithoutAttachmentsInput;
  where?: InputMaybe<IncomeDataWhereInput>;
};

export type IncomeDataWhereInput = {
  AND?: InputMaybe<Array<IncomeDataWhereInput>>;
  NOT?: InputMaybe<Array<IncomeDataWhereInput>>;
  OR?: InputMaybe<Array<IncomeDataWhereInput>>;
  additionalInformation?: InputMaybe<StringNullableFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  charityDonationSpecification?: InputMaybe<StringNullableFilter>;
  childrenNamesAndBirthDates?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<UuidFilter>;
  didBreakUpWithPartner?: InputMaybe<BoolNullableFilter>;
  didBuyOrSellProperty?: InputMaybe<BoolNullableFilter>;
  didDonateToCharities?: InputMaybe<BoolNullableFilter>;
  didFileForDivorce?: InputMaybe<BoolNullableFilter>;
  didHaveOtherIncome?: InputMaybe<BoolNullableFilter>;
  didHaveSalary?: InputMaybe<BoolNullableFilter>;
  didLiveAtSameAddress?: InputMaybe<BoolNullableFilter>;
  didLiveSeparate?: InputMaybe<BoolNullableFilter>;
  didLiveTogether?: InputMaybe<BoolNullableFilter>;
  didMarryThisYear?: InputMaybe<BoolNullableFilter>;
  didPayAnnuityPremiums?: InputMaybe<BoolNullableFilter>;
  didPayOrReceiveSpousalSupport?: InputMaybe<StringNullableFilter>;
  didReceiveDonationForProperty?: InputMaybe<StringNullableFilter>;
  didReceiveOtherBenefits?: InputMaybe<BoolNullableFilter>;
  didRegisterDifferentAddress?: InputMaybe<BoolNullableFilter>;
  didRegisterInPensionSchemes?: InputMaybe<BoolNullableFilter>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<BoolNullableFilter>;
  doesOwnProperty?: InputMaybe<BoolNullableFilter>;
  doesOwnPropertyOfResidence?: InputMaybe<BoolNullableFilter>;
  doesOwnPropertyTogether?: InputMaybe<BoolNullableFilter>;
  doesOwnSharesInCompany?: InputMaybe<BoolNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  groundLeaseAmount?: InputMaybe<StringNullableFilter>;
  hasNewOrIncreasedMortage?: InputMaybe<BoolNullableFilter>;
  hasNotarialCohabitationAgreement?: InputMaybe<BoolNullableFilter>;
  hasPartner?: InputMaybe<BoolNullableFilter>;
  hasPlanToUseProperty?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<UuidFilter>;
  immigrationDateAndOriginCountry?: InputMaybe<StringNullableFilter>;
  invoiceFromNotary?: InputMaybe<StringNullableFilter>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<BoolNullableFilter>;
  livingTogetherStartDate?: InputMaybe<DateTimeNullableFilter>;
  marriageDate?: InputMaybe<DateTimeNullableFilter>;
  namesOfCompanies?: InputMaybe<StringNullableFilter>;
  otherAllowances?: InputMaybe<StringNullableFilter>;
  otherPropertyAddresses?: InputMaybe<StringNullableFilter>;
  partnerName?: InputMaybe<StringNullableFilter>;
  registerDifferentAddressDate?: InputMaybe<DateTimeNullableFilter>;
  rentalIncomeForProperty?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumIncomeDataStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wasChildAknowledged?: InputMaybe<BoolNullableFilter>;
  wasMarriedEntireYear?: InputMaybe<BoolNullableFilter>;
  wasResidentOfNetherlands?: InputMaybe<BoolNullableFilter>;
  wasResidentOfOtherCountry?: InputMaybe<StringNullableFilter>;
  year?: InputMaybe<IntFilter>;
};

export type IncomeDataWhereUniqueInput = {
  AND?: InputMaybe<Array<IncomeDataWhereInput>>;
  NOT?: InputMaybe<Array<IncomeDataWhereInput>>;
  OR?: InputMaybe<Array<IncomeDataWhereInput>>;
  additionalInformation?: InputMaybe<StringNullableFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  charityDonationSpecification?: InputMaybe<StringNullableFilter>;
  childrenNamesAndBirthDates?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<UuidFilter>;
  didBreakUpWithPartner?: InputMaybe<BoolNullableFilter>;
  didBuyOrSellProperty?: InputMaybe<BoolNullableFilter>;
  didDonateToCharities?: InputMaybe<BoolNullableFilter>;
  didFileForDivorce?: InputMaybe<BoolNullableFilter>;
  didHaveOtherIncome?: InputMaybe<BoolNullableFilter>;
  didHaveSalary?: InputMaybe<BoolNullableFilter>;
  didLiveAtSameAddress?: InputMaybe<BoolNullableFilter>;
  didLiveSeparate?: InputMaybe<BoolNullableFilter>;
  didLiveTogether?: InputMaybe<BoolNullableFilter>;
  didMarryThisYear?: InputMaybe<BoolNullableFilter>;
  didPayAnnuityPremiums?: InputMaybe<BoolNullableFilter>;
  didPayOrReceiveSpousalSupport?: InputMaybe<StringNullableFilter>;
  didReceiveDonationForProperty?: InputMaybe<StringNullableFilter>;
  didReceiveOtherBenefits?: InputMaybe<BoolNullableFilter>;
  didRegisterDifferentAddress?: InputMaybe<BoolNullableFilter>;
  didRegisterInPensionSchemes?: InputMaybe<BoolNullableFilter>;
  didWorkRequiredHoursOnSoleOrParntership?: InputMaybe<BoolNullableFilter>;
  doesOwnProperty?: InputMaybe<BoolNullableFilter>;
  doesOwnPropertyOfResidence?: InputMaybe<BoolNullableFilter>;
  doesOwnPropertyTogether?: InputMaybe<BoolNullableFilter>;
  doesOwnSharesInCompany?: InputMaybe<BoolNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  groundLeaseAmount?: InputMaybe<StringNullableFilter>;
  hasNewOrIncreasedMortage?: InputMaybe<BoolNullableFilter>;
  hasNotarialCohabitationAgreement?: InputMaybe<BoolNullableFilter>;
  hasPartner?: InputMaybe<BoolNullableFilter>;
  hasPlanToUseProperty?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  immigrationDateAndOriginCountry?: InputMaybe<StringNullableFilter>;
  invoiceFromNotary?: InputMaybe<StringNullableFilter>;
  isChildOfTenantRegisteredAtAddress?: InputMaybe<BoolNullableFilter>;
  livingTogetherStartDate?: InputMaybe<DateTimeNullableFilter>;
  marriageDate?: InputMaybe<DateTimeNullableFilter>;
  namesOfCompanies?: InputMaybe<StringNullableFilter>;
  otherAllowances?: InputMaybe<StringNullableFilter>;
  otherPropertyAddresses?: InputMaybe<StringNullableFilter>;
  partnerName?: InputMaybe<StringNullableFilter>;
  registerDifferentAddressDate?: InputMaybe<DateTimeNullableFilter>;
  rentalIncomeForProperty?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumIncomeDataStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wasChildAknowledged?: InputMaybe<BoolNullableFilter>;
  wasMarriedEntireYear?: InputMaybe<BoolNullableFilter>;
  wasResidentOfNetherlands?: InputMaybe<BoolNullableFilter>;
  wasResidentOfOtherCountry?: InputMaybe<StringNullableFilter>;
  year?: InputMaybe<IntFilter>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Invitation = {
  __typename?: 'Invitation';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  entityName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  role?: Maybe<UserEntityRole>;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationCreateManyEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvitationCreateManyEntityInputEnvelope = {
  data: Array<InvitationCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvitationCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvitationCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<InvitationCreateWithoutEntityInput>>;
  createMany?: InputMaybe<InvitationCreateManyEntityInputEnvelope>;
};

export type InvitationCreateOrConnectWithoutEntityInput = {
  create: InvitationCreateWithoutEntityInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationCreateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvitationListRelationFilter = {
  every?: InputMaybe<InvitationWhereInput>;
  none?: InputMaybe<InvitationWhereInput>;
  some?: InputMaybe<InvitationWhereInput>;
};

export type InvitationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InvitationScalarWhereInput = {
  AND?: InputMaybe<Array<InvitationScalarWhereInput>>;
  NOT?: InputMaybe<Array<InvitationScalarWhereInput>>;
  OR?: InputMaybe<Array<InvitationScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  entityId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  role?: InputMaybe<EnumUserEntityRoleNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvitationUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvitationUpdateManyWithWhereWithoutEntityInput = {
  data: InvitationUpdateManyMutationInput;
  where: InvitationScalarWhereInput;
};

export type InvitationUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvitationCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<InvitationCreateWithoutEntityInput>>;
  createMany?: InputMaybe<InvitationCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvitationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  set?: InputMaybe<Array<InvitationWhereUniqueInput>>;
  update?: InputMaybe<Array<InvitationUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<InvitationUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<InvitationUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type InvitationUpdateWithWhereUniqueWithoutEntityInput = {
  data: InvitationUpdateWithoutEntityInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationUpdateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvitationUpsertWithWhereUniqueWithoutEntityInput = {
  create: InvitationCreateWithoutEntityInput;
  update: InvitationUpdateWithoutEntityInput;
  where: InvitationWhereUniqueInput;
};

export type InvitationWhereInput = {
  AND?: InputMaybe<Array<InvitationWhereInput>>;
  NOT?: InputMaybe<Array<InvitationWhereInput>>;
  OR?: InputMaybe<Array<InvitationWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  role?: InputMaybe<EnumUserEntityRoleNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InvitationWhereUniqueInput = {
  AND?: InputMaybe<Array<InvitationWhereInput>>;
  NOT?: InputMaybe<Array<InvitationWhereInput>>;
  OR?: InputMaybe<Array<InvitationWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<EnumUserEntityRoleNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type Invoice = {
  __typename?: 'Invoice';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isProcessed: Scalars['Boolean']['output'];
  paymentMethod?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uploader?: Maybe<User>;
  uploaderId?: Maybe<Scalars['String']['output']>;
};

export type InvoiceCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityCreateNestedOneWithoutInvoicesInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutInvoicesInput>;
};

export type InvoiceCreateManyEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCreateManyEntityInputEnvelope = {
  data: Array<InvoiceCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceCreateManyInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploaderId?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceCreateManyUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceCreateManyUploaderInputEnvelope = {
  data: Array<InvoiceCreateManyUploaderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutEntityInput>>;
  createMany?: InputMaybe<InvoiceCreateManyEntityInputEnvelope>;
};

export type InvoiceCreateNestedManyWithoutUploaderInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<InvoiceCreateManyUploaderInputEnvelope>;
};

export type InvoiceCreateOrConnectWithoutEntityInput = {
  create: InvoiceCreateWithoutEntityInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateOrConnectWithoutUploaderInput = {
  create: InvoiceCreateWithoutUploaderInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceCreateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserCreateNestedOneWithoutInvoicesInput>;
};

export type InvoiceCreateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityCreateNestedOneWithoutInvoicesInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InvoiceOrderByWithRelationInput = {
  archivedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isProcessed?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  uploader?: InputMaybe<UserOrderByWithRelationInput>;
  uploaderId?: InputMaybe<SortOrderInput>;
};

export enum InvoiceScalarFieldEnum {
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Date = 'date',
  Description = 'description',
  EntityId = 'entityId',
  File = 'file',
  Id = 'id',
  IsProcessed = 'isProcessed',
  PaymentMethod = 'paymentMethod',
  UpdatedAt = 'updatedAt',
  UploaderId = 'uploaderId'
}

export type InvoiceScalarWhereInput = {
  AND?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  OR?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
};

export type InvoiceUpdateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutInvoicesNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutInvoicesNestedInput>;
};

export type InvoiceUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceUpdateManyWithWhereWithoutEntityInput = {
  data: InvoiceUpdateManyMutationInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithWhereWithoutUploaderInput = {
  data: InvoiceUpdateManyMutationInput;
  where: InvoiceScalarWhereInput;
};

export type InvoiceUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutEntityInput>>;
  createMany?: InputMaybe<InvoiceCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<InvoiceUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<InvoiceUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<InvoiceUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type InvoiceUpdateManyWithoutUploaderNestedInput = {
  connect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InvoiceCreateOrConnectWithoutUploaderInput>>;
  create?: InputMaybe<Array<InvoiceCreateWithoutUploaderInput>>;
  createMany?: InputMaybe<InvoiceCreateManyUploaderInputEnvelope>;
  delete?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InvoiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  set?: InputMaybe<Array<InvoiceWhereUniqueInput>>;
  update?: InputMaybe<Array<InvoiceUpdateWithWhereUniqueWithoutUploaderInput>>;
  updateMany?: InputMaybe<Array<InvoiceUpdateManyWithWhereWithoutUploaderInput>>;
  upsert?: InputMaybe<Array<InvoiceUpsertWithWhereUniqueWithoutUploaderInput>>;
};

export type InvoiceUpdateWithWhereUniqueWithoutEntityInput = {
  data: InvoiceUpdateWithoutEntityInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutUploaderInput = {
  data: InvoiceUpdateWithoutUploaderInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpdateWithoutEntityInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  uploader?: InputMaybe<UserUpdateOneWithoutInvoicesNestedInput>;
};

export type InvoiceUpdateWithoutUploaderInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutInvoicesNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<Scalars['Boolean']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type InvoiceUpsertWithWhereUniqueWithoutEntityInput = {
  create: InvoiceCreateWithoutEntityInput;
  update: InvoiceUpdateWithoutEntityInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceUpsertWithWhereUniqueWithoutUploaderInput = {
  create: InvoiceCreateWithoutUploaderInput;
  update: InvoiceUpdateWithoutUploaderInput;
  where: InvoiceWhereUniqueInput;
};

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  isProcessed?: InputMaybe<BoolFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
};

export type InvoiceWhereUniqueInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isProcessed?: InputMaybe<BoolFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  uploader?: InputMaybe<UserNullableRelationFilter>;
  uploaderId?: InputMaybe<UuidNullableFilter>;
};

export type InvoicesResponse = {
  __typename?: 'InvoicesResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Invoice>;
};

export enum Language {
  En = 'en',
  Nl = 'nl'
}

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveAttachment: Attachment;
  archiveEntity: Entity;
  archiveInvitation: Scalars['Boolean']['output'];
  archiveInvoice: Invoice;
  archiveReport: Report;
  createActivities: Scalars['Boolean']['output'];
  createAdmin: User;
  createAttachment: Attachment;
  createBankStatement: BankStatement;
  createEntity: Entity;
  createExpense: Expense;
  createIncomeData: IncomeData;
  createInvitations: Array<Invitation>;
  createInvoice: Invoice;
  createManyAttachments: Scalars['Boolean']['output'];
  createManyBankStatements: Scalars['Boolean']['output'];
  createManyExpenses: Scalars['Boolean']['output'];
  createManyInvoices: Scalars['Boolean']['output'];
  createPushToken: PushToken;
  createReport: Report;
  createUpdates: Scalars['Float']['output'];
  createUpdatesAllUsers: Scalars['Float']['output'];
  deleteUser: Scalars['Boolean']['output'];
  destroyIncomeData: Scalars['Boolean']['output'];
  destroyPushToken: Scalars['Boolean']['output'];
  downloadAttachments: Scalars['Boolean']['output'];
  downloadBankStatements: Scalars['Boolean']['output'];
  downloadExpenses: Scalars['Boolean']['output'];
  downloadIncomeData: Scalars['Boolean']['output'];
  downloadInvoices: Scalars['Boolean']['output'];
  downloadReports: Scalars['Boolean']['output'];
  exportExpenses: Scalars['String']['output'];
  exportInvoices: Scalars['String']['output'];
  forgotPassword: Scalars['Boolean']['output'];
  forwardExpenses: Scalars['Boolean']['output'];
  forwardInvoices: Scalars['Boolean']['output'];
  getBulkSignedS3UrlForPut?: Maybe<Array<SignedResponse>>;
  getSignedS3UrlForPut?: Maybe<SignedResponse>;
  login: AuthResponse;
  logout: Scalars['Boolean']['output'];
  register: AuthResponse;
  removeUserFromEntity: Scalars['Boolean']['output'];
  resetPassword: Scalars['Boolean']['output'];
  sendVerification: VerificationResponse;
  unArchiveEntity: Entity;
  unarchiveAttachment: Attachment;
  unarchiveInvoice: Invoice;
  unarchiveReport: Report;
  updateAttachment: Attachment;
  updateAttachments: Scalars['Boolean']['output'];
  updateBankStatement: BankStatement;
  updateBankStatements: Scalars['Boolean']['output'];
  updateEntity: Entity;
  updateExpense: Expense;
  updateExpenses: Scalars['Boolean']['output'];
  updateIncomeData: IncomeData;
  updateInvoice: Invoice;
  updateInvoiceIsProcessed: Invoice;
  updateInvoices: Scalars['Boolean']['output'];
  updateMe: User;
  updateReport: Report;
  updateReports: Scalars['Boolean']['output'];
  updateUpdate: Update;
  updateUpdates: Scalars['Float']['output'];
  updateUser: User;
  updateUserEntity: UserEntity;
  verifyPhone: Scalars['Boolean']['output'];
};


export type MutationArchiveAttachmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveEntityArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateActivitiesArgs = {
  data: Array<ActivityCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateAdminArgs = {
  data: CreateAdminInput;
};


export type MutationCreateAttachmentArgs = {
  data: AttachmentCreateInput;
};


export type MutationCreateBankStatementArgs = {
  data: BankStatementCreateInput;
};


export type MutationCreateEntityArgs = {
  data: EntityCreateInput;
};


export type MutationCreateExpenseArgs = {
  data: ExpenseCreateInput;
};


export type MutationCreateIncomeDataArgs = {
  data: IncomeDataCreateInput;
};


export type MutationCreateInvitationsArgs = {
  emails: Array<Scalars['String']['input']>;
  entityId: Scalars['String']['input'];
  userEntityRole: Scalars['String']['input'];
};


export type MutationCreateInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationCreateManyAttachmentsArgs = {
  data: Array<AttachmentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyBankStatementsArgs = {
  data: Array<BankStatementCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyExpensesArgs = {
  data: Array<ExpenseCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyInvoicesArgs = {
  data: Array<InvoiceCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreatePushTokenArgs = {
  data: PushTokenCreateInput;
};


export type MutationCreateReportArgs = {
  data: ReportCreateInput;
};


export type MutationCreateUpdatesArgs = {
  data: CreateUpdatesInput;
};


export type MutationCreateUpdatesAllUsersArgs = {
  data: CreateUpdatesAllUsersInput;
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDestroyIncomeDataArgs = {
  id: Scalars['String']['input'];
};


export type MutationDestroyPushTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationDownloadAttachmentsArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  period: Scalars['String']['input'];
  selectedAttachmentIds: Array<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type MutationDownloadBankStatementsArgs = {
  cursor?: InputMaybe<BankStatementWhereUniqueInput>;
  distinct?: InputMaybe<Array<BankStatementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput>>;
  period: Scalars['String']['input'];
  selectedBankStatementIds: Array<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankStatementWhereInput>;
};


export type MutationDownloadExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  markAsProcessed: Scalars['Boolean']['input'];
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  period: Scalars['String']['input'];
  selectedExpenseIds: Array<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExpenseWhereInput>;
};


export type MutationDownloadIncomeDataArgs = {
  id: Scalars['String']['input'];
};


export type MutationDownloadInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  markAsProcessed: Scalars['Boolean']['input'];
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  period: Scalars['String']['input'];
  selectedInvoiceIds: Array<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type MutationDownloadReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  period: Scalars['String']['input'];
  selectedReportIds: Array<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type MutationExportExpensesArgs = {
  entityId: Scalars['String']['input'];
};


export type MutationExportInvoicesArgs = {
  entityId: Scalars['String']['input'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationForwardExpensesArgs = {
  entityId: Scalars['String']['input'];
  where: ExpenseWhereInput;
};


export type MutationForwardInvoicesArgs = {
  entityId: Scalars['String']['input'];
  where: InvoiceWhereInput;
};


export type MutationGetBulkSignedS3UrlForPutArgs = {
  data: S3BulkSignedUrlInput;
};


export type MutationGetSignedS3UrlForPutArgs = {
  data: S3SignedUrlInput;
};


export type MutationLoginArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  data: LoginInput;
};


export type MutationRegisterArgs = {
  code: Scalars['String']['input'];
  data: RegisterInput;
};


export type MutationRemoveUserFromEntityArgs = {
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationSendVerificationArgs = {
  data: LoginInput;
};


export type MutationUnArchiveEntityArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnarchiveAttachmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnarchiveInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationUnarchiveReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAttachmentArgs = {
  data: AttachmentUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateAttachmentsArgs = {
  data: AttachmentUpdateInput;
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateBankStatementArgs = {
  data: BankStatementUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateBankStatementsArgs = {
  data: BankStatementUpdateInput;
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateEntityArgs = {
  data: EntityUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateExpenseArgs = {
  data: ExpenseUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateExpensesArgs = {
  data: ExpenseUpdateInput;
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateIncomeDataArgs = {
  data: IncomeDataUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateInvoiceArgs = {
  data: InvoiceUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateInvoiceIsProcessedArgs = {
  id: Scalars['String']['input'];
  isProcessed: Scalars['Boolean']['input'];
};


export type MutationUpdateInvoicesArgs = {
  data: InvoiceUpdateInput;
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateMeArgs = {
  data: UserUpdateInput;
};


export type MutationUpdateReportArgs = {
  data: ReportUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateReportsArgs = {
  data: ReportUpdateInput;
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']>;
};


export type MutationUpdateUpdateArgs = {
  data: UpdateUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateUpdatesArgs = {
  data: UpdateUpdatesInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateUserEntityArgs = {
  id: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationVerifyPhoneArgs = {
  phone: Scalars['String']['input'];
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumActivityTypeFilter = {
  equals?: InputMaybe<ActivityType>;
  in?: InputMaybe<Array<ActivityType>>;
  not?: InputMaybe<NestedEnumActivityTypeFilter>;
  notIn?: InputMaybe<Array<ActivityType>>;
};

export type NestedEnumEntityTypeFilter = {
  equals?: InputMaybe<EntityType>;
  in?: InputMaybe<Array<EntityType>>;
  not?: InputMaybe<NestedEnumEntityTypeFilter>;
  notIn?: InputMaybe<Array<EntityType>>;
};

export type NestedEnumIncomeDataQuestionNullableFilter = {
  equals?: InputMaybe<IncomeDataQuestion>;
  in?: InputMaybe<Array<IncomeDataQuestion>>;
  not?: InputMaybe<NestedEnumIncomeDataQuestionNullableFilter>;
  notIn?: InputMaybe<Array<IncomeDataQuestion>>;
};

export type NestedEnumIncomeDataStatusFilter = {
  equals?: InputMaybe<IncomeDataStatus>;
  in?: InputMaybe<Array<IncomeDataStatus>>;
  not?: InputMaybe<NestedEnumIncomeDataStatusFilter>;
  notIn?: InputMaybe<Array<IncomeDataStatus>>;
};

export type NestedEnumLanguageFilter = {
  equals?: InputMaybe<Language>;
  in?: InputMaybe<Array<Language>>;
  not?: InputMaybe<NestedEnumLanguageFilter>;
  notIn?: InputMaybe<Array<Language>>;
};

export type NestedEnumPaymentMethodNullableFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodNullableFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumQuarterNullableFilter = {
  equals?: InputMaybe<Quarter>;
  in?: InputMaybe<Array<Quarter>>;
  not?: InputMaybe<NestedEnumQuarterNullableFilter>;
  notIn?: InputMaybe<Array<Quarter>>;
};

export type NestedEnumReportStatusFilter = {
  equals?: InputMaybe<ReportStatus>;
  in?: InputMaybe<Array<ReportStatus>>;
  not?: InputMaybe<NestedEnumReportStatusFilter>;
  notIn?: InputMaybe<Array<ReportStatus>>;
};

export type NestedEnumReportTypeFilter = {
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumUserEntityRoleFilter = {
  equals?: InputMaybe<UserEntityRole>;
  in?: InputMaybe<Array<UserEntityRole>>;
  not?: InputMaybe<NestedEnumUserEntityRoleFilter>;
  notIn?: InputMaybe<Array<UserEntityRole>>;
};

export type NestedEnumUserEntityRoleNullableFilter = {
  equals?: InputMaybe<UserEntityRole>;
  in?: InputMaybe<Array<UserEntityRole>>;
  not?: InputMaybe<NestedEnumUserEntityRoleNullableFilter>;
  notIn?: InputMaybe<Array<UserEntityRole>>;
};

export type NestedEnumVatPeriodNullableFilter = {
  equals?: InputMaybe<VatPeriod>;
  in?: InputMaybe<Array<VatPeriod>>;
  not?: InputMaybe<NestedEnumVatPeriodNullableFilter>;
  notIn?: InputMaybe<Array<VatPeriod>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedUuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type NestedUuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export enum PaymentMethod {
  Bank = 'BANK',
  Card = 'CARD',
  Service = 'SERVICE'
}

export type PushToken = {
  __typename?: 'PushToken';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type PushTokenCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutPushTokensInput;
};

export type PushTokenCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PushTokenCreateManyUserInputEnvelope = {
  data: Array<PushTokenCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PushTokenCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<PushTokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PushTokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PushTokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<PushTokenCreateManyUserInputEnvelope>;
};

export type PushTokenCreateOrConnectWithoutUserInput = {
  create: PushTokenCreateWithoutUserInput;
  where: PushTokenWhereUniqueInput;
};

export type PushTokenCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PushTokenListRelationFilter = {
  every?: InputMaybe<PushTokenWhereInput>;
  none?: InputMaybe<PushTokenWhereInput>;
  some?: InputMaybe<PushTokenWhereInput>;
};

export type PushTokenOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PushTokenOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum PushTokenScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type PushTokenScalarWhereInput = {
  AND?: InputMaybe<Array<PushTokenScalarWhereInput>>;
  NOT?: InputMaybe<Array<PushTokenScalarWhereInput>>;
  OR?: InputMaybe<Array<PushTokenScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type PushTokenUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PushTokenUpdateManyWithWhereWithoutUserInput = {
  data: PushTokenUpdateManyMutationInput;
  where: PushTokenScalarWhereInput;
};

export type PushTokenUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<PushTokenWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PushTokenCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<PushTokenCreateWithoutUserInput>>;
  createMany?: InputMaybe<PushTokenCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<PushTokenWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PushTokenScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PushTokenWhereUniqueInput>>;
  set?: InputMaybe<Array<PushTokenWhereUniqueInput>>;
  update?: InputMaybe<Array<PushTokenUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<PushTokenUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<PushTokenUpsertWithWhereUniqueWithoutUserInput>>;
};

export type PushTokenUpdateWithWhereUniqueWithoutUserInput = {
  data: PushTokenUpdateWithoutUserInput;
  where: PushTokenWhereUniqueInput;
};

export type PushTokenUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PushTokenUpsertWithWhereUniqueWithoutUserInput = {
  create: PushTokenCreateWithoutUserInput;
  update: PushTokenUpdateWithoutUserInput;
  where: PushTokenWhereUniqueInput;
};

export type PushTokenWhereInput = {
  AND?: InputMaybe<Array<PushTokenWhereInput>>;
  NOT?: InputMaybe<Array<PushTokenWhereInput>>;
  OR?: InputMaybe<Array<PushTokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type PushTokenWhereUniqueInput = {
  AND?: InputMaybe<Array<PushTokenWhereInput>>;
  NOT?: InputMaybe<Array<PushTokenWhereInput>>;
  OR?: InputMaybe<Array<PushTokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export type Query = {
  __typename?: 'Query';
  activity: ActivityResponse;
  admin?: Maybe<User>;
  admins: UsersResponse;
  allIncomeData: IncomeDataResponse;
  attachment?: Maybe<Attachment>;
  attachments: AttachmentsResponse;
  bankStatement?: Maybe<BankStatement>;
  bankStatements: BankStatementsResponse;
  entities: EntitiesResponse;
  entity?: Maybe<Entity>;
  expense?: Maybe<Expense>;
  expenses: ExpensesResponse;
  getSignedS3UrlForGet?: Maybe<Scalars['String']['output']>;
  incomeData?: Maybe<IncomeData>;
  invitation?: Maybe<Invitation>;
  invoice?: Maybe<Invoice>;
  invoices: InvoicesResponse;
  me?: Maybe<User>;
  pushToken?: Maybe<PushToken>;
  refreshToken?: Maybe<RefreshTokenResponse>;
  report?: Maybe<Report>;
  reports: ReportsResponse;
  updates: UpdatesResponse;
  user?: Maybe<User>;
  users: UsersResponse;
};


export type QueryActivityArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryAdminArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAdminsArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAllIncomeDataArgs = {
  cursor?: InputMaybe<IncomeDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<IncomeDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IncomeDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IncomeDataWhereInput>;
};


export type QueryAttachmentArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryAttachmentsArgs = {
  cursor?: InputMaybe<AttachmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AttachmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AttachmentWhereInput>;
};


export type QueryBankStatementArgs = {
  cursor?: InputMaybe<BankStatementWhereUniqueInput>;
  distinct?: InputMaybe<Array<BankStatementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankStatementWhereInput>;
};


export type QueryBankStatementsArgs = {
  cursor?: InputMaybe<BankStatementWhereUniqueInput>;
  distinct?: InputMaybe<Array<BankStatementScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankStatementWhereInput>;
};


export type QueryEntitiesArgs = {
  cursor?: InputMaybe<EntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntityWhereInput>;
};


export type QueryEntityArgs = {
  cursor?: InputMaybe<EntityWhereUniqueInput>;
  distinct?: InputMaybe<Array<EntityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<EntityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntityWhereInput>;
};


export type QueryExpenseArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExpenseWhereInput>;
};


export type QueryExpensesArgs = {
  cursor?: InputMaybe<ExpenseWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExpenseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExpenseWhereInput>;
};


export type QueryGetSignedS3UrlForGetArgs = {
  key: Scalars['String']['input'];
};


export type QueryIncomeDataArgs = {
  cursor?: InputMaybe<IncomeDataWhereUniqueInput>;
  distinct?: InputMaybe<Array<IncomeDataScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IncomeDataOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IncomeDataWhereInput>;
};


export type QueryInvitationArgs = {
  id: Scalars['String']['input'];
};


export type QueryInvoiceArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryInvoicesArgs = {
  cursor?: InputMaybe<InvoiceWhereUniqueInput>;
  distinct?: InputMaybe<Array<InvoiceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
};


export type QueryPushTokenArgs = {
  cursor?: InputMaybe<PushTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<PushTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PushTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PushTokenWhereInput>;
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type QueryReportArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryUpdatesArgs = {
  cursor?: InputMaybe<UpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<UpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UpdateWhereInput>;
};


export type QueryUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  invitationId: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type Report = {
  __typename?: 'Report';
  approver?: Maybe<User>;
  approverId?: Maybe<Scalars['String']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  creatorId: Scalars['String']['output'];
  entity: Entity;
  entityId: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['String']['output'];
  quarter?: Maybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt: Scalars['DateTime']['output'];
  year: Scalars['Float']['output'];
};

export type ReportCreateInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApprovedReportsInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator: UserCreateNestedOneWithoutCreatedReportsInput;
  entity: EntityCreateNestedOneWithoutReportsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportCreateManyApproverInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creatorId: Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportCreateManyApproverInputEnvelope = {
  data: Array<ReportCreateManyApproverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportCreateManyCreatorInput = {
  approverId?: InputMaybe<Scalars['String']['input']>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportCreateManyCreatorInputEnvelope = {
  data: Array<ReportCreateManyCreatorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportCreateManyEntityInput = {
  approverId?: InputMaybe<Scalars['String']['input']>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creatorId: Scalars['String']['input'];
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportCreateManyEntityInputEnvelope = {
  data: Array<ReportCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportCreateNestedManyWithoutApproverInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutApproverInput>>;
  createMany?: InputMaybe<ReportCreateManyApproverInputEnvelope>;
};

export type ReportCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<ReportCreateManyCreatorInputEnvelope>;
};

export type ReportCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutEntityInput>>;
  createMany?: InputMaybe<ReportCreateManyEntityInputEnvelope>;
};

export type ReportCreateOrConnectWithoutApproverInput = {
  create: ReportCreateWithoutApproverInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutCreatorInput = {
  create: ReportCreateWithoutCreatorInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutEntityInput = {
  create: ReportCreateWithoutEntityInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateWithoutApproverInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator: UserCreateNestedOneWithoutCreatedReportsInput;
  entity: EntityCreateNestedOneWithoutReportsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportCreateWithoutCreatorInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApprovedReportsInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutReportsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportCreateWithoutEntityInput = {
  approver?: InputMaybe<UserCreateNestedOneWithoutApprovedReportsInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator: UserCreateNestedOneWithoutCreatedReportsInput;
  file: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status: ReportStatus;
  type: ReportType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year: Scalars['Int']['input'];
};

export type ReportListRelationFilter = {
  every?: InputMaybe<ReportWhereInput>;
  none?: InputMaybe<ReportWhereInput>;
  some?: InputMaybe<ReportWhereInput>;
};

export type ReportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReportOrderByWithRelationInput = {
  approver?: InputMaybe<UserOrderByWithRelationInput>;
  approverId?: InputMaybe<SortOrderInput>;
  archivedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  creator?: InputMaybe<UserOrderByWithRelationInput>;
  creatorId?: InputMaybe<SortOrder>;
  entity?: InputMaybe<EntityOrderByWithRelationInput>;
  entityId?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  quarter?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  year?: InputMaybe<SortOrder>;
};

export enum ReportScalarFieldEnum {
  ApproverId = 'approverId',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  EntityId = 'entityId',
  File = 'file',
  Id = 'id',
  Quarter = 'quarter',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Year = 'year'
}

export type ReportScalarWhereInput = {
  AND?: InputMaybe<Array<ReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportScalarWhereInput>>;
  approverId?: InputMaybe<UuidNullableFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<UuidFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  quarter?: InputMaybe<EnumQuarterNullableFilter>;
  status?: InputMaybe<EnumReportStatusFilter>;
  type?: InputMaybe<EnumReportTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<IntFilter>;
};

export enum ReportStatus {
  Approved = 'APPROVED',
  Filed = 'FILED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum ReportType {
  Annual = 'ANNUAL',
  Corporate = 'CORPORATE',
  IcpOpgaaf = 'ICP_OPGAAF',
  Jaarsuppletie = 'JAARSUPPLETIE',
  Personal = 'PERSONAL',
  Sales = 'SALES'
}

export type ReportUpdateInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApprovedReportsNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedReportsNestedInput>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutReportsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status?: InputMaybe<ReportStatus>;
  type?: InputMaybe<ReportType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportUpdateManyMutationInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status?: InputMaybe<ReportStatus>;
  type?: InputMaybe<ReportType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportUpdateManyWithWhereWithoutApproverInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithWhereWithoutCreatorInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithWhereWithoutEntityInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithoutApproverNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutApproverInput>>;
  createMany?: InputMaybe<ReportCreateManyApproverInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutApproverInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutApproverInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutApproverInput>>;
};

export type ReportUpdateManyWithoutCreatorNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<ReportCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type ReportUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutEntityInput>>;
  createMany?: InputMaybe<ReportCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type ReportUpdateWithWhereUniqueWithoutApproverInput = {
  data: ReportUpdateWithoutApproverInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithWhereUniqueWithoutCreatorInput = {
  data: ReportUpdateWithoutCreatorInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithWhereUniqueWithoutEntityInput = {
  data: ReportUpdateWithoutEntityInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithoutApproverInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedReportsNestedInput>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutReportsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status?: InputMaybe<ReportStatus>;
  type?: InputMaybe<ReportType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportUpdateWithoutCreatorInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApprovedReportsNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutReportsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status?: InputMaybe<ReportStatus>;
  type?: InputMaybe<ReportType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportUpdateWithoutEntityInput = {
  approver?: InputMaybe<UserUpdateOneWithoutApprovedReportsNestedInput>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  creator?: InputMaybe<UserUpdateOneRequiredWithoutCreatedReportsNestedInput>;
  file?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<Quarter>;
  status?: InputMaybe<ReportStatus>;
  type?: InputMaybe<ReportType>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type ReportUpsertWithWhereUniqueWithoutApproverInput = {
  create: ReportCreateWithoutApproverInput;
  update: ReportUpdateWithoutApproverInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithWhereUniqueWithoutCreatorInput = {
  create: ReportCreateWithoutCreatorInput;
  update: ReportUpdateWithoutCreatorInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithWhereUniqueWithoutEntityInput = {
  create: ReportCreateWithoutEntityInput;
  update: ReportUpdateWithoutEntityInput;
  where: ReportWhereUniqueInput;
};

export type ReportWhereInput = {
  AND?: InputMaybe<Array<ReportWhereInput>>;
  NOT?: InputMaybe<Array<ReportWhereInput>>;
  OR?: InputMaybe<Array<ReportWhereInput>>;
  approver?: InputMaybe<UserNullableRelationFilter>;
  approverId?: InputMaybe<UuidNullableFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<UuidFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<UuidFilter>;
  quarter?: InputMaybe<EnumQuarterNullableFilter>;
  status?: InputMaybe<EnumReportStatusFilter>;
  type?: InputMaybe<EnumReportTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ReportWhereUniqueInput = {
  AND?: InputMaybe<Array<ReportWhereInput>>;
  NOT?: InputMaybe<Array<ReportWhereInput>>;
  OR?: InputMaybe<Array<ReportWhereInput>>;
  approver?: InputMaybe<UserNullableRelationFilter>;
  approverId?: InputMaybe<UuidNullableFilter>;
  archivedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserRelationFilter>;
  creatorId?: InputMaybe<UuidFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  file?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  quarter?: InputMaybe<EnumQuarterNullableFilter>;
  status?: InputMaybe<EnumReportStatusFilter>;
  type?: InputMaybe<EnumReportTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  year?: InputMaybe<IntFilter>;
};

export type ReportsResponse = {
  __typename?: 'ReportsResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Report>;
};

export type ResetPasswordInput = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export type S3BulkSignedUrlInput = {
  files: Array<S3SignedUrlInput>;
};

export type S3SignedUrlInput = {
  fileType: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type SignedResponse = {
  __typename?: 'SignedResponse';
  key: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Update = {
  __typename?: 'Update';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  message: Scalars['String']['output'];
  sender: User;
  senderId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type UpdateCreateManySenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type UpdateCreateManySenderInputEnvelope = {
  data: Array<UpdateCreateManySenderInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateCreateManyUserInputEnvelope = {
  data: Array<UpdateCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UpdateCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<UpdateCreateWithoutSenderInput>>;
  createMany?: InputMaybe<UpdateCreateManySenderInputEnvelope>;
};

export type UpdateCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UpdateCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UpdateCreateWithoutUserInput>>;
  createMany?: InputMaybe<UpdateCreateManyUserInputEnvelope>;
};

export type UpdateCreateOrConnectWithoutSenderInput = {
  create: UpdateCreateWithoutSenderInput;
  where: UpdateWhereUniqueInput;
};

export type UpdateCreateOrConnectWithoutUserInput = {
  create: UpdateCreateWithoutUserInput;
  where: UpdateWhereUniqueInput;
};

export type UpdateCreateWithoutSenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutUpdatesInput;
};

export type UpdateCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  sender: UserCreateNestedOneWithoutSentUpdatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateListRelationFilter = {
  every?: InputMaybe<UpdateWhereInput>;
  none?: InputMaybe<UpdateWhereInput>;
  some?: InputMaybe<UpdateWhereInput>;
};

export type UpdateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UpdateOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isRead?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  sender?: InputMaybe<UserOrderByWithRelationInput>;
  senderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UpdateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsRead = 'isRead',
  Message = 'message',
  SenderId = 'senderId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UpdateScalarWhereInput = {
  AND?: InputMaybe<Array<UpdateScalarWhereInput>>;
  NOT?: InputMaybe<Array<UpdateScalarWhereInput>>;
  OR?: InputMaybe<Array<UpdateScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  isRead?: InputMaybe<BoolFilter>;
  message?: InputMaybe<StringFilter>;
  senderId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UpdateUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutSentUpdatesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUpdatesNestedInput>;
};

export type UpdateUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateUpdateManyWithWhereWithoutSenderInput = {
  data: UpdateUpdateManyMutationInput;
  where: UpdateScalarWhereInput;
};

export type UpdateUpdateManyWithWhereWithoutUserInput = {
  data: UpdateUpdateManyMutationInput;
  where: UpdateScalarWhereInput;
};

export type UpdateUpdateManyWithoutSenderNestedInput = {
  connect?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UpdateCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<UpdateCreateWithoutSenderInput>>;
  createMany?: InputMaybe<UpdateCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<UpdateUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<UpdateUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<UpdateUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type UpdateUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UpdateCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UpdateCreateWithoutUserInput>>;
  createMany?: InputMaybe<UpdateCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<UpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<UpdateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UpdateUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UpdateUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UpdateUpdateWithWhereUniqueWithoutSenderInput = {
  data: UpdateUpdateWithoutSenderInput;
  where: UpdateWhereUniqueInput;
};

export type UpdateUpdateWithWhereUniqueWithoutUserInput = {
  data: UpdateUpdateWithoutUserInput;
  where: UpdateWhereUniqueInput;
};

export type UpdateUpdateWithoutSenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUpdatesNestedInput>;
};

export type UpdateUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutSentUpdatesNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateUpdatesInput = {
  isRead: Scalars['Boolean']['input'];
  updates: Array<Scalars['String']['input']>;
};

export type UpdateUpsertWithWhereUniqueWithoutSenderInput = {
  create: UpdateCreateWithoutSenderInput;
  update: UpdateUpdateWithoutSenderInput;
  where: UpdateWhereUniqueInput;
};

export type UpdateUpsertWithWhereUniqueWithoutUserInput = {
  create: UpdateCreateWithoutUserInput;
  update: UpdateUpdateWithoutUserInput;
  where: UpdateWhereUniqueInput;
};

export type UpdateWhereInput = {
  AND?: InputMaybe<Array<UpdateWhereInput>>;
  NOT?: InputMaybe<Array<UpdateWhereInput>>;
  OR?: InputMaybe<Array<UpdateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<UuidFilter>;
  isRead?: InputMaybe<BoolFilter>;
  message?: InputMaybe<StringFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UpdateWhereUniqueInput = {
  AND?: InputMaybe<Array<UpdateWhereInput>>;
  NOT?: InputMaybe<Array<UpdateWhereInput>>;
  OR?: InputMaybe<Array<UpdateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  isRead?: InputMaybe<BoolFilter>;
  message?: InputMaybe<StringFilter>;
  sender?: InputMaybe<UserRelationFilter>;
  senderId?: InputMaybe<UuidFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UpdatesResponse = {
  __typename?: 'UpdatesResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<Update>;
};

export type User = {
  __typename?: 'User';
  access: Array<Access>;
  avatar?: Maybe<Scalars['String']['output']>;
  bio?: Maybe<Scalars['String']['output']>;
  companyEntities: Array<Entity>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  entities: Array<Entity>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isIncomeDataNotificationsEnabled: Scalars['Boolean']['output'];
  isReportNotificationsEnabled: Scalars['Boolean']['output'];
  isUpcomingVatNotificationsEnabled: Scalars['Boolean']['output'];
  isUpdateToUserNotificationEnabled: Scalars['Boolean']['output'];
  language: Language;
  lastName: Scalars['String']['output'];
  latestReports: Array<Report>;
  personalEntities: Array<Entity>;
  phone?: Maybe<Scalars['String']['output']>;
  role: Role;
  unreadUpdatesCount: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  updates: UpdatesResponse;
};


export type UserUpdatesArgs = {
  cursor?: InputMaybe<UpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<UpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UpdateWhereInput>;
};

export type UserCreateNestedOneWithoutActivityInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<UserCreateWithoutActivityInput>;
};

export type UserCreateNestedOneWithoutApprovedReportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutApprovedReportsInput>;
  create?: InputMaybe<UserCreateWithoutApprovedReportsInput>;
};

export type UserCreateNestedOneWithoutAttachmentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<UserCreateWithoutAttachmentsInput>;
};

export type UserCreateNestedOneWithoutBankStatementsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBankStatementsInput>;
  create?: InputMaybe<UserCreateWithoutBankStatementsInput>;
};

export type UserCreateNestedOneWithoutCreatedReportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedReportsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedReportsInput>;
};

export type UserCreateNestedOneWithoutExpensesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<UserCreateWithoutExpensesInput>;
};

export type UserCreateNestedOneWithoutIncomeDataInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIncomeDataInput>;
  create?: InputMaybe<UserCreateWithoutIncomeDataInput>;
};

export type UserCreateNestedOneWithoutInvoicesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<UserCreateWithoutInvoicesInput>;
};

export type UserCreateNestedOneWithoutManagedEntitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutManagedEntitiesInput>;
  create?: InputMaybe<UserCreateWithoutManagedEntitiesInput>;
};

export type UserCreateNestedOneWithoutPushTokensInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPushTokensInput>;
  create?: InputMaybe<UserCreateWithoutPushTokensInput>;
};

export type UserCreateNestedOneWithoutSentUpdatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentUpdatesInput>;
  create?: InputMaybe<UserCreateWithoutSentUpdatesInput>;
};

export type UserCreateNestedOneWithoutUpdatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUpdatesInput>;
  create?: InputMaybe<UserCreateWithoutUpdatesInput>;
};

export type UserCreateNestedOneWithoutUserEntitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserEntitiesInput>;
  create?: InputMaybe<UserCreateWithoutUserEntitiesInput>;
};

export type UserCreateOrConnectWithoutActivityInput = {
  create: UserCreateWithoutActivityInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutApprovedReportsInput = {
  create: UserCreateWithoutApprovedReportsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutAttachmentsInput = {
  create: UserCreateWithoutAttachmentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBankStatementsInput = {
  create: UserCreateWithoutBankStatementsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedReportsInput = {
  create: UserCreateWithoutCreatedReportsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutExpensesInput = {
  create: UserCreateWithoutExpensesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutIncomeDataInput = {
  create: UserCreateWithoutIncomeDataInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInvoicesInput = {
  create: UserCreateWithoutInvoicesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutManagedEntitiesInput = {
  create: UserCreateWithoutManagedEntitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPushTokensInput = {
  create: UserCreateWithoutPushTokensInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSentUpdatesInput = {
  create: UserCreateWithoutSentUpdatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUpdatesInput = {
  create: UserCreateWithoutUpdatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserEntitiesInput = {
  create: UserCreateWithoutUserEntitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutActivityInput = {
  access?: InputMaybe<Array<Access>>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutApprovedReportsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutAttachmentsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutBankStatementsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutCreatedReportsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutExpensesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutIncomeDataInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutInvoicesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutManagedEntitiesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutPushTokensInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSentUpdatesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUpdatesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutUserEntitiesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityCreateNestedManyWithoutUserInput>;
  approvedReports?: InputMaybe<ReportCreateNestedManyWithoutApproverInput>;
  attachments?: InputMaybe<AttachmentCreateNestedManyWithoutUploaderInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementCreateNestedManyWithoutUploaderInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportCreateNestedManyWithoutCreatorInput>;
  email: Scalars['String']['input'];
  expenses?: InputMaybe<ExpenseCreateNestedManyWithoutUploaderInput>;
  firstName: Scalars['String']['input'];
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataCreateNestedManyWithoutCreatorInput>;
  invoices?: InputMaybe<InvoiceCreateNestedManyWithoutUploaderInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String']['input'];
  managedEntities?: InputMaybe<EntityCreateNestedManyWithoutClientManagerInput>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenCreateNestedManyWithoutUserInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateCreateNestedManyWithoutSenderInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateCreateNestedManyWithoutUserInput>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  role: UserEntityRole;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserEntityCreateManyEntityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role: UserEntityRole;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userId: Scalars['String']['input'];
};

export type UserEntityCreateManyEntityInputEnvelope = {
  data: Array<UserEntityCreateManyEntityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEntityCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entityId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  role: UserEntityRole;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserEntityCreateManyUserInputEnvelope = {
  data: Array<UserEntityCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserEntityCreateNestedManyWithoutEntityInput = {
  connect?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserEntityCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<UserEntityCreateWithoutEntityInput>>;
  createMany?: InputMaybe<UserEntityCreateManyEntityInputEnvelope>;
};

export type UserEntityCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserEntityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserEntityCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserEntityCreateManyUserInputEnvelope>;
};

export type UserEntityCreateOrConnectWithoutEntityInput = {
  create: UserEntityCreateWithoutEntityInput;
  where: UserEntityWhereUniqueInput;
};

export type UserEntityCreateOrConnectWithoutUserInput = {
  create: UserEntityCreateWithoutUserInput;
  where: UserEntityWhereUniqueInput;
};

export type UserEntityCreateWithoutEntityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role: UserEntityRole;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user: UserCreateNestedOneWithoutUserEntitiesInput;
};

export type UserEntityCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityCreateNestedOneWithoutUserEntitiesInput;
  id?: InputMaybe<Scalars['String']['input']>;
  role: UserEntityRole;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserEntityEntityIdUserIdCompoundUniqueInput = {
  entityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserEntityListRelationFilter = {
  every?: InputMaybe<UserEntityWhereInput>;
  none?: InputMaybe<UserEntityWhereInput>;
  some?: InputMaybe<UserEntityWhereInput>;
};

export type UserEntityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export enum UserEntityRole {
  Employee = 'EMPLOYEE',
  Owner = 'OWNER'
}

export type UserEntityScalarWhereInput = {
  AND?: InputMaybe<Array<UserEntityScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserEntityScalarWhereInput>>;
  OR?: InputMaybe<Array<UserEntityScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  role?: InputMaybe<EnumUserEntityRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserEntityUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserEntityUpdateManyWithWhereWithoutEntityInput = {
  data: UserEntityUpdateManyMutationInput;
  where: UserEntityScalarWhereInput;
};

export type UserEntityUpdateManyWithWhereWithoutUserInput = {
  data: UserEntityUpdateManyMutationInput;
  where: UserEntityScalarWhereInput;
};

export type UserEntityUpdateManyWithoutEntityNestedInput = {
  connect?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserEntityCreateOrConnectWithoutEntityInput>>;
  create?: InputMaybe<Array<UserEntityCreateWithoutEntityInput>>;
  createMany?: InputMaybe<UserEntityCreateManyEntityInputEnvelope>;
  delete?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<UserEntityUpdateWithWhereUniqueWithoutEntityInput>>;
  updateMany?: InputMaybe<Array<UserEntityUpdateManyWithWhereWithoutEntityInput>>;
  upsert?: InputMaybe<Array<UserEntityUpsertWithWhereUniqueWithoutEntityInput>>;
};

export type UserEntityUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserEntityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserEntityCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserEntityCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserEntityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  set?: InputMaybe<Array<UserEntityWhereUniqueInput>>;
  update?: InputMaybe<Array<UserEntityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserEntityUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserEntityUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserEntityUpdateWithWhereUniqueWithoutEntityInput = {
  data: UserEntityUpdateWithoutEntityInput;
  where: UserEntityWhereUniqueInput;
};

export type UserEntityUpdateWithWhereUniqueWithoutUserInput = {
  data: UserEntityUpdateWithoutUserInput;
  where: UserEntityWhereUniqueInput;
};

export type UserEntityUpdateWithoutEntityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserEntitiesNestedInput>;
};

export type UserEntityUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityUpdateOneRequiredWithoutUserEntitiesNestedInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<UserEntityRole>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserEntityUpsertWithWhereUniqueWithoutEntityInput = {
  create: UserEntityCreateWithoutEntityInput;
  update: UserEntityUpdateWithoutEntityInput;
  where: UserEntityWhereUniqueInput;
};

export type UserEntityUpsertWithWhereUniqueWithoutUserInput = {
  create: UserEntityCreateWithoutUserInput;
  update: UserEntityUpdateWithoutUserInput;
  where: UserEntityWhereUniqueInput;
};

export type UserEntityWhereInput = {
  AND?: InputMaybe<Array<UserEntityWhereInput>>;
  NOT?: InputMaybe<Array<UserEntityWhereInput>>;
  OR?: InputMaybe<Array<UserEntityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  id?: InputMaybe<UuidFilter>;
  role?: InputMaybe<EnumUserEntityRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserEntityWhereUniqueInput = {
  AND?: InputMaybe<Array<UserEntityWhereInput>>;
  NOT?: InputMaybe<Array<UserEntityWhereInput>>;
  OR?: InputMaybe<Array<UserEntityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entity?: InputMaybe<EntityRelationFilter>;
  entityId?: InputMaybe<UuidFilter>;
  entityId_userId?: InputMaybe<UserEntityEntityIdUserIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<EnumUserEntityRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<UuidFilter>;
};

export type UserNullableRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export type UserOrderByWithRelationInput = {
  access?: InputMaybe<SortOrder>;
  activity?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  approvedReports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  attachments?: InputMaybe<AttachmentOrderByRelationAggregateInput>;
  avatar?: InputMaybe<SortOrderInput>;
  bankStatements?: InputMaybe<BankStatementOrderByRelationAggregateInput>;
  bio?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdReports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  email?: InputMaybe<SortOrder>;
  expenses?: InputMaybe<ExpenseOrderByRelationAggregateInput>;
  firstName?: InputMaybe<SortOrder>;
  hasGmAccess?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incomeData?: InputMaybe<IncomeDataOrderByRelationAggregateInput>;
  invoices?: InputMaybe<InvoiceOrderByRelationAggregateInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<SortOrder>;
  isReportNotificationsEnabled?: InputMaybe<SortOrder>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<SortOrder>;
  isUpdateToUserNotificationEnabled?: InputMaybe<SortOrder>;
  language?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  managedEntities?: InputMaybe<EntityOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrderInput>;
  pushTokens?: InputMaybe<PushTokenOrderByRelationAggregateInput>;
  role?: InputMaybe<SortOrder>;
  sentUpdates?: InputMaybe<UpdateOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  updates?: InputMaybe<UpdateOrderByRelationAggregateInput>;
  userEntities?: InputMaybe<UserEntityOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Access = 'access',
  Avatar = 'avatar',
  Bio = 'bio',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  HasGmAccess = 'hasGmAccess',
  Id = 'id',
  IsIncomeDataNotificationsEnabled = 'isIncomeDataNotificationsEnabled',
  IsReportNotificationsEnabled = 'isReportNotificationsEnabled',
  IsUpcomingVatNotificationsEnabled = 'isUpcomingVatNotificationsEnabled',
  IsUpdateToUserNotificationEnabled = 'isUpdateToUserNotificationEnabled',
  Language = 'language',
  LastName = 'lastName',
  Password = 'password',
  Phone = 'phone',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export type UserUpdateInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateOneRequiredWithoutActivityNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivityInput>;
  create?: InputMaybe<UserCreateWithoutActivityInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutActivityInput>;
  upsert?: InputMaybe<UserUpsertWithoutActivityInput>;
};

export type UserUpdateOneRequiredWithoutCreatedReportsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedReportsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedReportsInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutCreatedReportsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedReportsInput>;
};

export type UserUpdateOneRequiredWithoutIncomeDataNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutIncomeDataInput>;
  create?: InputMaybe<UserCreateWithoutIncomeDataInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutIncomeDataInput>;
  upsert?: InputMaybe<UserUpsertWithoutIncomeDataInput>;
};

export type UserUpdateOneRequiredWithoutSentUpdatesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSentUpdatesInput>;
  create?: InputMaybe<UserCreateWithoutSentUpdatesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutSentUpdatesInput>;
  upsert?: InputMaybe<UserUpsertWithoutSentUpdatesInput>;
};

export type UserUpdateOneRequiredWithoutUpdatesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUpdatesInput>;
  create?: InputMaybe<UserCreateWithoutUpdatesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUpdatesInput>;
  upsert?: InputMaybe<UserUpsertWithoutUpdatesInput>;
};

export type UserUpdateOneRequiredWithoutUserEntitiesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserEntitiesInput>;
  create?: InputMaybe<UserCreateWithoutUserEntitiesInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutUserEntitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserEntitiesInput>;
};

export type UserUpdateOneWithoutApprovedReportsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutApprovedReportsInput>;
  create?: InputMaybe<UserCreateWithoutApprovedReportsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutApprovedReportsInput>;
  upsert?: InputMaybe<UserUpsertWithoutApprovedReportsInput>;
};

export type UserUpdateOneWithoutAttachmentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAttachmentsInput>;
  create?: InputMaybe<UserCreateWithoutAttachmentsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutAttachmentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAttachmentsInput>;
};

export type UserUpdateOneWithoutBankStatementsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBankStatementsInput>;
  create?: InputMaybe<UserCreateWithoutBankStatementsInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutBankStatementsInput>;
  upsert?: InputMaybe<UserUpsertWithoutBankStatementsInput>;
};

export type UserUpdateOneWithoutExpensesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutExpensesInput>;
  create?: InputMaybe<UserCreateWithoutExpensesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutExpensesInput>;
  upsert?: InputMaybe<UserUpsertWithoutExpensesInput>;
};

export type UserUpdateOneWithoutInvoicesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutInvoicesInput>;
  create?: InputMaybe<UserCreateWithoutInvoicesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutInvoicesInput>;
  upsert?: InputMaybe<UserUpsertWithoutInvoicesInput>;
};

export type UserUpdateOneWithoutManagedEntitiesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutManagedEntitiesInput>;
  create?: InputMaybe<UserCreateWithoutManagedEntitiesInput>;
  delete?: InputMaybe<UserWhereInput>;
  disconnect?: InputMaybe<UserWhereInput>;
  update?: InputMaybe<UserUpdateToOneWithWhereWithoutManagedEntitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutManagedEntitiesInput>;
};

export type UserUpdateToOneWithWhereWithoutActivityInput = {
  data: UserUpdateWithoutActivityInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutApprovedReportsInput = {
  data: UserUpdateWithoutApprovedReportsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutAttachmentsInput = {
  data: UserUpdateWithoutAttachmentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutBankStatementsInput = {
  data: UserUpdateWithoutBankStatementsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutCreatedReportsInput = {
  data: UserUpdateWithoutCreatedReportsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutExpensesInput = {
  data: UserUpdateWithoutExpensesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutIncomeDataInput = {
  data: UserUpdateWithoutIncomeDataInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutInvoicesInput = {
  data: UserUpdateWithoutInvoicesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutManagedEntitiesInput = {
  data: UserUpdateWithoutManagedEntitiesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutSentUpdatesInput = {
  data: UserUpdateWithoutSentUpdatesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUpdatesInput = {
  data: UserUpdateWithoutUpdatesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateToOneWithWhereWithoutUserEntitiesInput = {
  data: UserUpdateWithoutUserEntitiesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpdateWithoutActivityInput = {
  access?: InputMaybe<Array<Access>>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutApprovedReportsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutAttachmentsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutBankStatementsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutCreatedReportsInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutExpensesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutIncomeDataInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutInvoicesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutManagedEntitiesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutSentUpdatesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUpdatesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userEntities?: InputMaybe<UserEntityUpdateManyWithoutUserNestedInput>;
};

export type UserUpdateWithoutUserEntitiesInput = {
  access?: InputMaybe<Array<Access>>;
  activity?: InputMaybe<ActivityUpdateManyWithoutUserNestedInput>;
  approvedReports?: InputMaybe<ReportUpdateManyWithoutApproverNestedInput>;
  attachments?: InputMaybe<AttachmentUpdateManyWithoutUploaderNestedInput>;
  avatar?: InputMaybe<Scalars['String']['input']>;
  bankStatements?: InputMaybe<BankStatementUpdateManyWithoutUploaderNestedInput>;
  bio?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdReports?: InputMaybe<ReportUpdateManyWithoutCreatorNestedInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseUpdateManyWithoutUploaderNestedInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasGmAccess?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataUpdateManyWithoutCreatorNestedInput>;
  invoices?: InputMaybe<InvoiceUpdateManyWithoutUploaderNestedInput>;
  isIncomeDataNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isReportNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateToUserNotificationEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managedEntities?: InputMaybe<EntityUpdateManyWithoutClientManagerNestedInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  pushTokens?: InputMaybe<PushTokenUpdateManyWithoutUserNestedInput>;
  role?: InputMaybe<Role>;
  sentUpdates?: InputMaybe<UpdateUpdateManyWithoutSenderNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updates?: InputMaybe<UpdateUpdateManyWithoutUserNestedInput>;
};

export type UserUpsertWithoutActivityInput = {
  create: UserCreateWithoutActivityInput;
  update: UserUpdateWithoutActivityInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutApprovedReportsInput = {
  create: UserCreateWithoutApprovedReportsInput;
  update: UserUpdateWithoutApprovedReportsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutAttachmentsInput = {
  create: UserCreateWithoutAttachmentsInput;
  update: UserUpdateWithoutAttachmentsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutBankStatementsInput = {
  create: UserCreateWithoutBankStatementsInput;
  update: UserUpdateWithoutBankStatementsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutCreatedReportsInput = {
  create: UserCreateWithoutCreatedReportsInput;
  update: UserUpdateWithoutCreatedReportsInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutExpensesInput = {
  create: UserCreateWithoutExpensesInput;
  update: UserUpdateWithoutExpensesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutIncomeDataInput = {
  create: UserCreateWithoutIncomeDataInput;
  update: UserUpdateWithoutIncomeDataInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutInvoicesInput = {
  create: UserCreateWithoutInvoicesInput;
  update: UserUpdateWithoutInvoicesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutManagedEntitiesInput = {
  create: UserCreateWithoutManagedEntitiesInput;
  update: UserUpdateWithoutManagedEntitiesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutSentUpdatesInput = {
  create: UserCreateWithoutSentUpdatesInput;
  update: UserUpdateWithoutSentUpdatesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUpdatesInput = {
  create: UserCreateWithoutUpdatesInput;
  update: UserUpdateWithoutUpdatesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserUpsertWithoutUserEntitiesInput = {
  create: UserCreateWithoutUserEntitiesInput;
  update: UserUpdateWithoutUserEntitiesInput;
  where?: InputMaybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  access?: InputMaybe<EnumAccessNullableListFilter>;
  activity?: InputMaybe<ActivityListRelationFilter>;
  approvedReports?: InputMaybe<ReportListRelationFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  bankStatements?: InputMaybe<BankStatementListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdReports?: InputMaybe<ReportListRelationFilter>;
  email?: InputMaybe<StringFilter>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  firstName?: InputMaybe<StringFilter>;
  hasGmAccess?: InputMaybe<BoolFilter>;
  id?: InputMaybe<UuidFilter>;
  incomeData?: InputMaybe<IncomeDataListRelationFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  isIncomeDataNotificationsEnabled?: InputMaybe<BoolFilter>;
  isReportNotificationsEnabled?: InputMaybe<BoolFilter>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<BoolFilter>;
  isUpdateToUserNotificationEnabled?: InputMaybe<BoolFilter>;
  language?: InputMaybe<EnumLanguageFilter>;
  lastName?: InputMaybe<StringFilter>;
  managedEntities?: InputMaybe<EntityListRelationFilter>;
  password?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  pushTokens?: InputMaybe<PushTokenListRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  sentUpdates?: InputMaybe<UpdateListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updates?: InputMaybe<UpdateListRelationFilter>;
  userEntities?: InputMaybe<UserEntityListRelationFilter>;
};

export type UserWhereUniqueInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  access?: InputMaybe<EnumAccessNullableListFilter>;
  activity?: InputMaybe<ActivityListRelationFilter>;
  approvedReports?: InputMaybe<ReportListRelationFilter>;
  attachments?: InputMaybe<AttachmentListRelationFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  bankStatements?: InputMaybe<BankStatementListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdReports?: InputMaybe<ReportListRelationFilter>;
  email?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<ExpenseListRelationFilter>;
  firstName?: InputMaybe<StringFilter>;
  hasGmAccess?: InputMaybe<BoolFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  incomeData?: InputMaybe<IncomeDataListRelationFilter>;
  invoices?: InputMaybe<InvoiceListRelationFilter>;
  isIncomeDataNotificationsEnabled?: InputMaybe<BoolFilter>;
  isReportNotificationsEnabled?: InputMaybe<BoolFilter>;
  isUpcomingVatNotificationsEnabled?: InputMaybe<BoolFilter>;
  isUpdateToUserNotificationEnabled?: InputMaybe<BoolFilter>;
  language?: InputMaybe<EnumLanguageFilter>;
  lastName?: InputMaybe<StringFilter>;
  managedEntities?: InputMaybe<EntityListRelationFilter>;
  password?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  pushTokens?: InputMaybe<PushTokenListRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  sentUpdates?: InputMaybe<UpdateListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  updates?: InputMaybe<UpdateListRelationFilter>;
  userEntities?: InputMaybe<UserEntityListRelationFilter>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  allItemIds?: Maybe<Array<Scalars['String']['output']>>;
  count: Scalars['Int']['output'];
  items: Array<User>;
};

export type UuidFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UuidNullableFilter = {
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedUuidNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export enum VatPeriod {
  Monthly = 'MONTHLY',
  None = 'NONE',
  Quarterly = 'QUARTERLY',
  Yearly = 'YEARLY'
}

export type VerificationResponse = {
  __typename?: 'VerificationResponse';
  skip2fa: Scalars['Boolean']['output'];
};

export type AdminCreateAdminMutationVariables = Exact<{
  data: CreateAdminInput;
}>;


export type AdminCreateAdminMutation = { __typename?: 'Mutation', createAdmin: { __typename?: 'User', id: string } };

export type AdminCreateAttachmentMutationVariables = Exact<{
  data: AttachmentCreateInput;
}>;


export type AdminCreateAttachmentMutation = { __typename?: 'Mutation', createAttachment: { __typename?: 'Attachment', id: string, name: string, date?: string | null, file: string, entityId: string } };

export type AdminCreateBankStatementMutationVariables = Exact<{
  data: BankStatementCreateInput;
}>;


export type AdminCreateBankStatementMutation = { __typename?: 'Mutation', createBankStatement: { __typename?: 'BankStatement', id: string, name: string, date?: string | null, file: string, entityId: string } };

export type CreateManyBankStatementsMutationVariables = Exact<{
  data: Array<BankStatementCreateManyInput> | BankStatementCreateManyInput;
}>;


export type CreateManyBankStatementsMutation = { __typename?: 'Mutation', createManyBankStatements: boolean };

export type ClientManagerOptionItemFragment = { __typename?: 'User', id: string, fullName: string };

export type AdminGetClientManagersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  where?: InputMaybe<UserWhereInput>;
}>;


export type AdminGetClientManagersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number, items: Array<{ __typename?: 'User', id: string, fullName: string }> } };

export type AdminCreateEntityMutationVariables = Exact<{
  data: EntityCreateInput;
}>;


export type AdminCreateEntityMutation = { __typename?: 'Mutation', createEntity: { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, type: EntityType, archivedAt?: string | null, forwardingEmail?: string | null, unprocessedDocuments: { __typename?: 'EntityUnprocessedDocumentsResponse', expenseCount: number, invoiceCount: number, bankStatementCount: number, total: number }, latestActivity?: { __typename?: 'Activity', id: string, type: ActivityType, createdAt: string } | null, clientManager?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminCreateExpenseMutationVariables = Exact<{
  data: ExpenseCreateInput;
}>;


export type AdminCreateExpenseMutation = { __typename?: 'Mutation', createExpense: { __typename?: 'Expense', id: string, description?: string | null, file: string, entityId: string, date: string } };

export type AdminCreateManyExpenseMutationVariables = Exact<{
  data: Array<ExpenseCreateManyInput> | ExpenseCreateManyInput;
}>;


export type AdminCreateManyExpenseMutation = { __typename?: 'Mutation', createManyExpenses: boolean };

export type AdminCreateInvoiceMutationVariables = Exact<{
  data: InvoiceCreateInput;
}>;


export type AdminCreateInvoiceMutation = { __typename?: 'Mutation', createInvoice: { __typename?: 'Invoice', id: string, description?: string | null, file: string, entityId: string, date: string } };

export type AdminCreateManyInvoiceMutationVariables = Exact<{
  data: Array<InvoiceCreateManyInput> | InvoiceCreateManyInput;
}>;


export type AdminCreateManyInvoiceMutation = { __typename?: 'Mutation', createManyInvoices: boolean };

export type AdminDownloadAttachmentsMutationVariables = Exact<{
  period: Scalars['String']['input'];
  selectedAttachmentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  where?: InputMaybe<AttachmentWhereInput>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput> | AttachmentOrderByWithRelationInput>;
}>;


export type AdminDownloadAttachmentsMutation = { __typename?: 'Mutation', downloadAttachments: boolean };

export type AdminDownloadBankStatementsMutationVariables = Exact<{
  period: Scalars['String']['input'];
  selectedBankStatementIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  where?: InputMaybe<BankStatementWhereInput>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput> | BankStatementOrderByWithRelationInput>;
}>;


export type AdminDownloadBankStatementsMutation = { __typename?: 'Mutation', downloadBankStatements: boolean };

export type AdminDownloadExpensesMutationVariables = Exact<{
  period: Scalars['String']['input'];
  markAsProcessed: Scalars['Boolean']['input'];
  selectedExpenseIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  where?: InputMaybe<ExpenseWhereInput>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput> | ExpenseOrderByWithRelationInput>;
}>;


export type AdminDownloadExpensesMutation = { __typename?: 'Mutation', downloadExpenses: boolean };

export type AdminDownloadInvoicesMutationVariables = Exact<{
  period: Scalars['String']['input'];
  markAsProcessed: Scalars['Boolean']['input'];
  selectedInvoiceIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  where?: InputMaybe<InvoiceWhereInput>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput> | InvoiceOrderByWithRelationInput>;
}>;


export type AdminDownloadInvoicesMutation = { __typename?: 'Mutation', downloadInvoices: boolean };

export type AdminDownloadReportsMutationVariables = Exact<{
  period: Scalars['String']['input'];
  selectedReportIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  where?: InputMaybe<ReportWhereInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput> | ReportOrderByWithRelationInput>;
}>;


export type AdminDownloadReportsMutation = { __typename?: 'Mutation', downloadReports: boolean };

export type AdminExpenseForwardingEmailFragment = { __typename?: 'Entity', id: string, name: string, forwardingEmail?: string | null };

export type GetAdminEntityForwardingEmailQueryVariables = Exact<{
  where?: InputMaybe<EntityWhereInput>;
}>;


export type GetAdminEntityForwardingEmailQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, name: string, forwardingEmail?: string | null } | null };

export type AdminForwardExpensesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  where: ExpenseWhereInput;
}>;


export type AdminForwardExpensesMutation = { __typename?: 'Mutation', forwardExpenses: boolean };

export type AdminForwardInvoicesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  where: InvoiceWhereInput;
}>;


export type AdminForwardInvoicesMutation = { __typename?: 'Mutation', forwardInvoices: boolean };

export type UpdateLanguageMutationVariables = Exact<{
  data: UserUpdateInput;
}>;


export type UpdateLanguageMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', id: string, language: Language } };

export type AdminUpdateEntityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: EntityUpdateInput;
}>;


export type AdminUpdateEntityMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, rsin?: string | null, kvk?: string | null, btw?: string | null, btwId?: string | null, type: EntityType, vatPeriod?: VatPeriod | null, archivedAt?: string | null, clientManagerId?: string | null, forwardingEmail?: string | null, users: Array<{ __typename?: 'User', id: string, fullName: string, avatar?: string | null }>, userEntities: Array<{ __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } }>, invitations: Array<{ __typename?: 'Invitation', id: string, email: string, createdAt: string, role?: UserEntityRole | null }> } };

export type AdminUpdateExpenseMutationVariables = Exact<{
  data: ExpenseUpdateInput;
  id: Scalars['String']['input'];
}>;


export type AdminUpdateExpenseMutation = { __typename?: 'Mutation', updateExpense: { __typename?: 'Expense', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUpdateInvoiceMutationVariables = Exact<{
  data: InvoiceUpdateInput;
  id: Scalars['String']['input'];
}>;


export type AdminUpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice: { __typename?: 'Invoice', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUpdateUserEntityMutationVariables = Exact<{
  id: Scalars['String']['input'];
  role: Scalars['String']['input'];
}>;


export type AdminUpdateUserEntityMutation = { __typename?: 'Mutation', updateUserEntity: { __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } } };

export type AttachmentItemFragment = { __typename?: 'Attachment', id: string, name: string, file: string, year?: number | null, date?: string | null, createdAt: string };

export type GetEntityAttachmentsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput> | AttachmentOrderByWithRelationInput>;
  where?: InputMaybe<EntityWhereInput>;
  attachmentWhere?: InputMaybe<AttachmentWhereInput>;
}>;


export type GetEntityAttachmentsQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, attachments: { __typename?: 'AttachmentsResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, year?: number | null, date?: string | null, createdAt: string }> } } | null };

export type BulkUpdateAttachmentsMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: AttachmentUpdateInput;
}>;


export type BulkUpdateAttachmentsMutation = { __typename?: 'Mutation', updateAttachments: boolean };

export type BankStatementItemFragment = { __typename?: 'BankStatement', id: string, name: string, file: string, date?: string | null, year?: number | null, createdAt: string };

export type GetEntityBankStatementsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput> | BankStatementOrderByWithRelationInput>;
  where?: InputMaybe<EntityWhereInput>;
  bankStatementWhere?: InputMaybe<BankStatementWhereInput>;
}>;


export type GetEntityBankStatementsQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, bankStatements: { __typename?: 'BankStatementsResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'BankStatement', id: string, name: string, file: string, date?: string | null, year?: number | null, createdAt: string }> } } | null };

export type UpdateBankStatementMutationVariables = Exact<{
  data: BankStatementUpdateInput;
  id: Scalars['String']['input'];
}>;


export type UpdateBankStatementMutation = { __typename?: 'Mutation', updateBankStatement: { __typename?: 'BankStatement', id: string, name: string, file: string, date?: string | null, year?: number | null, createdAt: string } };

export type BulkUpdateBankStatementsMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: BankStatementUpdateInput;
}>;


export type BulkUpdateBankStatementsMutation = { __typename?: 'Mutation', updateBankStatements: boolean };

export type CreateAttachmentMutationVariables = Exact<{
  data: AttachmentCreateInput;
}>;


export type CreateAttachmentMutation = { __typename?: 'Mutation', createAttachment: { __typename?: 'Attachment', id: string, name: string, file: string, entityId: string, year?: number | null } };

export type CreateBankStatementMutationVariables = Exact<{
  data: BankStatementCreateInput;
}>;


export type CreateBankStatementMutation = { __typename?: 'Mutation', createBankStatement: { __typename?: 'BankStatement', id: string, name: string, file: string, entityId: string, year?: number | null } };

export type CreateExpenseMutationVariables = Exact<{
  data: ExpenseCreateInput;
}>;


export type CreateExpenseMutation = { __typename?: 'Mutation', createExpense: { __typename?: 'Expense', description?: string | null, file: string, entityId: string, date: string, id: string } };

export type CreateManyExpensesMutationVariables = Exact<{
  data: Array<ExpenseCreateManyInput> | ExpenseCreateManyInput;
}>;


export type CreateManyExpensesMutation = { __typename?: 'Mutation', createManyExpenses: boolean };

export type GetUserCompanyEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserCompanyEntitiesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, companyEntities: Array<{ __typename?: 'Entity', id: string, name: string }> } | null };

export type CreateInvoiceMutationVariables = Exact<{
  data: InvoiceCreateInput;
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice: { __typename?: 'Invoice', id: string, description?: string | null, file: string, entityId: string, date: string } };

export type CreateManyInvoicesMutationVariables = Exact<{
  data: Array<InvoiceCreateManyInput> | InvoiceCreateManyInput;
}>;


export type CreateManyInvoicesMutation = { __typename?: 'Mutation', createManyInvoices: boolean };

export type CreateUpdatesMutationVariables = Exact<{
  data: CreateUpdatesInput;
}>;


export type CreateUpdatesMutation = { __typename?: 'Mutation', createUpdates: number };

export type CreateUpdatesAllUsersMutationVariables = Exact<{
  data: CreateUpdatesAllUsersInput;
}>;


export type CreateUpdatesAllUsersMutation = { __typename?: 'Mutation', createUpdatesAllUsers: number };

export type ExpenseItemFragment = { __typename?: 'Expense', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null };

export type GetEntityExpensesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput> | ExpenseOrderByWithRelationInput>;
  where?: InputMaybe<EntityWhereInput>;
  expenseWhere?: InputMaybe<ExpenseWhereInput>;
}>;


export type GetEntityExpensesQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, expenses: { __typename?: 'ExpensesResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Expense', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null }> } } | null };

export type BulkUpdateExpensesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: ExpenseUpdateInput;
}>;


export type BulkUpdateExpensesMutation = { __typename?: 'Mutation', updateExpenses: boolean };

export type IncomeDataItemFragment = { __typename?: 'IncomeData', id: string, year: number, status: IncomeDataStatus };

export type GetEntityIncomeDataQueryVariables = Exact<{
  where?: InputMaybe<EntityWhereInput>;
  incomeDataWhere?: InputMaybe<IncomeDataWhereInput>;
}>;


export type GetEntityIncomeDataQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, name: string, incomeData: { __typename?: 'IncomeDataResponse', count: number, items: Array<{ __typename?: 'IncomeData', id: string, year: number, status: IncomeDataStatus }> } } | null };

export type GetEntityInvoicesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput> | InvoiceOrderByWithRelationInput>;
  where?: InputMaybe<EntityWhereInput>;
  invoiceWhere?: InputMaybe<InvoiceWhereInput>;
}>;


export type GetEntityInvoicesQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, invoices: { __typename?: 'InvoicesResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Invoice', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null }> } } | null };

export type InvoiceItemFragment = { __typename?: 'Invoice', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null };

export type ArchiveInvoiceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ArchiveInvoiceMutation = { __typename?: 'Mutation', archiveInvoice: { __typename?: 'Invoice', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null } };

export type BulkUpdateInvoicesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: InvoiceUpdateInput;
}>;


export type BulkUpdateInvoicesMutation = { __typename?: 'Mutation', updateInvoices: boolean };

export type LatestReportItemFragment = { __typename?: 'Report', id: string, createdAt: string, quarter?: Quarter | null, year: number, type: ReportType, status: ReportStatus, file: string, entity: { __typename?: 'Entity', id: string, name: string } };

export type GetLatestReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestReportsQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, latestReports: Array<{ __typename?: 'Report', id: string, createdAt: string, quarter?: Quarter | null, year: number, type: ReportType, status: ReportStatus, file: string, entity: { __typename?: 'Entity', id: string, name: string } }> } | null };

export type UpdateReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: ReportUpdateInput;
}>;


export type UpdateReportMutation = { __typename?: 'Mutation', updateReport: { __typename?: 'Report', id: string, year: number, type: ReportType, quarter?: Quarter | null, file: string, status: ReportStatus, entityId: string } };

export type ReportItemFragment = { __typename?: 'Report', id: string, quarter?: Quarter | null, year: number, type: ReportType, status: ReportStatus, file: string, createdAt: string };

export type GetEntityReportsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput> | ReportOrderByWithRelationInput>;
  where?: InputMaybe<EntityWhereInput>;
  reportWhere?: InputMaybe<ReportWhereInput>;
}>;


export type GetEntityReportsQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, name: string, reports: { __typename?: 'ReportsResponse', count: number, items: Array<{ __typename?: 'Report', id: string, quarter?: Quarter | null, year: number, type: ReportType, status: ReportStatus, file: string, createdAt: string }> } } | null };

export type UpdateExpenseMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: ExpenseUpdateInput;
}>;


export type UpdateExpenseMutation = { __typename?: 'Mutation', updateExpense: { __typename?: 'Expense', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null } };

export type UpdateInvoiceMutationVariables = Exact<{
  data: InvoiceUpdateInput;
  id: Scalars['String']['input'];
}>;


export type UpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice: { __typename?: 'Invoice', id: string, description?: string | null, date: string, createdAt: string, file: string, paymentMethod?: string | null } };

export type UpdateItemFragment = { __typename?: 'Update', id: string, message: string, isRead?: boolean | null, createdAt: string, sender: { __typename?: 'User', id: string, firstName: string, avatar?: string | null } };

export type GetUpdatesQueryVariables = Exact<{
  where?: InputMaybe<UpdateWhereInput>;
  orderBy?: InputMaybe<Array<UpdateOrderByWithRelationInput> | UpdateOrderByWithRelationInput>;
}>;


export type GetUpdatesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, updates: { __typename?: 'UpdatesResponse', items: Array<{ __typename?: 'Update', id: string, message: string, isRead?: boolean | null, createdAt: string, sender: { __typename?: 'User', id: string, firstName: string, avatar?: string | null } }> } } | null };

export type GetNewUpdatesCountQueryVariables = Exact<{
  where?: InputMaybe<UpdateWhereInput>;
}>;


export type GetNewUpdatesCountQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, updates: { __typename?: 'UpdatesResponse', count: number } } | null };

export type UpdateUpdateMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateUpdateInput;
}>;


export type UpdateUpdateMutation = { __typename?: 'Mutation', updateUpdate: { __typename?: 'Update', id: string, message: string, isRead?: boolean | null, createdAt: string, sender: { __typename?: 'User', id: string, firstName: string, avatar?: string | null } } };

export type EntityItemFragment = { __typename?: 'Entity', id: string, name: string };

export type GetUserEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserEntitiesQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, entities: Array<{ __typename?: 'Entity', id: string, name: string }> } | null };

export type UserTaskEntityFragment = { __typename?: 'Entity', id: string, name: string, type: EntityType, hasUpcomingVatDeadline: boolean, pendingReports: Array<{ __typename?: 'Report', id: string, year: number, quarter?: Quarter | null, type: ReportType, file: string }>, pendingIncomeData: Array<{ __typename?: 'IncomeData', id: string, year: number }> };

export type UserTasksFragment = { __typename?: 'User', id: string, entities: Array<{ __typename?: 'Entity', id: string, name: string, type: EntityType, hasUpcomingVatDeadline: boolean, pendingReports: Array<{ __typename?: 'Report', id: string, year: number, quarter?: Quarter | null, type: ReportType, file: string }>, pendingIncomeData: Array<{ __typename?: 'IncomeData', id: string, year: number }> }> };

export type GetUserTasksQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type GetUserTasksQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, entities: Array<{ __typename?: 'Entity', id: string, name: string, type: EntityType, hasUpcomingVatDeadline: boolean, pendingReports: Array<{ __typename?: 'Report', id: string, year: number, quarter?: Quarter | null, type: ReportType, file: string }>, pendingIncomeData: Array<{ __typename?: 'IncomeData', id: string, year: number }> }> } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type MeFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, avatar?: string | null, email: string, access: Array<Access>, role: Role, language: Language, companyEntities: Array<{ __typename?: 'Entity', id: string }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, avatar?: string | null, email: string, access: Array<Access>, role: Role, language: Language, companyEntities: Array<{ __typename?: 'Entity', id: string }> } | null };

export type GetSignedUrlForPutMutationVariables = Exact<{
  data: S3SignedUrlInput;
}>;


export type GetSignedUrlForPutMutation = { __typename?: 'Mutation', getSignedS3UrlForPut?: { __typename?: 'SignedResponse', url: string, uploadUrl: string } | null };

export type GetBulkSignedUrlForPutMutationVariables = Exact<{
  data: S3BulkSignedUrlInput;
}>;


export type GetBulkSignedUrlForPutMutation = { __typename?: 'Mutation', getBulkSignedS3UrlForPut?: Array<{ __typename?: 'SignedResponse', url: string, uploadUrl: string, key: string }> | null };

export type AdminAdminDetailFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, phone?: string | null, avatar?: string | null, email: string, createdAt: string };

export type AdminGetAdminQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;


export type AdminGetAdminQuery = { __typename?: 'Query', admin?: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, phone?: string | null, avatar?: string | null, email: string, createdAt: string } | null };

export type AdminAdminItemFragment = { __typename?: 'User', id: string, fullName: string, email: string, createdAt: string, access: Array<Access> };

export type AdminGetAdminsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  where?: InputMaybe<UserWhereInput>;
}>;


export type AdminGetAdminsQuery = { __typename?: 'Query', admins: { __typename?: 'UsersResponse', count: number, items: Array<{ __typename?: 'User', id: string, fullName: string, email: string, createdAt: string, access: Array<Access> }> } };

export type AdminAttachmentItemFragment = { __typename?: 'Attachment', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, incomeDataId?: string | null, incomeDataQuestion?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null };

export type AdminGetEntityAttachmentsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AttachmentWhereInput>;
  orderBy?: InputMaybe<Array<AttachmentOrderByWithRelationInput> | AttachmentOrderByWithRelationInput>;
}>;


export type AdminGetEntityAttachmentsQuery = { __typename?: 'Query', attachments: { __typename?: 'AttachmentsResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Attachment', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, incomeDataId?: string | null, incomeDataQuestion?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null }> } };

export type AdminBulkUpdateAttachmentsMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: AttachmentUpdateInput;
}>;


export type AdminBulkUpdateAttachmentsMutation = { __typename?: 'Mutation', updateAttachments: boolean };

export type AdminArchiveAttachmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminArchiveAttachmentMutation = { __typename?: 'Mutation', archiveAttachment: { __typename?: 'Attachment', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, incomeDataId?: string | null, incomeDataQuestion?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUnarchiveAttachmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminUnarchiveAttachmentMutation = { __typename?: 'Mutation', unarchiveAttachment: { __typename?: 'Attachment', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, incomeDataId?: string | null, incomeDataQuestion?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUpdateAttachmentMutationVariables = Exact<{
  data: AttachmentUpdateInput;
  id: Scalars['String']['input'];
}>;


export type AdminUpdateAttachmentMutation = { __typename?: 'Mutation', updateAttachment: { __typename?: 'Attachment', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, incomeDataId?: string | null, incomeDataQuestion?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminBankStatementItemFragment = { __typename?: 'BankStatement', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null };

export type AdminGetEntityBankStatementsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BankStatementWhereInput>;
  orderBy?: InputMaybe<Array<BankStatementOrderByWithRelationInput> | BankStatementOrderByWithRelationInput>;
}>;


export type AdminGetEntityBankStatementsQuery = { __typename?: 'Query', bankStatements: { __typename?: 'BankStatementsResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'BankStatement', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null }> } };

export type AdminBulkUpdateBankStatementsMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: BankStatementUpdateInput;
}>;


export type AdminBulkUpdateBankStatementsMutation = { __typename?: 'Mutation', updateBankStatements: boolean };

export type AdminUpdateBankStatementMutationVariables = Exact<{
  data: BankStatementUpdateInput;
  id: Scalars['String']['input'];
}>;


export type AdminUpdateBankStatementMutation = { __typename?: 'Mutation', updateBankStatement: { __typename?: 'BankStatement', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminExpenseItemFragment = { __typename?: 'Expense', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null };

export type AdminGetEntityExpensesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExpenseWhereInput>;
  orderBy?: InputMaybe<Array<ExpenseOrderByWithRelationInput> | ExpenseOrderByWithRelationInput>;
}>;


export type AdminGetEntityExpensesQuery = { __typename?: 'Query', expenses: { __typename?: 'ExpensesResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Expense', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null }> } };

export type AdminExportExpensesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
}>;


export type AdminExportExpensesMutation = { __typename?: 'Mutation', exportExpenses: string };

export type CreateActivitiesMutationVariables = Exact<{
  data: Array<ActivityCreateManyInput> | ActivityCreateManyInput;
}>;


export type CreateActivitiesMutation = { __typename?: 'Mutation', createActivities: boolean };

export type AdminBulkUpdateExpensesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: ExpenseUpdateInput;
}>;


export type AdminBulkUpdateExpensesMutation = { __typename?: 'Mutation', updateExpenses: boolean };

export type AdminIncomeDataAttachmentFragment = { __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null };

export type AdminIncomeDataDetailFragment = { __typename?: 'IncomeData', id: string, entityId: string, year: number, status: IncomeDataStatus, hasPartner?: boolean | null, partnerName?: string | null, wasMarriedEntireYear?: boolean | null, didMarryThisYear?: boolean | null, invoiceFromNotary?: string | null, marriageDate?: string | null, namesOfCompanies?: string | null, didLiveSeparate?: boolean | null, didFileForDivorce?: boolean | null, didRegisterDifferentAddress?: boolean | null, didLiveTogether?: boolean | null, livingTogetherStartDate?: string | null, didBreakUpWithPartner?: boolean | null, hasNotarialCohabitationAgreement?: boolean | null, wasChildAknowledged?: boolean | null, didRegisterInPensionSchemes?: boolean | null, doesOwnPropertyTogether?: boolean | null, isChildOfTenantRegisteredAtAddress?: boolean | null, childrenNamesAndBirthDates?: string | null, didLiveAtSameAddress?: boolean | null, wasResidentOfNetherlands?: boolean | null, immigrationDateAndOriginCountry?: string | null, didWorkRequiredHoursOnSoleOrParntership?: boolean | null, didHaveSalary?: boolean | null, didHaveOtherIncome?: boolean | null, didReceiveOtherBenefits?: boolean | null, otherAllowances?: string | null, doesOwnSharesInCompany?: boolean | null, doesOwnPropertyOfResidence?: boolean | null, groundLeaseAmount?: string | null, didReceiveDonationForProperty?: string | null, hasPlanToUseProperty?: boolean | null, didBuyOrSellProperty?: boolean | null, rentalIncomeForProperty?: string | null, hasNewOrIncreasedMortage?: boolean | null, otherPropertyAddresses?: string | null, didPayOrReceiveSpousalSupport?: string | null, didPayAnnuityPremiums?: boolean | null, didDonateToCharities?: boolean | null, charityDonationSpecification?: string | null, additionalInformation?: string | null, attachments?: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null }> | null };

export type AdminGetEntityIncomeDataDetailQueryVariables = Exact<{
  where?: InputMaybe<IncomeDataWhereInput>;
}>;


export type AdminGetEntityIncomeDataDetailQuery = { __typename?: 'Query', incomeData?: { __typename?: 'IncomeData', id: string, entityId: string, year: number, status: IncomeDataStatus, hasPartner?: boolean | null, partnerName?: string | null, wasMarriedEntireYear?: boolean | null, didMarryThisYear?: boolean | null, invoiceFromNotary?: string | null, marriageDate?: string | null, namesOfCompanies?: string | null, didLiveSeparate?: boolean | null, didFileForDivorce?: boolean | null, didRegisterDifferentAddress?: boolean | null, didLiveTogether?: boolean | null, livingTogetherStartDate?: string | null, didBreakUpWithPartner?: boolean | null, hasNotarialCohabitationAgreement?: boolean | null, wasChildAknowledged?: boolean | null, didRegisterInPensionSchemes?: boolean | null, doesOwnPropertyTogether?: boolean | null, isChildOfTenantRegisteredAtAddress?: boolean | null, childrenNamesAndBirthDates?: string | null, didLiveAtSameAddress?: boolean | null, wasResidentOfNetherlands?: boolean | null, immigrationDateAndOriginCountry?: string | null, didWorkRequiredHoursOnSoleOrParntership?: boolean | null, didHaveSalary?: boolean | null, didHaveOtherIncome?: boolean | null, didReceiveOtherBenefits?: boolean | null, otherAllowances?: string | null, doesOwnSharesInCompany?: boolean | null, doesOwnPropertyOfResidence?: boolean | null, groundLeaseAmount?: string | null, didReceiveDonationForProperty?: string | null, hasPlanToUseProperty?: boolean | null, didBuyOrSellProperty?: boolean | null, rentalIncomeForProperty?: string | null, hasNewOrIncreasedMortage?: boolean | null, otherPropertyAddresses?: string | null, didPayOrReceiveSpousalSupport?: string | null, didPayAnnuityPremiums?: boolean | null, didDonateToCharities?: boolean | null, charityDonationSpecification?: string | null, additionalInformation?: string | null, attachments?: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null }> | null } | null };

export type AdminUpdateIncomeDataMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: IncomeDataUpdateInput;
}>;


export type AdminUpdateIncomeDataMutation = { __typename?: 'Mutation', updateIncomeData: { __typename?: 'IncomeData', id: string, entityId: string, year: number, status: IncomeDataStatus, hasPartner?: boolean | null, partnerName?: string | null, wasMarriedEntireYear?: boolean | null, didMarryThisYear?: boolean | null, invoiceFromNotary?: string | null, marriageDate?: string | null, namesOfCompanies?: string | null, didLiveSeparate?: boolean | null, didFileForDivorce?: boolean | null, didRegisterDifferentAddress?: boolean | null, didLiveTogether?: boolean | null, livingTogetherStartDate?: string | null, didBreakUpWithPartner?: boolean | null, hasNotarialCohabitationAgreement?: boolean | null, wasChildAknowledged?: boolean | null, didRegisterInPensionSchemes?: boolean | null, doesOwnPropertyTogether?: boolean | null, isChildOfTenantRegisteredAtAddress?: boolean | null, childrenNamesAndBirthDates?: string | null, didLiveAtSameAddress?: boolean | null, wasResidentOfNetherlands?: boolean | null, immigrationDateAndOriginCountry?: string | null, didWorkRequiredHoursOnSoleOrParntership?: boolean | null, didHaveSalary?: boolean | null, didHaveOtherIncome?: boolean | null, didReceiveOtherBenefits?: boolean | null, otherAllowances?: string | null, doesOwnSharesInCompany?: boolean | null, doesOwnPropertyOfResidence?: boolean | null, groundLeaseAmount?: string | null, didReceiveDonationForProperty?: string | null, hasPlanToUseProperty?: boolean | null, didBuyOrSellProperty?: boolean | null, rentalIncomeForProperty?: string | null, hasNewOrIncreasedMortage?: boolean | null, otherPropertyAddresses?: string | null, didPayOrReceiveSpousalSupport?: string | null, didPayAnnuityPremiums?: boolean | null, didDonateToCharities?: boolean | null, charityDonationSpecification?: string | null, additionalInformation?: string | null, attachments?: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null }> | null } };

export type AdminIncomeDataItemFragment = { __typename?: 'IncomeData', id: string, year: number, status: IncomeDataStatus, createdAt: string };

export type AdminGetEntityIncomeDataQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<IncomeDataWhereInput>;
  orderBy?: InputMaybe<Array<IncomeDataOrderByWithRelationInput> | IncomeDataOrderByWithRelationInput>;
}>;


export type AdminGetEntityIncomeDataQuery = { __typename?: 'Query', allIncomeData: { __typename?: 'IncomeDataResponse', count: number, items: Array<{ __typename?: 'IncomeData', id: string, year: number, status: IncomeDataStatus, createdAt: string }> } };

export type AdminDownloadIncomeDataMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminDownloadIncomeDataMutation = { __typename?: 'Mutation', downloadIncomeData: boolean };

export type AdminCreateIncomeDataMutationVariables = Exact<{
  data: IncomeDataCreateInput;
}>;


export type AdminCreateIncomeDataMutation = { __typename?: 'Mutation', createIncomeData: { __typename?: 'IncomeData', id: string } };

export type UserEntityFragment = { __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } };

export type AdminEntityInvitationItemFragment = { __typename?: 'Invitation', id: string, email: string, createdAt: string, role?: UserEntityRole | null };

export type AdminEntityDetailFragment = { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, rsin?: string | null, kvk?: string | null, btw?: string | null, btwId?: string | null, type: EntityType, vatPeriod?: VatPeriod | null, archivedAt?: string | null, clientManagerId?: string | null, forwardingEmail?: string | null, users: Array<{ __typename?: 'User', id: string, fullName: string, avatar?: string | null }>, userEntities: Array<{ __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } }>, invitations: Array<{ __typename?: 'Invitation', id: string, email: string, createdAt: string, role?: UserEntityRole | null }> };

export type AdminGetEntityQueryVariables = Exact<{
  where?: InputMaybe<EntityWhereInput>;
}>;


export type AdminGetEntityQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, rsin?: string | null, kvk?: string | null, btw?: string | null, btwId?: string | null, type: EntityType, vatPeriod?: VatPeriod | null, archivedAt?: string | null, clientManagerId?: string | null, forwardingEmail?: string | null, users: Array<{ __typename?: 'User', id: string, fullName: string, avatar?: string | null }>, userEntities: Array<{ __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } }>, invitations: Array<{ __typename?: 'Invitation', id: string, email: string, createdAt: string, role?: UserEntityRole | null }> } | null };

export type AdminArchiveEntityMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminArchiveEntityMutation = { __typename?: 'Mutation', archiveEntity: { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, rsin?: string | null, kvk?: string | null, btw?: string | null, btwId?: string | null, type: EntityType, vatPeriod?: VatPeriod | null, archivedAt?: string | null, clientManagerId?: string | null, forwardingEmail?: string | null, users: Array<{ __typename?: 'User', id: string, fullName: string, avatar?: string | null }>, userEntities: Array<{ __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } }>, invitations: Array<{ __typename?: 'Invitation', id: string, email: string, createdAt: string, role?: UserEntityRole | null }> } };

export type AdminUnArchiveEntityMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminUnArchiveEntityMutation = { __typename?: 'Mutation', unArchiveEntity: { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, rsin?: string | null, kvk?: string | null, btw?: string | null, btwId?: string | null, type: EntityType, vatPeriod?: VatPeriod | null, archivedAt?: string | null, clientManagerId?: string | null, forwardingEmail?: string | null, users: Array<{ __typename?: 'User', id: string, fullName: string, avatar?: string | null }>, userEntities: Array<{ __typename?: 'UserEntity', id: string, role: UserEntityRole, user: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } }>, invitations: Array<{ __typename?: 'Invitation', id: string, email: string, createdAt: string, role?: UserEntityRole | null }> } };

export type AdminRemoveEntityUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type AdminRemoveEntityUserMutation = { __typename?: 'Mutation', removeUserFromEntity: boolean };

export type AdminCreateInvitationsMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  entityId: Scalars['String']['input'];
  userEntityRole: Scalars['String']['input'];
}>;


export type AdminCreateInvitationsMutation = { __typename?: 'Mutation', createInvitations: Array<{ __typename?: 'Invitation', id: string }> };

export type InviteMemberFragment = { __typename?: 'User', id: string, fullName: string, avatar?: string | null, email: string };

export type GetInviteMembersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;


export type GetInviteMembersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', items: Array<{ __typename?: 'User', id: string, fullName: string, avatar?: string | null, email: string }> } };

export type AdminArchiveInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminArchiveInvitationMutation = { __typename?: 'Mutation', archiveInvitation: boolean };

export type AdminInvoiceItemFragment = { __typename?: 'Invoice', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null };

export type AdminGetEntityInvoicesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvoiceWhereInput>;
  orderBy?: InputMaybe<Array<InvoiceOrderByWithRelationInput> | InvoiceOrderByWithRelationInput>;
}>;


export type AdminGetEntityInvoicesQuery = { __typename?: 'Query', invoices: { __typename?: 'InvoicesResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Invoice', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null }> } };

export type AdminExportInvoicesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
}>;


export type AdminExportInvoicesMutation = { __typename?: 'Mutation', exportInvoices: string };

export type AdminArchiveInvoiceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminArchiveInvoiceMutation = { __typename?: 'Mutation', archiveInvoice: { __typename?: 'Invoice', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUnarchiveInvoiceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminUnarchiveInvoiceMutation = { __typename?: 'Mutation', unarchiveInvoice: { __typename?: 'Invoice', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUpdateInvoiceIsProcessedMutationVariables = Exact<{
  id: Scalars['String']['input'];
  isProcessed: Scalars['Boolean']['input'];
}>;


export type AdminUpdateInvoiceIsProcessedMutation = { __typename?: 'Mutation', updateInvoiceIsProcessed: { __typename?: 'Invoice', id: string, date: string, file: string, description?: string | null, createdAt: string, archivedAt?: string | null, isProcessed: boolean, paymentMethod?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminBulkUpdateInvoicesMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: InvoiceUpdateInput;
}>;


export type AdminBulkUpdateInvoicesMutation = { __typename?: 'Mutation', updateInvoices: boolean };

export type AdminReportItemFragment = { __typename?: 'Report', id: string, createdAt: string, archivedAt?: string | null, quarter?: Quarter | null, year: number, file: string, type: ReportType, status: ReportStatus, creatorId: string, creator: { __typename?: 'User', id: string, fullName: string }, approver?: { __typename?: 'User', id: string, fullName: string } | null };

export type AdminCreateReportMutationVariables = Exact<{
  data: ReportCreateInput;
}>;


export type AdminCreateReportMutation = { __typename?: 'Mutation', createReport: { __typename?: 'Report', id: string, year: number, quarter?: Quarter | null, type: ReportType, status: ReportStatus, entityId: string, file: string } };

export type AdminGetEntityReportsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput> | ReportOrderByWithRelationInput>;
}>;


export type AdminGetEntityReportsQuery = { __typename?: 'Query', reports: { __typename?: 'ReportsResponse', count: number, allItemIds?: Array<string> | null, items: Array<{ __typename?: 'Report', id: string, createdAt: string, archivedAt?: string | null, quarter?: Quarter | null, year: number, file: string, type: ReportType, status: ReportStatus, creatorId: string, creator: { __typename?: 'User', id: string, fullName: string }, approver?: { __typename?: 'User', id: string, fullName: string } | null }> } };

export type AdminBulkUpdateReportsMutationVariables = Exact<{
  entityId: Scalars['String']['input'];
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  data: ReportUpdateInput;
}>;


export type AdminBulkUpdateReportsMutation = { __typename?: 'Mutation', updateReports: boolean };

export type AdminArchiveReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminArchiveReportMutation = { __typename?: 'Mutation', archiveReport: { __typename?: 'Report', id: string, createdAt: string, archivedAt?: string | null, quarter?: Quarter | null, year: number, file: string, type: ReportType, status: ReportStatus, creatorId: string, creator: { __typename?: 'User', id: string, fullName: string }, approver?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUnarchiveReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminUnarchiveReportMutation = { __typename?: 'Mutation', unarchiveReport: { __typename?: 'Report', id: string, createdAt: string, archivedAt?: string | null, quarter?: Quarter | null, year: number, file: string, type: ReportType, status: ReportStatus, creatorId: string, creator: { __typename?: 'User', id: string, fullName: string }, approver?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminUpdateReportMutationVariables = Exact<{
  data: ReportUpdateInput;
  id: Scalars['String']['input'];
}>;


export type AdminUpdateReportMutation = { __typename?: 'Mutation', updateReport: { __typename?: 'Report', id: string, createdAt: string, archivedAt?: string | null, quarter?: Quarter | null, year: number, file: string, type: ReportType, status: ReportStatus, creatorId: string, creator: { __typename?: 'User', id: string, fullName: string }, approver?: { __typename?: 'User', id: string, fullName: string } | null } };

export type AdminEntityItemFragment = { __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, type: EntityType, archivedAt?: string | null, forwardingEmail?: string | null, unprocessedDocuments: { __typename?: 'EntityUnprocessedDocumentsResponse', expenseCount: number, invoiceCount: number, bankStatementCount: number, total: number }, latestActivity?: { __typename?: 'Activity', id: string, type: ActivityType, createdAt: string } | null, clientManager?: { __typename?: 'User', id: string, fullName: string } | null };

export type AdminGetEntitiesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityOrderByWithRelationInput> | EntityOrderByWithRelationInput>;
  where?: InputMaybe<EntityWhereInput>;
}>;


export type AdminGetEntitiesQuery = { __typename?: 'Query', entities: { __typename?: 'EntitiesResponse', count: number, items: Array<{ __typename?: 'Entity', id: string, createdAt: string, updatedAt: string, name: string, type: EntityType, archivedAt?: string | null, forwardingEmail?: string | null, unprocessedDocuments: { __typename?: 'EntityUnprocessedDocumentsResponse', expenseCount: number, invoiceCount: number, bankStatementCount: number, total: number }, latestActivity?: { __typename?: 'Activity', id: string, type: ActivityType, createdAt: string } | null, clientManager?: { __typename?: 'User', id: string, fullName: string } | null }> } };

export type AdminUserEntityFragment = { __typename?: 'Entity', id: string, name: string, type: EntityType, archivedAt?: string | null };

export type AdminGetUserEntitiesQueryVariables = Exact<{
  where?: InputMaybe<EntityWhereInput>;
}>;


export type AdminGetUserEntitiesQuery = { __typename?: 'Query', entities: { __typename?: 'EntitiesResponse', items: Array<{ __typename?: 'Entity', id: string, name: string, type: EntityType, archivedAt?: string | null }> } };

export type AdminUserDetailFragment = { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, phone?: string | null, bio?: string | null, access: Array<Access>, avatar?: string | null, email: string, language: Language, createdAt: string };

export type AdminGetUserQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;


export type AdminGetUserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, phone?: string | null, bio?: string | null, access: Array<Access>, avatar?: string | null, email: string, language: Language, createdAt: string } | null };

export type AdminUpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UserUpdateInput;
}>;


export type AdminUpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, phone?: string | null, bio?: string | null, access: Array<Access>, avatar?: string | null, email: string, language: Language, createdAt: string } };

export type AdminDeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type AdminDeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type AdminUserNotifcationSettingsFragment = { __typename?: 'User', isUpcomingVatNotificationsEnabled: boolean, isIncomeDataNotificationsEnabled: boolean, isReportNotificationsEnabled: boolean, isUpdateToUserNotificationEnabled: boolean };

export type AdminGetUserNotificationSettingsQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
}>;


export type AdminGetUserNotificationSettingsQuery = { __typename?: 'Query', user?: { __typename?: 'User', isUpcomingVatNotificationsEnabled: boolean, isIncomeDataNotificationsEnabled: boolean, isReportNotificationsEnabled: boolean, isUpdateToUserNotificationEnabled: boolean } | null };

export type AdminUserTasksFragment = { __typename?: 'User', id: string, entities: Array<{ __typename?: 'Entity', id: string, name: string, type: EntityType, hasUpcomingVatDeadline: boolean, pendingReports: Array<{ __typename?: 'Report', id: string, year: number, quarter?: Quarter | null, type: ReportType }>, pendingIncomeData: Array<{ __typename?: 'IncomeData', id: string, year: number }> }> };

export type GetAdminUserTasksQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
  userId: Scalars['String']['input'];
}>;


export type GetAdminUserTasksQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, entities: Array<{ __typename?: 'Entity', id: string, name: string, type: EntityType, hasUpcomingVatDeadline: boolean, pendingReports: Array<{ __typename?: 'Report', id: string, year: number, quarter?: Quarter | null, type: ReportType }>, pendingIncomeData: Array<{ __typename?: 'IncomeData', id: string, year: number }> }> } | null };

export type UpdateOverviewFragment = { __typename?: 'Update', id: string, message: string, isRead?: boolean | null, createdAt: string, sender: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } };

export type GetUserUpdatesQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UpdateOrderByWithRelationInput> | UpdateOrderByWithRelationInput>;
  where?: InputMaybe<UserWhereInput>;
}>;


export type GetUserUpdatesQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, fullName: string, updates: { __typename?: 'UpdatesResponse', count: number, items: Array<{ __typename?: 'Update', id: string, message: string, isRead?: boolean | null, createdAt: string, sender: { __typename?: 'User', id: string, fullName: string, avatar?: string | null } }> } } | null };

export type AdminUserItemFragment = { __typename?: 'User', id: string, fullName: string, email: string, createdAt: string };

export type AdminGetUsersQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  where?: InputMaybe<UserWhereInput>;
}>;


export type AdminGetUsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersResponse', count: number, items: Array<{ __typename?: 'User', id: string, fullName: string, email: string, createdAt: string }> } };

export type RefreshTokenQueryVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshTokenQuery = { __typename?: 'Query', refreshToken?: { __typename?: 'RefreshTokenResponse', token: string, refreshToken: string } | null };

export type ReportOverviewFragment = { __typename?: 'Report', id: string, year: number, type: ReportType, quarter?: Quarter | null, file: string, status: ReportStatus, entityId: string };

export type GetReportQueryVariables = Exact<{
  where?: InputMaybe<ReportWhereInput>;
}>;


export type GetReportQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: string, year: number, type: ReportType, quarter?: Quarter | null, file: string, status: ReportStatus, entityId: string } | null };

export type AttachmentOverviewFragment = { __typename?: 'Attachment', id: string, name: string, year?: number | null, file: string, entityId: string };

export type GetAttachmentQueryVariables = Exact<{
  where?: InputMaybe<AttachmentWhereInput>;
}>;


export type GetAttachmentQuery = { __typename?: 'Query', attachment?: { __typename?: 'Attachment', id: string, name: string, year?: number | null, file: string, entityId: string } | null };

export type BankStatementOverviewFragment = { __typename?: 'BankStatement', id: string, name: string, year?: number | null, file: string, entityId: string };

export type GetBankStatementQueryVariables = Exact<{
  where?: InputMaybe<BankStatementWhereInput>;
}>;


export type GetBankStatementQuery = { __typename?: 'Query', bankStatement?: { __typename?: 'BankStatement', id: string, name: string, year?: number | null, file: string, entityId: string } | null };

export type IncomeDataAttachmentFragment = { __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null };

export type IncomeDataDetailFragment = { __typename?: 'IncomeData', id: string, year: number, entityId: string, status: IncomeDataStatus, hasPartner?: boolean | null, partnerName?: string | null, wasMarriedEntireYear?: boolean | null, didMarryThisYear?: boolean | null, invoiceFromNotary?: string | null, marriageDate?: string | null, namesOfCompanies?: string | null, didLiveSeparate?: boolean | null, didFileForDivorce?: boolean | null, didRegisterDifferentAddress?: boolean | null, didLiveTogether?: boolean | null, livingTogetherStartDate?: string | null, didBreakUpWithPartner?: boolean | null, hasNotarialCohabitationAgreement?: boolean | null, wasChildAknowledged?: boolean | null, didRegisterInPensionSchemes?: boolean | null, doesOwnPropertyTogether?: boolean | null, isChildOfTenantRegisteredAtAddress?: boolean | null, childrenNamesAndBirthDates?: string | null, didLiveAtSameAddress?: boolean | null, wasResidentOfNetherlands?: boolean | null, immigrationDateAndOriginCountry?: string | null, didWorkRequiredHoursOnSoleOrParntership?: boolean | null, didHaveSalary?: boolean | null, didHaveOtherIncome?: boolean | null, didReceiveOtherBenefits?: boolean | null, otherAllowances?: string | null, doesOwnSharesInCompany?: boolean | null, doesOwnPropertyOfResidence?: boolean | null, groundLeaseAmount?: string | null, didReceiveDonationForProperty?: string | null, hasPlanToUseProperty?: boolean | null, didBuyOrSellProperty?: boolean | null, rentalIncomeForProperty?: string | null, hasNewOrIncreasedMortage?: boolean | null, otherPropertyAddresses?: string | null, didPayOrReceiveSpousalSupport?: string | null, didPayAnnuityPremiums?: boolean | null, didDonateToCharities?: boolean | null, charityDonationSpecification?: string | null, additionalInformation?: string | null, attachments?: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null }> | null };

export type GetIncomeDataQueryVariables = Exact<{
  where?: InputMaybe<IncomeDataWhereInput>;
}>;


export type GetIncomeDataQuery = { __typename?: 'Query', incomeData?: { __typename?: 'IncomeData', id: string, year: number, entityId: string, status: IncomeDataStatus, hasPartner?: boolean | null, partnerName?: string | null, wasMarriedEntireYear?: boolean | null, didMarryThisYear?: boolean | null, invoiceFromNotary?: string | null, marriageDate?: string | null, namesOfCompanies?: string | null, didLiveSeparate?: boolean | null, didFileForDivorce?: boolean | null, didRegisterDifferentAddress?: boolean | null, didLiveTogether?: boolean | null, livingTogetherStartDate?: string | null, didBreakUpWithPartner?: boolean | null, hasNotarialCohabitationAgreement?: boolean | null, wasChildAknowledged?: boolean | null, didRegisterInPensionSchemes?: boolean | null, doesOwnPropertyTogether?: boolean | null, isChildOfTenantRegisteredAtAddress?: boolean | null, childrenNamesAndBirthDates?: string | null, didLiveAtSameAddress?: boolean | null, wasResidentOfNetherlands?: boolean | null, immigrationDateAndOriginCountry?: string | null, didWorkRequiredHoursOnSoleOrParntership?: boolean | null, didHaveSalary?: boolean | null, didHaveOtherIncome?: boolean | null, didReceiveOtherBenefits?: boolean | null, otherAllowances?: string | null, doesOwnSharesInCompany?: boolean | null, doesOwnPropertyOfResidence?: boolean | null, groundLeaseAmount?: string | null, didReceiveDonationForProperty?: string | null, hasPlanToUseProperty?: boolean | null, didBuyOrSellProperty?: boolean | null, rentalIncomeForProperty?: string | null, hasNewOrIncreasedMortage?: boolean | null, otherPropertyAddresses?: string | null, didPayOrReceiveSpousalSupport?: string | null, didPayAnnuityPremiums?: boolean | null, didDonateToCharities?: boolean | null, charityDonationSpecification?: string | null, additionalInformation?: string | null, attachments?: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null }> | null } | null };

export type UpdateIncomeDataMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: IncomeDataUpdateInput;
}>;


export type UpdateIncomeDataMutation = { __typename?: 'Mutation', updateIncomeData: { __typename?: 'IncomeData', id: string, year: number, entityId: string, status: IncomeDataStatus, hasPartner?: boolean | null, partnerName?: string | null, wasMarriedEntireYear?: boolean | null, didMarryThisYear?: boolean | null, invoiceFromNotary?: string | null, marriageDate?: string | null, namesOfCompanies?: string | null, didLiveSeparate?: boolean | null, didFileForDivorce?: boolean | null, didRegisterDifferentAddress?: boolean | null, didLiveTogether?: boolean | null, livingTogetherStartDate?: string | null, didBreakUpWithPartner?: boolean | null, hasNotarialCohabitationAgreement?: boolean | null, wasChildAknowledged?: boolean | null, didRegisterInPensionSchemes?: boolean | null, doesOwnPropertyTogether?: boolean | null, isChildOfTenantRegisteredAtAddress?: boolean | null, childrenNamesAndBirthDates?: string | null, didLiveAtSameAddress?: boolean | null, wasResidentOfNetherlands?: boolean | null, immigrationDateAndOriginCountry?: string | null, didWorkRequiredHoursOnSoleOrParntership?: boolean | null, didHaveSalary?: boolean | null, didHaveOtherIncome?: boolean | null, didReceiveOtherBenefits?: boolean | null, otherAllowances?: string | null, doesOwnSharesInCompany?: boolean | null, doesOwnPropertyOfResidence?: boolean | null, groundLeaseAmount?: string | null, didReceiveDonationForProperty?: string | null, hasPlanToUseProperty?: boolean | null, didBuyOrSellProperty?: boolean | null, rentalIncomeForProperty?: string | null, hasNewOrIncreasedMortage?: boolean | null, otherPropertyAddresses?: string | null, didPayOrReceiveSpousalSupport?: string | null, didPayAnnuityPremiums?: boolean | null, didDonateToCharities?: boolean | null, charityDonationSpecification?: string | null, additionalInformation?: string | null, attachments?: Array<{ __typename?: 'Attachment', id: string, name: string, file: string, incomeDataQuestion?: string | null }> | null } };

export type CreateManyAttachmentsMutationVariables = Exact<{
  data: Array<AttachmentCreateManyInput> | AttachmentCreateManyInput;
}>;


export type CreateManyAttachmentsMutation = { __typename?: 'Mutation', createManyAttachments: boolean };

export type ArchiveAttachmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ArchiveAttachmentMutation = { __typename?: 'Mutation', archiveAttachment: { __typename?: 'Attachment', id: string, name: string, date?: string | null, year?: number | null, file: string, isProcessed: boolean, createdAt: string, archivedAt?: string | null, incomeDataId?: string | null, incomeDataQuestion?: string | null, uploader?: { __typename?: 'User', id: string, fullName: string } | null } };

export type IsCurrentUserManagerQueryVariables = Exact<{
  where?: InputMaybe<EntityWhereInput>;
}>;


export type IsCurrentUserManagerQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, isCurrentUserManager: boolean, type: EntityType } | null };

export type ClientManagerItemFragment = { __typename?: 'User', id: string, avatar?: string | null, fullName: string };

export type EntityOverviewFragment = { __typename?: 'Entity', id: string, name: string, type: EntityType, vatPeriod?: VatPeriod | null, isCurrentUserManager: boolean, clientManager?: { __typename?: 'User', id: string, avatar?: string | null, fullName: string } | null };

export type GetEntityQueryVariables = Exact<{
  where?: InputMaybe<EntityWhereInput>;
}>;


export type GetEntityQuery = { __typename?: 'Query', entity?: { __typename?: 'Entity', id: string, name: string, type: EntityType, vatPeriod?: VatPeriod | null, isCurrentUserManager: boolean, clientManager?: { __typename?: 'User', id: string, avatar?: string | null, fullName: string } | null } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type GetInvitationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetInvitationQuery = { __typename?: 'Query', invitation?: { __typename?: 'Invitation', id: string, email: string, entityName: string, createdAt: string, updatedAt: string } | null };

export type RegisterMutationVariables = Exact<{
  data: RegisterInput;
  code: Scalars['String']['input'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'AuthResponse', token: string, refreshToken: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, avatar?: string | null, email: string, access: Array<Access>, role: Role, language: Language, companyEntities: Array<{ __typename?: 'Entity', id: string }> } } };

export type VerifyPhoneMutationVariables = Exact<{
  phone: Scalars['String']['input'];
}>;


export type VerifyPhoneMutation = { __typename?: 'Mutation', verifyPhone: boolean };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
  code?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string, refreshToken: string, user: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, avatar?: string | null, email: string, access: Array<Access>, role: Role, language: Language, companyEntities: Array<{ __typename?: 'Entity', id: string }> } } };

export type SendVerificationMutationVariables = Exact<{
  data: LoginInput;
}>;


export type SendVerificationMutation = { __typename?: 'Mutation', sendVerification: { __typename?: 'VerificationResponse', skip2fa: boolean } };

export type UpdateMeMutationVariables = Exact<{
  data: UserUpdateInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', id: string, firstName: string, lastName: string, fullName: string, avatar?: string | null, email: string, access: Array<Access>, role: Role, language: Language, companyEntities: Array<{ __typename?: 'Entity', id: string }> } };

export type UserNotifcationSettingsFragment = { __typename?: 'User', isUpcomingVatNotificationsEnabled: boolean, isIncomeDataNotificationsEnabled: boolean, isReportNotificationsEnabled: boolean, isUpdateToUserNotificationEnabled: boolean };

export type GetUserNotificationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserNotificationSettingsQuery = { __typename?: 'Query', me?: { __typename?: 'User', isUpcomingVatNotificationsEnabled: boolean, isIncomeDataNotificationsEnabled: boolean, isReportNotificationsEnabled: boolean, isUpdateToUserNotificationEnabled: boolean } | null };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export const ClientManagerOptionItemFragmentDoc = gql`
    fragment ClientManagerOptionItem on User {
  id
  fullName
}
    `;
export const AdminExpenseForwardingEmailFragmentDoc = gql`
    fragment AdminExpenseForwardingEmail on Entity {
  id
  name
  forwardingEmail
}
    `;
export const AttachmentItemFragmentDoc = gql`
    fragment AttachmentItem on Attachment {
  id
  name
  file
  year
  date
  createdAt
}
    `;
export const BankStatementItemFragmentDoc = gql`
    fragment BankStatementItem on BankStatement {
  id
  name
  file
  date
  year
  createdAt
}
    `;
export const ExpenseItemFragmentDoc = gql`
    fragment ExpenseItem on Expense {
  id
  description
  date
  createdAt
  file
  paymentMethod
}
    `;
export const IncomeDataItemFragmentDoc = gql`
    fragment IncomeDataItem on IncomeData {
  id
  year
  status
}
    `;
export const InvoiceItemFragmentDoc = gql`
    fragment InvoiceItem on Invoice {
  id
  description
  date
  createdAt
  file
  paymentMethod
}
    `;
export const LatestReportItemFragmentDoc = gql`
    fragment LatestReportItem on Report {
  id
  createdAt
  quarter
  year
  type
  status
  file
  entity {
    id
    name
  }
}
    `;
export const ReportItemFragmentDoc = gql`
    fragment ReportItem on Report {
  id
  quarter
  year
  type
  status
  file
  createdAt
}
    `;
export const UpdateItemFragmentDoc = gql`
    fragment UpdateItem on Update {
  id
  message
  isRead
  createdAt
  sender {
    id
    firstName
    avatar
  }
}
    `;
export const EntityItemFragmentDoc = gql`
    fragment EntityItem on Entity {
  id
  name
}
    `;
export const UserTaskEntityFragmentDoc = gql`
    fragment UserTaskEntity on Entity {
  id
  name
  type
  pendingReports(userId: $userId) {
    id
    year
    quarter
    type
    file
  }
  pendingIncomeData {
    id
    year
  }
  hasUpcomingVatDeadline
}
    `;
export const UserTasksFragmentDoc = gql`
    fragment UserTasks on User {
  id
  entities {
    ...UserTaskEntity
  }
}
    ${UserTaskEntityFragmentDoc}`;
export const MeFragmentDoc = gql`
    fragment Me on User {
  id
  firstName
  lastName
  fullName
  avatar
  email
  access
  role
  language
  companyEntities {
    id
  }
}
    `;
export const AdminAdminDetailFragmentDoc = gql`
    fragment AdminAdminDetail on User {
  id
  firstName
  lastName
  fullName
  phone
  avatar
  email
  createdAt
}
    `;
export const AdminAdminItemFragmentDoc = gql`
    fragment AdminAdminItem on User {
  id
  fullName
  email
  createdAt
  access
}
    `;
export const AdminAttachmentItemFragmentDoc = gql`
    fragment AdminAttachmentItem on Attachment {
  id
  name
  date
  year
  file
  isProcessed
  createdAt
  archivedAt
  incomeDataId
  incomeDataQuestion
  uploader {
    id
    fullName
  }
}
    `;
export const AdminBankStatementItemFragmentDoc = gql`
    fragment AdminBankStatementItem on BankStatement {
  id
  name
  date
  year
  file
  isProcessed
  createdAt
  archivedAt
  uploader {
    id
    fullName
  }
}
    `;
export const AdminExpenseItemFragmentDoc = gql`
    fragment AdminExpenseItem on Expense {
  id
  date
  file
  description
  createdAt
  archivedAt
  isProcessed
  paymentMethod
  uploader {
    id
    fullName
  }
}
    `;
export const AdminIncomeDataAttachmentFragmentDoc = gql`
    fragment AdminIncomeDataAttachment on Attachment {
  id
  name
  file
  incomeDataQuestion
}
    `;
export const AdminIncomeDataDetailFragmentDoc = gql`
    fragment AdminIncomeDataDetail on IncomeData {
  id
  entityId
  year
  status
  hasPartner
  partnerName
  wasMarriedEntireYear
  didMarryThisYear
  invoiceFromNotary
  marriageDate
  namesOfCompanies
  didLiveSeparate
  didFileForDivorce
  didRegisterDifferentAddress
  didLiveTogether
  livingTogetherStartDate
  didBreakUpWithPartner
  hasNotarialCohabitationAgreement
  wasChildAknowledged
  didRegisterInPensionSchemes
  doesOwnPropertyTogether
  isChildOfTenantRegisteredAtAddress
  childrenNamesAndBirthDates
  didLiveAtSameAddress
  wasResidentOfNetherlands
  immigrationDateAndOriginCountry
  didWorkRequiredHoursOnSoleOrParntership
  didHaveSalary
  didHaveOtherIncome
  didReceiveOtherBenefits
  otherAllowances
  doesOwnSharesInCompany
  doesOwnPropertyOfResidence
  groundLeaseAmount
  didReceiveDonationForProperty
  hasPlanToUseProperty
  didBuyOrSellProperty
  rentalIncomeForProperty
  hasNewOrIncreasedMortage
  otherPropertyAddresses
  didPayOrReceiveSpousalSupport
  didPayAnnuityPremiums
  didDonateToCharities
  charityDonationSpecification
  additionalInformation
  attachments {
    ...AdminIncomeDataAttachment
  }
}
    ${AdminIncomeDataAttachmentFragmentDoc}`;
export const AdminIncomeDataItemFragmentDoc = gql`
    fragment AdminIncomeDataItem on IncomeData {
  id
  year
  status
  createdAt
}
    `;
export const UserEntityFragmentDoc = gql`
    fragment UserEntity on UserEntity {
  id
  role
  user {
    id
    fullName
    avatar
  }
}
    `;
export const AdminEntityInvitationItemFragmentDoc = gql`
    fragment AdminEntityInvitationItem on Invitation {
  id
  email
  createdAt
  role
}
    `;
export const AdminEntityDetailFragmentDoc = gql`
    fragment AdminEntityDetail on Entity {
  id
  createdAt
  updatedAt
  name
  rsin
  kvk
  btw
  btwId
  type
  vatPeriod
  archivedAt
  clientManagerId
  forwardingEmail
  users {
    id
    fullName
    avatar
  }
  userEntities {
    ...UserEntity
  }
  invitations {
    ...AdminEntityInvitationItem
  }
}
    ${UserEntityFragmentDoc}
${AdminEntityInvitationItemFragmentDoc}`;
export const InviteMemberFragmentDoc = gql`
    fragment InviteMember on User {
  id
  fullName
  avatar
  email
}
    `;
export const AdminInvoiceItemFragmentDoc = gql`
    fragment AdminInvoiceItem on Invoice {
  id
  date
  file
  description
  createdAt
  archivedAt
  isProcessed
  paymentMethod
  uploader {
    id
    fullName
  }
}
    `;
export const AdminReportItemFragmentDoc = gql`
    fragment AdminReportItem on Report {
  id
  createdAt
  archivedAt
  quarter
  year
  file
  type
  status
  creatorId
  creator {
    id
    fullName
  }
  approver {
    id
    fullName
  }
}
    `;
export const AdminEntityItemFragmentDoc = gql`
    fragment AdminEntityItem on Entity {
  id
  createdAt
  updatedAt
  name
  type
  archivedAt
  unprocessedDocuments {
    expenseCount
    invoiceCount
    bankStatementCount
    total
  }
  forwardingEmail
  latestActivity {
    id
    type
    createdAt
  }
  clientManager {
    id
    fullName
  }
}
    `;
export const AdminUserEntityFragmentDoc = gql`
    fragment AdminUserEntity on Entity {
  id
  name
  type
  archivedAt
}
    `;
export const AdminUserDetailFragmentDoc = gql`
    fragment AdminUserDetail on User {
  id
  firstName
  lastName
  fullName
  phone
  bio
  access
  avatar
  email
  language
  createdAt
}
    `;
export const AdminUserNotifcationSettingsFragmentDoc = gql`
    fragment AdminUserNotifcationSettings on User {
  isUpcomingVatNotificationsEnabled
  isIncomeDataNotificationsEnabled
  isReportNotificationsEnabled
  isUpdateToUserNotificationEnabled
}
    `;
export const AdminUserTasksFragmentDoc = gql`
    fragment AdminUserTasks on User {
  id
  entities {
    id
    name
    type
    pendingReports(userId: $userId) {
      id
      year
      quarter
      type
    }
    pendingIncomeData {
      id
      year
    }
    hasUpcomingVatDeadline
  }
}
    `;
export const UpdateOverviewFragmentDoc = gql`
    fragment UpdateOverview on Update {
  id
  message
  isRead
  createdAt
  sender {
    id
    fullName
    avatar
  }
}
    `;
export const AdminUserItemFragmentDoc = gql`
    fragment AdminUserItem on User {
  id
  fullName
  email
  createdAt
}
    `;
export const ReportOverviewFragmentDoc = gql`
    fragment ReportOverview on Report {
  id
  year
  type
  quarter
  file
  status
  entityId
}
    `;
export const AttachmentOverviewFragmentDoc = gql`
    fragment AttachmentOverview on Attachment {
  id
  name
  year
  file
  entityId
}
    `;
export const BankStatementOverviewFragmentDoc = gql`
    fragment BankStatementOverview on BankStatement {
  id
  name
  year
  file
  entityId
}
    `;
export const IncomeDataAttachmentFragmentDoc = gql`
    fragment IncomeDataAttachment on Attachment {
  id
  name
  file
  incomeDataQuestion
}
    `;
export const IncomeDataDetailFragmentDoc = gql`
    fragment IncomeDataDetail on IncomeData {
  id
  year
  entityId
  status
  hasPartner
  partnerName
  wasMarriedEntireYear
  didMarryThisYear
  invoiceFromNotary
  marriageDate
  namesOfCompanies
  didLiveSeparate
  didFileForDivorce
  didRegisterDifferentAddress
  didLiveTogether
  livingTogetherStartDate
  didBreakUpWithPartner
  hasNotarialCohabitationAgreement
  wasChildAknowledged
  didRegisterInPensionSchemes
  doesOwnPropertyTogether
  isChildOfTenantRegisteredAtAddress
  childrenNamesAndBirthDates
  didLiveAtSameAddress
  wasResidentOfNetherlands
  immigrationDateAndOriginCountry
  didWorkRequiredHoursOnSoleOrParntership
  didHaveSalary
  didHaveOtherIncome
  didReceiveOtherBenefits
  otherAllowances
  doesOwnSharesInCompany
  doesOwnPropertyOfResidence
  groundLeaseAmount
  didReceiveDonationForProperty
  hasPlanToUseProperty
  didBuyOrSellProperty
  rentalIncomeForProperty
  hasNewOrIncreasedMortage
  otherPropertyAddresses
  didPayOrReceiveSpousalSupport
  didPayAnnuityPremiums
  didDonateToCharities
  charityDonationSpecification
  additionalInformation
  attachments {
    ...IncomeDataAttachment
  }
}
    ${IncomeDataAttachmentFragmentDoc}`;
export const ClientManagerItemFragmentDoc = gql`
    fragment ClientManagerItem on User {
  id
  avatar
  fullName
}
    `;
export const EntityOverviewFragmentDoc = gql`
    fragment EntityOverview on Entity {
  id
  name
  type
  vatPeriod
  clientManager {
    ...ClientManagerItem
  }
  isCurrentUserManager
}
    ${ClientManagerItemFragmentDoc}`;
export const UserNotifcationSettingsFragmentDoc = gql`
    fragment UserNotifcationSettings on User {
  isUpcomingVatNotificationsEnabled
  isIncomeDataNotificationsEnabled
  isReportNotificationsEnabled
  isUpdateToUserNotificationEnabled
}
    `;
export const AdminCreateAdminDocument = gql`
    mutation AdminCreateAdmin($data: CreateAdminInput!) {
  createAdmin(data: $data) {
    id
  }
}
    `;
export function useAdminCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateAdminMutation, AdminCreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateAdminMutation, AdminCreateAdminMutationVariables>(AdminCreateAdminDocument, options);
      }
export type AdminCreateAdminMutationHookResult = ReturnType<typeof useAdminCreateAdminMutation>;
export type AdminCreateAdminMutationResult = Apollo.MutationResult<AdminCreateAdminMutation>;
export type AdminCreateAdminMutationOptions = Apollo.BaseMutationOptions<AdminCreateAdminMutation, AdminCreateAdminMutationVariables>;
export const AdminCreateAttachmentDocument = gql`
    mutation AdminCreateAttachment($data: AttachmentCreateInput!) {
  createAttachment(data: $data) {
    id
    name
    date
    file
    entityId
  }
}
    `;
export function useAdminCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateAttachmentMutation, AdminCreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateAttachmentMutation, AdminCreateAttachmentMutationVariables>(AdminCreateAttachmentDocument, options);
      }
export type AdminCreateAttachmentMutationHookResult = ReturnType<typeof useAdminCreateAttachmentMutation>;
export type AdminCreateAttachmentMutationResult = Apollo.MutationResult<AdminCreateAttachmentMutation>;
export type AdminCreateAttachmentMutationOptions = Apollo.BaseMutationOptions<AdminCreateAttachmentMutation, AdminCreateAttachmentMutationVariables>;
export const AdminCreateBankStatementDocument = gql`
    mutation AdminCreateBankStatement($data: BankStatementCreateInput!) {
  createBankStatement(data: $data) {
    id
    name
    date
    file
    entityId
  }
}
    `;
export function useAdminCreateBankStatementMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateBankStatementMutation, AdminCreateBankStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateBankStatementMutation, AdminCreateBankStatementMutationVariables>(AdminCreateBankStatementDocument, options);
      }
export type AdminCreateBankStatementMutationHookResult = ReturnType<typeof useAdminCreateBankStatementMutation>;
export type AdminCreateBankStatementMutationResult = Apollo.MutationResult<AdminCreateBankStatementMutation>;
export type AdminCreateBankStatementMutationOptions = Apollo.BaseMutationOptions<AdminCreateBankStatementMutation, AdminCreateBankStatementMutationVariables>;
export const CreateManyBankStatementsDocument = gql`
    mutation CreateManyBankStatements($data: [BankStatementCreateManyInput!]!) {
  createManyBankStatements(data: $data)
}
    `;
export function useCreateManyBankStatementsMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyBankStatementsMutation, CreateManyBankStatementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyBankStatementsMutation, CreateManyBankStatementsMutationVariables>(CreateManyBankStatementsDocument, options);
      }
export type CreateManyBankStatementsMutationHookResult = ReturnType<typeof useCreateManyBankStatementsMutation>;
export type CreateManyBankStatementsMutationResult = Apollo.MutationResult<CreateManyBankStatementsMutation>;
export type CreateManyBankStatementsMutationOptions = Apollo.BaseMutationOptions<CreateManyBankStatementsMutation, CreateManyBankStatementsMutationVariables>;
export const AdminGetClientManagersDocument = gql`
    query AdminGetClientManagers($orderBy: [UserOrderByWithRelationInput!], $where: UserWhereInput) {
  users(orderBy: $orderBy, where: $where) {
    items {
      ...ClientManagerOptionItem
    }
    count
  }
}
    ${ClientManagerOptionItemFragmentDoc}`;
export function useAdminGetClientManagersQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>(AdminGetClientManagersDocument, options);
      }
export function useAdminGetClientManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>(AdminGetClientManagersDocument, options);
        }
export function useAdminGetClientManagersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>(AdminGetClientManagersDocument, options);
        }
export type AdminGetClientManagersQueryHookResult = ReturnType<typeof useAdminGetClientManagersQuery>;
export type AdminGetClientManagersLazyQueryHookResult = ReturnType<typeof useAdminGetClientManagersLazyQuery>;
export type AdminGetClientManagersSuspenseQueryHookResult = ReturnType<typeof useAdminGetClientManagersSuspenseQuery>;
export type AdminGetClientManagersQueryResult = Apollo.QueryResult<AdminGetClientManagersQuery, AdminGetClientManagersQueryVariables>;
export const AdminCreateEntityDocument = gql`
    mutation AdminCreateEntity($data: EntityCreateInput!) {
  createEntity(data: $data) {
    ...AdminEntityItem
  }
}
    ${AdminEntityItemFragmentDoc}`;
export function useAdminCreateEntityMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateEntityMutation, AdminCreateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateEntityMutation, AdminCreateEntityMutationVariables>(AdminCreateEntityDocument, options);
      }
export type AdminCreateEntityMutationHookResult = ReturnType<typeof useAdminCreateEntityMutation>;
export type AdminCreateEntityMutationResult = Apollo.MutationResult<AdminCreateEntityMutation>;
export type AdminCreateEntityMutationOptions = Apollo.BaseMutationOptions<AdminCreateEntityMutation, AdminCreateEntityMutationVariables>;
export const AdminCreateExpenseDocument = gql`
    mutation AdminCreateExpense($data: ExpenseCreateInput!) {
  createExpense(data: $data) {
    id
    description
    file
    entityId
    date
  }
}
    `;
export function useAdminCreateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateExpenseMutation, AdminCreateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateExpenseMutation, AdminCreateExpenseMutationVariables>(AdminCreateExpenseDocument, options);
      }
export type AdminCreateExpenseMutationHookResult = ReturnType<typeof useAdminCreateExpenseMutation>;
export type AdminCreateExpenseMutationResult = Apollo.MutationResult<AdminCreateExpenseMutation>;
export type AdminCreateExpenseMutationOptions = Apollo.BaseMutationOptions<AdminCreateExpenseMutation, AdminCreateExpenseMutationVariables>;
export const AdminCreateManyExpenseDocument = gql`
    mutation AdminCreateManyExpense($data: [ExpenseCreateManyInput!]!) {
  createManyExpenses(data: $data)
}
    `;
export function useAdminCreateManyExpenseMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateManyExpenseMutation, AdminCreateManyExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateManyExpenseMutation, AdminCreateManyExpenseMutationVariables>(AdminCreateManyExpenseDocument, options);
      }
export type AdminCreateManyExpenseMutationHookResult = ReturnType<typeof useAdminCreateManyExpenseMutation>;
export type AdminCreateManyExpenseMutationResult = Apollo.MutationResult<AdminCreateManyExpenseMutation>;
export type AdminCreateManyExpenseMutationOptions = Apollo.BaseMutationOptions<AdminCreateManyExpenseMutation, AdminCreateManyExpenseMutationVariables>;
export const AdminCreateInvoiceDocument = gql`
    mutation AdminCreateInvoice($data: InvoiceCreateInput!) {
  createInvoice(data: $data) {
    id
    description
    file
    entityId
    date
  }
}
    `;
export function useAdminCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateInvoiceMutation, AdminCreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateInvoiceMutation, AdminCreateInvoiceMutationVariables>(AdminCreateInvoiceDocument, options);
      }
export type AdminCreateInvoiceMutationHookResult = ReturnType<typeof useAdminCreateInvoiceMutation>;
export type AdminCreateInvoiceMutationResult = Apollo.MutationResult<AdminCreateInvoiceMutation>;
export type AdminCreateInvoiceMutationOptions = Apollo.BaseMutationOptions<AdminCreateInvoiceMutation, AdminCreateInvoiceMutationVariables>;
export const AdminCreateManyInvoiceDocument = gql`
    mutation AdminCreateManyInvoice($data: [InvoiceCreateManyInput!]!) {
  createManyInvoices(data: $data)
}
    `;
export function useAdminCreateManyInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateManyInvoiceMutation, AdminCreateManyInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateManyInvoiceMutation, AdminCreateManyInvoiceMutationVariables>(AdminCreateManyInvoiceDocument, options);
      }
export type AdminCreateManyInvoiceMutationHookResult = ReturnType<typeof useAdminCreateManyInvoiceMutation>;
export type AdminCreateManyInvoiceMutationResult = Apollo.MutationResult<AdminCreateManyInvoiceMutation>;
export type AdminCreateManyInvoiceMutationOptions = Apollo.BaseMutationOptions<AdminCreateManyInvoiceMutation, AdminCreateManyInvoiceMutationVariables>;
export const AdminDownloadAttachmentsDocument = gql`
    mutation AdminDownloadAttachments($period: String!, $selectedAttachmentIds: [String!]!, $where: AttachmentWhereInput, $orderBy: [AttachmentOrderByWithRelationInput!]) {
  downloadAttachments(
    period: $period
    selectedAttachmentIds: $selectedAttachmentIds
    where: $where
    orderBy: $orderBy
  )
}
    `;
export function useAdminDownloadAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AdminDownloadAttachmentsMutation, AdminDownloadAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDownloadAttachmentsMutation, AdminDownloadAttachmentsMutationVariables>(AdminDownloadAttachmentsDocument, options);
      }
export type AdminDownloadAttachmentsMutationHookResult = ReturnType<typeof useAdminDownloadAttachmentsMutation>;
export type AdminDownloadAttachmentsMutationResult = Apollo.MutationResult<AdminDownloadAttachmentsMutation>;
export type AdminDownloadAttachmentsMutationOptions = Apollo.BaseMutationOptions<AdminDownloadAttachmentsMutation, AdminDownloadAttachmentsMutationVariables>;
export const AdminDownloadBankStatementsDocument = gql`
    mutation AdminDownloadBankStatements($period: String!, $selectedBankStatementIds: [String!]!, $where: BankStatementWhereInput, $orderBy: [BankStatementOrderByWithRelationInput!]) {
  downloadBankStatements(
    period: $period
    selectedBankStatementIds: $selectedBankStatementIds
    where: $where
    orderBy: $orderBy
  )
}
    `;
export function useAdminDownloadBankStatementsMutation(baseOptions?: Apollo.MutationHookOptions<AdminDownloadBankStatementsMutation, AdminDownloadBankStatementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDownloadBankStatementsMutation, AdminDownloadBankStatementsMutationVariables>(AdminDownloadBankStatementsDocument, options);
      }
export type AdminDownloadBankStatementsMutationHookResult = ReturnType<typeof useAdminDownloadBankStatementsMutation>;
export type AdminDownloadBankStatementsMutationResult = Apollo.MutationResult<AdminDownloadBankStatementsMutation>;
export type AdminDownloadBankStatementsMutationOptions = Apollo.BaseMutationOptions<AdminDownloadBankStatementsMutation, AdminDownloadBankStatementsMutationVariables>;
export const AdminDownloadExpensesDocument = gql`
    mutation AdminDownloadExpenses($period: String!, $markAsProcessed: Boolean!, $selectedExpenseIds: [String!]!, $where: ExpenseWhereInput, $orderBy: [ExpenseOrderByWithRelationInput!]) {
  downloadExpenses(
    period: $period
    markAsProcessed: $markAsProcessed
    selectedExpenseIds: $selectedExpenseIds
    where: $where
    orderBy: $orderBy
  )
}
    `;
export function useAdminDownloadExpensesMutation(baseOptions?: Apollo.MutationHookOptions<AdminDownloadExpensesMutation, AdminDownloadExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDownloadExpensesMutation, AdminDownloadExpensesMutationVariables>(AdminDownloadExpensesDocument, options);
      }
export type AdminDownloadExpensesMutationHookResult = ReturnType<typeof useAdminDownloadExpensesMutation>;
export type AdminDownloadExpensesMutationResult = Apollo.MutationResult<AdminDownloadExpensesMutation>;
export type AdminDownloadExpensesMutationOptions = Apollo.BaseMutationOptions<AdminDownloadExpensesMutation, AdminDownloadExpensesMutationVariables>;
export const AdminDownloadInvoicesDocument = gql`
    mutation AdminDownloadInvoices($period: String!, $markAsProcessed: Boolean!, $selectedInvoiceIds: [String!]!, $where: InvoiceWhereInput, $orderBy: [InvoiceOrderByWithRelationInput!]) {
  downloadInvoices(
    period: $period
    markAsProcessed: $markAsProcessed
    selectedInvoiceIds: $selectedInvoiceIds
    where: $where
    orderBy: $orderBy
  )
}
    `;
export function useAdminDownloadInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<AdminDownloadInvoicesMutation, AdminDownloadInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDownloadInvoicesMutation, AdminDownloadInvoicesMutationVariables>(AdminDownloadInvoicesDocument, options);
      }
export type AdminDownloadInvoicesMutationHookResult = ReturnType<typeof useAdminDownloadInvoicesMutation>;
export type AdminDownloadInvoicesMutationResult = Apollo.MutationResult<AdminDownloadInvoicesMutation>;
export type AdminDownloadInvoicesMutationOptions = Apollo.BaseMutationOptions<AdminDownloadInvoicesMutation, AdminDownloadInvoicesMutationVariables>;
export const AdminDownloadReportsDocument = gql`
    mutation AdminDownloadReports($period: String!, $selectedReportIds: [String!]!, $where: ReportWhereInput, $orderBy: [ReportOrderByWithRelationInput!]) {
  downloadReports(
    period: $period
    selectedReportIds: $selectedReportIds
    where: $where
    orderBy: $orderBy
  )
}
    `;
export function useAdminDownloadReportsMutation(baseOptions?: Apollo.MutationHookOptions<AdminDownloadReportsMutation, AdminDownloadReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDownloadReportsMutation, AdminDownloadReportsMutationVariables>(AdminDownloadReportsDocument, options);
      }
export type AdminDownloadReportsMutationHookResult = ReturnType<typeof useAdminDownloadReportsMutation>;
export type AdminDownloadReportsMutationResult = Apollo.MutationResult<AdminDownloadReportsMutation>;
export type AdminDownloadReportsMutationOptions = Apollo.BaseMutationOptions<AdminDownloadReportsMutation, AdminDownloadReportsMutationVariables>;
export const GetAdminEntityForwardingEmailDocument = gql`
    query GetAdminEntityForwardingEmail($where: EntityWhereInput) {
  entity(where: $where) {
    ...AdminExpenseForwardingEmail
  }
}
    ${AdminExpenseForwardingEmailFragmentDoc}`;
export function useGetAdminEntityForwardingEmailQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>(GetAdminEntityForwardingEmailDocument, options);
      }
export function useGetAdminEntityForwardingEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>(GetAdminEntityForwardingEmailDocument, options);
        }
export function useGetAdminEntityForwardingEmailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>(GetAdminEntityForwardingEmailDocument, options);
        }
export type GetAdminEntityForwardingEmailQueryHookResult = ReturnType<typeof useGetAdminEntityForwardingEmailQuery>;
export type GetAdminEntityForwardingEmailLazyQueryHookResult = ReturnType<typeof useGetAdminEntityForwardingEmailLazyQuery>;
export type GetAdminEntityForwardingEmailSuspenseQueryHookResult = ReturnType<typeof useGetAdminEntityForwardingEmailSuspenseQuery>;
export type GetAdminEntityForwardingEmailQueryResult = Apollo.QueryResult<GetAdminEntityForwardingEmailQuery, GetAdminEntityForwardingEmailQueryVariables>;
export const AdminForwardExpensesDocument = gql`
    mutation AdminForwardExpenses($entityId: String!, $where: ExpenseWhereInput!) {
  forwardExpenses(entityId: $entityId, where: $where)
}
    `;
export function useAdminForwardExpensesMutation(baseOptions?: Apollo.MutationHookOptions<AdminForwardExpensesMutation, AdminForwardExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminForwardExpensesMutation, AdminForwardExpensesMutationVariables>(AdminForwardExpensesDocument, options);
      }
export type AdminForwardExpensesMutationHookResult = ReturnType<typeof useAdminForwardExpensesMutation>;
export type AdminForwardExpensesMutationResult = Apollo.MutationResult<AdminForwardExpensesMutation>;
export type AdminForwardExpensesMutationOptions = Apollo.BaseMutationOptions<AdminForwardExpensesMutation, AdminForwardExpensesMutationVariables>;
export const AdminForwardInvoicesDocument = gql`
    mutation AdminForwardInvoices($entityId: String!, $where: InvoiceWhereInput!) {
  forwardInvoices(entityId: $entityId, where: $where)
}
    `;
export function useAdminForwardInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<AdminForwardInvoicesMutation, AdminForwardInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminForwardInvoicesMutation, AdminForwardInvoicesMutationVariables>(AdminForwardInvoicesDocument, options);
      }
export type AdminForwardInvoicesMutationHookResult = ReturnType<typeof useAdminForwardInvoicesMutation>;
export type AdminForwardInvoicesMutationResult = Apollo.MutationResult<AdminForwardInvoicesMutation>;
export type AdminForwardInvoicesMutationOptions = Apollo.BaseMutationOptions<AdminForwardInvoicesMutation, AdminForwardInvoicesMutationVariables>;
export const UpdateLanguageDocument = gql`
    mutation UpdateLanguage($data: UserUpdateInput!) {
  updateMe(data: $data) {
    id
    language
  }
}
    `;
export function useUpdateLanguageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLanguageMutation, UpdateLanguageMutationVariables>(UpdateLanguageDocument, options);
      }
export type UpdateLanguageMutationHookResult = ReturnType<typeof useUpdateLanguageMutation>;
export type UpdateLanguageMutationResult = Apollo.MutationResult<UpdateLanguageMutation>;
export type UpdateLanguageMutationOptions = Apollo.BaseMutationOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export const AdminUpdateEntityDocument = gql`
    mutation AdminUpdateEntity($id: String!, $data: EntityUpdateInput!) {
  updateEntity(id: $id, data: $data) {
    ...AdminEntityDetail
  }
}
    ${AdminEntityDetailFragmentDoc}`;
export function useAdminUpdateEntityMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateEntityMutation, AdminUpdateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateEntityMutation, AdminUpdateEntityMutationVariables>(AdminUpdateEntityDocument, options);
      }
export type AdminUpdateEntityMutationHookResult = ReturnType<typeof useAdminUpdateEntityMutation>;
export type AdminUpdateEntityMutationResult = Apollo.MutationResult<AdminUpdateEntityMutation>;
export type AdminUpdateEntityMutationOptions = Apollo.BaseMutationOptions<AdminUpdateEntityMutation, AdminUpdateEntityMutationVariables>;
export const AdminUpdateExpenseDocument = gql`
    mutation AdminUpdateExpense($data: ExpenseUpdateInput!, $id: String!) {
  updateExpense(data: $data, id: $id) {
    ...AdminExpenseItem
  }
}
    ${AdminExpenseItemFragmentDoc}`;
export function useAdminUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateExpenseMutation, AdminUpdateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateExpenseMutation, AdminUpdateExpenseMutationVariables>(AdminUpdateExpenseDocument, options);
      }
export type AdminUpdateExpenseMutationHookResult = ReturnType<typeof useAdminUpdateExpenseMutation>;
export type AdminUpdateExpenseMutationResult = Apollo.MutationResult<AdminUpdateExpenseMutation>;
export type AdminUpdateExpenseMutationOptions = Apollo.BaseMutationOptions<AdminUpdateExpenseMutation, AdminUpdateExpenseMutationVariables>;
export const AdminUpdateInvoiceDocument = gql`
    mutation AdminUpdateInvoice($data: InvoiceUpdateInput!, $id: String!) {
  updateInvoice(data: $data, id: $id) {
    ...AdminInvoiceItem
  }
}
    ${AdminInvoiceItemFragmentDoc}`;
export function useAdminUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateInvoiceMutation, AdminUpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateInvoiceMutation, AdminUpdateInvoiceMutationVariables>(AdminUpdateInvoiceDocument, options);
      }
export type AdminUpdateInvoiceMutationHookResult = ReturnType<typeof useAdminUpdateInvoiceMutation>;
export type AdminUpdateInvoiceMutationResult = Apollo.MutationResult<AdminUpdateInvoiceMutation>;
export type AdminUpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<AdminUpdateInvoiceMutation, AdminUpdateInvoiceMutationVariables>;
export const AdminUpdateUserEntityDocument = gql`
    mutation AdminUpdateUserEntity($id: String!, $role: String!) {
  updateUserEntity(id: $id, role: $role) {
    ...UserEntity
  }
}
    ${UserEntityFragmentDoc}`;
export function useAdminUpdateUserEntityMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserEntityMutation, AdminUpdateUserEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateUserEntityMutation, AdminUpdateUserEntityMutationVariables>(AdminUpdateUserEntityDocument, options);
      }
export type AdminUpdateUserEntityMutationHookResult = ReturnType<typeof useAdminUpdateUserEntityMutation>;
export type AdminUpdateUserEntityMutationResult = Apollo.MutationResult<AdminUpdateUserEntityMutation>;
export type AdminUpdateUserEntityMutationOptions = Apollo.BaseMutationOptions<AdminUpdateUserEntityMutation, AdminUpdateUserEntityMutationVariables>;
export const GetEntityAttachmentsDocument = gql`
    query GetEntityAttachments($take: Int, $skip: Int, $orderBy: [AttachmentOrderByWithRelationInput!], $where: EntityWhereInput, $attachmentWhere: AttachmentWhereInput) {
  entity(where: $where) {
    id
    attachments(
      take: $take
      skip: $skip
      orderBy: $orderBy
      where: $attachmentWhere
    ) {
      items {
        ...AttachmentItem
      }
      count
      allItemIds
    }
  }
}
    ${AttachmentItemFragmentDoc}`;
export function useGetEntityAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>(GetEntityAttachmentsDocument, options);
      }
export function useGetEntityAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>(GetEntityAttachmentsDocument, options);
        }
export function useGetEntityAttachmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>(GetEntityAttachmentsDocument, options);
        }
export type GetEntityAttachmentsQueryHookResult = ReturnType<typeof useGetEntityAttachmentsQuery>;
export type GetEntityAttachmentsLazyQueryHookResult = ReturnType<typeof useGetEntityAttachmentsLazyQuery>;
export type GetEntityAttachmentsSuspenseQueryHookResult = ReturnType<typeof useGetEntityAttachmentsSuspenseQuery>;
export type GetEntityAttachmentsQueryResult = Apollo.QueryResult<GetEntityAttachmentsQuery, GetEntityAttachmentsQueryVariables>;
export const BulkUpdateAttachmentsDocument = gql`
    mutation BulkUpdateAttachments($entityId: String!, $ids: [String!]!, $data: AttachmentUpdateInput!) {
  updateAttachments(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useBulkUpdateAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateAttachmentsMutation, BulkUpdateAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateAttachmentsMutation, BulkUpdateAttachmentsMutationVariables>(BulkUpdateAttachmentsDocument, options);
      }
export type BulkUpdateAttachmentsMutationHookResult = ReturnType<typeof useBulkUpdateAttachmentsMutation>;
export type BulkUpdateAttachmentsMutationResult = Apollo.MutationResult<BulkUpdateAttachmentsMutation>;
export type BulkUpdateAttachmentsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateAttachmentsMutation, BulkUpdateAttachmentsMutationVariables>;
export const GetEntityBankStatementsDocument = gql`
    query GetEntityBankStatements($take: Int, $skip: Int, $orderBy: [BankStatementOrderByWithRelationInput!], $where: EntityWhereInput, $bankStatementWhere: BankStatementWhereInput) {
  entity(where: $where) {
    id
    bankStatements(
      take: $take
      skip: $skip
      orderBy: $orderBy
      where: $bankStatementWhere
    ) {
      items {
        ...BankStatementItem
      }
      count
      allItemIds
    }
  }
}
    ${BankStatementItemFragmentDoc}`;
export function useGetEntityBankStatementsQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>(GetEntityBankStatementsDocument, options);
      }
export function useGetEntityBankStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>(GetEntityBankStatementsDocument, options);
        }
export function useGetEntityBankStatementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>(GetEntityBankStatementsDocument, options);
        }
export type GetEntityBankStatementsQueryHookResult = ReturnType<typeof useGetEntityBankStatementsQuery>;
export type GetEntityBankStatementsLazyQueryHookResult = ReturnType<typeof useGetEntityBankStatementsLazyQuery>;
export type GetEntityBankStatementsSuspenseQueryHookResult = ReturnType<typeof useGetEntityBankStatementsSuspenseQuery>;
export type GetEntityBankStatementsQueryResult = Apollo.QueryResult<GetEntityBankStatementsQuery, GetEntityBankStatementsQueryVariables>;
export const UpdateBankStatementDocument = gql`
    mutation UpdateBankStatement($data: BankStatementUpdateInput!, $id: String!) {
  updateBankStatement(data: $data, id: $id) {
    ...BankStatementItem
  }
}
    ${BankStatementItemFragmentDoc}`;
export function useUpdateBankStatementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankStatementMutation, UpdateBankStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBankStatementMutation, UpdateBankStatementMutationVariables>(UpdateBankStatementDocument, options);
      }
export type UpdateBankStatementMutationHookResult = ReturnType<typeof useUpdateBankStatementMutation>;
export type UpdateBankStatementMutationResult = Apollo.MutationResult<UpdateBankStatementMutation>;
export type UpdateBankStatementMutationOptions = Apollo.BaseMutationOptions<UpdateBankStatementMutation, UpdateBankStatementMutationVariables>;
export const BulkUpdateBankStatementsDocument = gql`
    mutation BulkUpdateBankStatements($entityId: String!, $ids: [String!]!, $data: BankStatementUpdateInput!) {
  updateBankStatements(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useBulkUpdateBankStatementsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateBankStatementsMutation, BulkUpdateBankStatementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateBankStatementsMutation, BulkUpdateBankStatementsMutationVariables>(BulkUpdateBankStatementsDocument, options);
      }
export type BulkUpdateBankStatementsMutationHookResult = ReturnType<typeof useBulkUpdateBankStatementsMutation>;
export type BulkUpdateBankStatementsMutationResult = Apollo.MutationResult<BulkUpdateBankStatementsMutation>;
export type BulkUpdateBankStatementsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateBankStatementsMutation, BulkUpdateBankStatementsMutationVariables>;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($data: AttachmentCreateInput!) {
  createAttachment(data: $data) {
    id
    name
    file
    entityId
    year
  }
}
    `;
export function useCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, options);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const CreateBankStatementDocument = gql`
    mutation CreateBankStatement($data: BankStatementCreateInput!) {
  createBankStatement(data: $data) {
    id
    name
    file
    entityId
    year
  }
}
    `;
export function useCreateBankStatementMutation(baseOptions?: Apollo.MutationHookOptions<CreateBankStatementMutation, CreateBankStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBankStatementMutation, CreateBankStatementMutationVariables>(CreateBankStatementDocument, options);
      }
export type CreateBankStatementMutationHookResult = ReturnType<typeof useCreateBankStatementMutation>;
export type CreateBankStatementMutationResult = Apollo.MutationResult<CreateBankStatementMutation>;
export type CreateBankStatementMutationOptions = Apollo.BaseMutationOptions<CreateBankStatementMutation, CreateBankStatementMutationVariables>;
export const CreateExpenseDocument = gql`
    mutation CreateExpense($data: ExpenseCreateInput!) {
  createExpense(data: $data) {
    description
    file
    entityId
    date
    id
  }
}
    `;
export function useCreateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseMutation, CreateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseMutation, CreateExpenseMutationVariables>(CreateExpenseDocument, options);
      }
export type CreateExpenseMutationHookResult = ReturnType<typeof useCreateExpenseMutation>;
export type CreateExpenseMutationResult = Apollo.MutationResult<CreateExpenseMutation>;
export type CreateExpenseMutationOptions = Apollo.BaseMutationOptions<CreateExpenseMutation, CreateExpenseMutationVariables>;
export const CreateManyExpensesDocument = gql`
    mutation CreateManyExpenses($data: [ExpenseCreateManyInput!]!) {
  createManyExpenses(data: $data)
}
    `;
export function useCreateManyExpensesMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyExpensesMutation, CreateManyExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyExpensesMutation, CreateManyExpensesMutationVariables>(CreateManyExpensesDocument, options);
      }
export type CreateManyExpensesMutationHookResult = ReturnType<typeof useCreateManyExpensesMutation>;
export type CreateManyExpensesMutationResult = Apollo.MutationResult<CreateManyExpensesMutation>;
export type CreateManyExpensesMutationOptions = Apollo.BaseMutationOptions<CreateManyExpensesMutation, CreateManyExpensesMutationVariables>;
export const GetUserCompanyEntitiesDocument = gql`
    query GetUserCompanyEntities {
  me {
    id
    companyEntities {
      ...EntityItem
    }
  }
}
    ${EntityItemFragmentDoc}`;
export function useGetUserCompanyEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>(GetUserCompanyEntitiesDocument, options);
      }
export function useGetUserCompanyEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>(GetUserCompanyEntitiesDocument, options);
        }
export function useGetUserCompanyEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>(GetUserCompanyEntitiesDocument, options);
        }
export type GetUserCompanyEntitiesQueryHookResult = ReturnType<typeof useGetUserCompanyEntitiesQuery>;
export type GetUserCompanyEntitiesLazyQueryHookResult = ReturnType<typeof useGetUserCompanyEntitiesLazyQuery>;
export type GetUserCompanyEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetUserCompanyEntitiesSuspenseQuery>;
export type GetUserCompanyEntitiesQueryResult = Apollo.QueryResult<GetUserCompanyEntitiesQuery, GetUserCompanyEntitiesQueryVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($data: InvoiceCreateInput!) {
  createInvoice(data: $data) {
    id
    description
    file
    entityId
    date
  }
}
    `;
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const CreateManyInvoicesDocument = gql`
    mutation CreateManyInvoices($data: [InvoiceCreateManyInput!]!) {
  createManyInvoices(data: $data)
}
    `;
export function useCreateManyInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyInvoicesMutation, CreateManyInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyInvoicesMutation, CreateManyInvoicesMutationVariables>(CreateManyInvoicesDocument, options);
      }
export type CreateManyInvoicesMutationHookResult = ReturnType<typeof useCreateManyInvoicesMutation>;
export type CreateManyInvoicesMutationResult = Apollo.MutationResult<CreateManyInvoicesMutation>;
export type CreateManyInvoicesMutationOptions = Apollo.BaseMutationOptions<CreateManyInvoicesMutation, CreateManyInvoicesMutationVariables>;
export const CreateUpdatesDocument = gql`
    mutation CreateUpdates($data: CreateUpdatesInput!) {
  createUpdates(data: $data)
}
    `;
export function useCreateUpdatesMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdatesMutation, CreateUpdatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdatesMutation, CreateUpdatesMutationVariables>(CreateUpdatesDocument, options);
      }
export type CreateUpdatesMutationHookResult = ReturnType<typeof useCreateUpdatesMutation>;
export type CreateUpdatesMutationResult = Apollo.MutationResult<CreateUpdatesMutation>;
export type CreateUpdatesMutationOptions = Apollo.BaseMutationOptions<CreateUpdatesMutation, CreateUpdatesMutationVariables>;
export const CreateUpdatesAllUsersDocument = gql`
    mutation CreateUpdatesAllUsers($data: CreateUpdatesAllUsersInput!) {
  createUpdatesAllUsers(data: $data)
}
    `;
export function useCreateUpdatesAllUsersMutation(baseOptions?: Apollo.MutationHookOptions<CreateUpdatesAllUsersMutation, CreateUpdatesAllUsersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUpdatesAllUsersMutation, CreateUpdatesAllUsersMutationVariables>(CreateUpdatesAllUsersDocument, options);
      }
export type CreateUpdatesAllUsersMutationHookResult = ReturnType<typeof useCreateUpdatesAllUsersMutation>;
export type CreateUpdatesAllUsersMutationResult = Apollo.MutationResult<CreateUpdatesAllUsersMutation>;
export type CreateUpdatesAllUsersMutationOptions = Apollo.BaseMutationOptions<CreateUpdatesAllUsersMutation, CreateUpdatesAllUsersMutationVariables>;
export const GetEntityExpensesDocument = gql`
    query GetEntityExpenses($take: Int, $skip: Int, $orderBy: [ExpenseOrderByWithRelationInput!], $where: EntityWhereInput, $expenseWhere: ExpenseWhereInput) {
  entity(where: $where) {
    id
    expenses(take: $take, skip: $skip, where: $expenseWhere, orderBy: $orderBy) {
      items {
        ...ExpenseItem
      }
      count
      allItemIds
    }
  }
}
    ${ExpenseItemFragmentDoc}`;
export function useGetEntityExpensesQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>(GetEntityExpensesDocument, options);
      }
export function useGetEntityExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>(GetEntityExpensesDocument, options);
        }
export function useGetEntityExpensesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>(GetEntityExpensesDocument, options);
        }
export type GetEntityExpensesQueryHookResult = ReturnType<typeof useGetEntityExpensesQuery>;
export type GetEntityExpensesLazyQueryHookResult = ReturnType<typeof useGetEntityExpensesLazyQuery>;
export type GetEntityExpensesSuspenseQueryHookResult = ReturnType<typeof useGetEntityExpensesSuspenseQuery>;
export type GetEntityExpensesQueryResult = Apollo.QueryResult<GetEntityExpensesQuery, GetEntityExpensesQueryVariables>;
export const BulkUpdateExpensesDocument = gql`
    mutation BulkUpdateExpenses($entityId: String!, $ids: [String!]!, $data: ExpenseUpdateInput!) {
  updateExpenses(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useBulkUpdateExpensesMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateExpensesMutation, BulkUpdateExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateExpensesMutation, BulkUpdateExpensesMutationVariables>(BulkUpdateExpensesDocument, options);
      }
export type BulkUpdateExpensesMutationHookResult = ReturnType<typeof useBulkUpdateExpensesMutation>;
export type BulkUpdateExpensesMutationResult = Apollo.MutationResult<BulkUpdateExpensesMutation>;
export type BulkUpdateExpensesMutationOptions = Apollo.BaseMutationOptions<BulkUpdateExpensesMutation, BulkUpdateExpensesMutationVariables>;
export const GetEntityIncomeDataDocument = gql`
    query GetEntityIncomeData($where: EntityWhereInput, $incomeDataWhere: IncomeDataWhereInput) {
  entity(where: $where) {
    id
    name
    incomeData(where: $incomeDataWhere) {
      items {
        ...IncomeDataItem
      }
      count
    }
  }
}
    ${IncomeDataItemFragmentDoc}`;
export function useGetEntityIncomeDataQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>(GetEntityIncomeDataDocument, options);
      }
export function useGetEntityIncomeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>(GetEntityIncomeDataDocument, options);
        }
export function useGetEntityIncomeDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>(GetEntityIncomeDataDocument, options);
        }
export type GetEntityIncomeDataQueryHookResult = ReturnType<typeof useGetEntityIncomeDataQuery>;
export type GetEntityIncomeDataLazyQueryHookResult = ReturnType<typeof useGetEntityIncomeDataLazyQuery>;
export type GetEntityIncomeDataSuspenseQueryHookResult = ReturnType<typeof useGetEntityIncomeDataSuspenseQuery>;
export type GetEntityIncomeDataQueryResult = Apollo.QueryResult<GetEntityIncomeDataQuery, GetEntityIncomeDataQueryVariables>;
export const GetEntityInvoicesDocument = gql`
    query GetEntityInvoices($take: Int, $skip: Int, $orderBy: [InvoiceOrderByWithRelationInput!], $where: EntityWhereInput, $invoiceWhere: InvoiceWhereInput) {
  entity(where: $where) {
    id
    invoices(take: $take, skip: $skip, where: $invoiceWhere, orderBy: $orderBy) {
      items {
        ...InvoiceItem
      }
      count
      allItemIds
    }
  }
}
    ${InvoiceItemFragmentDoc}`;
export function useGetEntityInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>(GetEntityInvoicesDocument, options);
      }
export function useGetEntityInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>(GetEntityInvoicesDocument, options);
        }
export function useGetEntityInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>(GetEntityInvoicesDocument, options);
        }
export type GetEntityInvoicesQueryHookResult = ReturnType<typeof useGetEntityInvoicesQuery>;
export type GetEntityInvoicesLazyQueryHookResult = ReturnType<typeof useGetEntityInvoicesLazyQuery>;
export type GetEntityInvoicesSuspenseQueryHookResult = ReturnType<typeof useGetEntityInvoicesSuspenseQuery>;
export type GetEntityInvoicesQueryResult = Apollo.QueryResult<GetEntityInvoicesQuery, GetEntityInvoicesQueryVariables>;
export const ArchiveInvoiceDocument = gql`
    mutation ArchiveInvoice($id: String!) {
  archiveInvoice(id: $id) {
    ...InvoiceItem
  }
}
    ${InvoiceItemFragmentDoc}`;
export function useArchiveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveInvoiceMutation, ArchiveInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveInvoiceMutation, ArchiveInvoiceMutationVariables>(ArchiveInvoiceDocument, options);
      }
export type ArchiveInvoiceMutationHookResult = ReturnType<typeof useArchiveInvoiceMutation>;
export type ArchiveInvoiceMutationResult = Apollo.MutationResult<ArchiveInvoiceMutation>;
export type ArchiveInvoiceMutationOptions = Apollo.BaseMutationOptions<ArchiveInvoiceMutation, ArchiveInvoiceMutationVariables>;
export const BulkUpdateInvoicesDocument = gql`
    mutation BulkUpdateInvoices($entityId: String!, $ids: [String!]!, $data: InvoiceUpdateInput!) {
  updateInvoices(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useBulkUpdateInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateInvoicesMutation, BulkUpdateInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateInvoicesMutation, BulkUpdateInvoicesMutationVariables>(BulkUpdateInvoicesDocument, options);
      }
export type BulkUpdateInvoicesMutationHookResult = ReturnType<typeof useBulkUpdateInvoicesMutation>;
export type BulkUpdateInvoicesMutationResult = Apollo.MutationResult<BulkUpdateInvoicesMutation>;
export type BulkUpdateInvoicesMutationOptions = Apollo.BaseMutationOptions<BulkUpdateInvoicesMutation, BulkUpdateInvoicesMutationVariables>;
export const GetLatestReportsDocument = gql`
    query GetLatestReports {
  me {
    id
    latestReports {
      ...LatestReportItem
    }
  }
}
    ${LatestReportItemFragmentDoc}`;
export function useGetLatestReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestReportsQuery, GetLatestReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestReportsQuery, GetLatestReportsQueryVariables>(GetLatestReportsDocument, options);
      }
export function useGetLatestReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestReportsQuery, GetLatestReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestReportsQuery, GetLatestReportsQueryVariables>(GetLatestReportsDocument, options);
        }
export function useGetLatestReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLatestReportsQuery, GetLatestReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestReportsQuery, GetLatestReportsQueryVariables>(GetLatestReportsDocument, options);
        }
export type GetLatestReportsQueryHookResult = ReturnType<typeof useGetLatestReportsQuery>;
export type GetLatestReportsLazyQueryHookResult = ReturnType<typeof useGetLatestReportsLazyQuery>;
export type GetLatestReportsSuspenseQueryHookResult = ReturnType<typeof useGetLatestReportsSuspenseQuery>;
export type GetLatestReportsQueryResult = Apollo.QueryResult<GetLatestReportsQuery, GetLatestReportsQueryVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($id: String!, $data: ReportUpdateInput!) {
  updateReport(id: $id, data: $data) {
    ...ReportOverview
  }
}
    ${ReportOverviewFragmentDoc}`;
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const GetEntityReportsDocument = gql`
    query GetEntityReports($take: Int, $skip: Int, $orderBy: [ReportOrderByWithRelationInput!], $where: EntityWhereInput, $reportWhere: ReportWhereInput) {
  entity(where: $where) {
    id
    name
    reports(take: $take, skip: $skip, orderBy: $orderBy, where: $reportWhere) {
      items {
        ...ReportItem
      }
      count
    }
  }
}
    ${ReportItemFragmentDoc}`;
export function useGetEntityReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityReportsQuery, GetEntityReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityReportsQuery, GetEntityReportsQueryVariables>(GetEntityReportsDocument, options);
      }
export function useGetEntityReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityReportsQuery, GetEntityReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityReportsQuery, GetEntityReportsQueryVariables>(GetEntityReportsDocument, options);
        }
export function useGetEntityReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityReportsQuery, GetEntityReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityReportsQuery, GetEntityReportsQueryVariables>(GetEntityReportsDocument, options);
        }
export type GetEntityReportsQueryHookResult = ReturnType<typeof useGetEntityReportsQuery>;
export type GetEntityReportsLazyQueryHookResult = ReturnType<typeof useGetEntityReportsLazyQuery>;
export type GetEntityReportsSuspenseQueryHookResult = ReturnType<typeof useGetEntityReportsSuspenseQuery>;
export type GetEntityReportsQueryResult = Apollo.QueryResult<GetEntityReportsQuery, GetEntityReportsQueryVariables>;
export const UpdateExpenseDocument = gql`
    mutation UpdateExpense($id: String!, $data: ExpenseUpdateInput!) {
  updateExpense(id: $id, data: $data) {
    ...ExpenseItem
  }
}
    ${ExpenseItemFragmentDoc}`;
export function useUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, options);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($data: InvoiceUpdateInput!, $id: String!) {
  updateInvoice(data: $data, id: $id) {
    ...InvoiceItem
  }
}
    ${InvoiceItemFragmentDoc}`;
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const GetUpdatesDocument = gql`
    query GetUpdates($where: UpdateWhereInput, $orderBy: [UpdateOrderByWithRelationInput!]) {
  me {
    id
    updates(where: $where, orderBy: $orderBy) {
      items {
        ...UpdateItem
      }
    }
  }
}
    ${UpdateItemFragmentDoc}`;
export function useGetUpdatesQuery(baseOptions?: Apollo.QueryHookOptions<GetUpdatesQuery, GetUpdatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpdatesQuery, GetUpdatesQueryVariables>(GetUpdatesDocument, options);
      }
export function useGetUpdatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdatesQuery, GetUpdatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpdatesQuery, GetUpdatesQueryVariables>(GetUpdatesDocument, options);
        }
export function useGetUpdatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUpdatesQuery, GetUpdatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUpdatesQuery, GetUpdatesQueryVariables>(GetUpdatesDocument, options);
        }
export type GetUpdatesQueryHookResult = ReturnType<typeof useGetUpdatesQuery>;
export type GetUpdatesLazyQueryHookResult = ReturnType<typeof useGetUpdatesLazyQuery>;
export type GetUpdatesSuspenseQueryHookResult = ReturnType<typeof useGetUpdatesSuspenseQuery>;
export type GetUpdatesQueryResult = Apollo.QueryResult<GetUpdatesQuery, GetUpdatesQueryVariables>;
export const GetNewUpdatesCountDocument = gql`
    query GetNewUpdatesCount($where: UpdateWhereInput) {
  me {
    id
    updates(where: $where) {
      count
    }
  }
}
    `;
export function useGetNewUpdatesCountQuery(baseOptions?: Apollo.QueryHookOptions<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>(GetNewUpdatesCountDocument, options);
      }
export function useGetNewUpdatesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>(GetNewUpdatesCountDocument, options);
        }
export function useGetNewUpdatesCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>(GetNewUpdatesCountDocument, options);
        }
export type GetNewUpdatesCountQueryHookResult = ReturnType<typeof useGetNewUpdatesCountQuery>;
export type GetNewUpdatesCountLazyQueryHookResult = ReturnType<typeof useGetNewUpdatesCountLazyQuery>;
export type GetNewUpdatesCountSuspenseQueryHookResult = ReturnType<typeof useGetNewUpdatesCountSuspenseQuery>;
export type GetNewUpdatesCountQueryResult = Apollo.QueryResult<GetNewUpdatesCountQuery, GetNewUpdatesCountQueryVariables>;
export const UpdateUpdateDocument = gql`
    mutation UpdateUpdate($id: String!, $data: UpdateUpdateInput!) {
  updateUpdate(id: $id, data: $data) {
    ...UpdateItem
  }
}
    ${UpdateItemFragmentDoc}`;
export function useUpdateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUpdateMutation, UpdateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUpdateMutation, UpdateUpdateMutationVariables>(UpdateUpdateDocument, options);
      }
export type UpdateUpdateMutationHookResult = ReturnType<typeof useUpdateUpdateMutation>;
export type UpdateUpdateMutationResult = Apollo.MutationResult<UpdateUpdateMutation>;
export type UpdateUpdateMutationOptions = Apollo.BaseMutationOptions<UpdateUpdateMutation, UpdateUpdateMutationVariables>;
export const GetUserEntitiesDocument = gql`
    query GetUserEntities {
  me {
    id
    entities {
      ...EntityItem
    }
  }
}
    ${EntityItemFragmentDoc}`;
export function useGetUserEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>(GetUserEntitiesDocument, options);
      }
export function useGetUserEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>(GetUserEntitiesDocument, options);
        }
export function useGetUserEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>(GetUserEntitiesDocument, options);
        }
export type GetUserEntitiesQueryHookResult = ReturnType<typeof useGetUserEntitiesQuery>;
export type GetUserEntitiesLazyQueryHookResult = ReturnType<typeof useGetUserEntitiesLazyQuery>;
export type GetUserEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetUserEntitiesSuspenseQuery>;
export type GetUserEntitiesQueryResult = Apollo.QueryResult<GetUserEntitiesQuery, GetUserEntitiesQueryVariables>;
export const GetUserTasksDocument = gql`
    query GetUserTasks($userId: String!) {
  me {
    ...UserTasks
  }
}
    ${UserTasksFragmentDoc}`;
export function useGetUserTasksQuery(baseOptions: Apollo.QueryHookOptions<GetUserTasksQuery, GetUserTasksQueryVariables> & ({ variables: GetUserTasksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserTasksQuery, GetUserTasksQueryVariables>(GetUserTasksDocument, options);
      }
export function useGetUserTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserTasksQuery, GetUserTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserTasksQuery, GetUserTasksQueryVariables>(GetUserTasksDocument, options);
        }
export function useGetUserTasksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserTasksQuery, GetUserTasksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserTasksQuery, GetUserTasksQueryVariables>(GetUserTasksDocument, options);
        }
export type GetUserTasksQueryHookResult = ReturnType<typeof useGetUserTasksQuery>;
export type GetUserTasksLazyQueryHookResult = ReturnType<typeof useGetUserTasksLazyQuery>;
export type GetUserTasksSuspenseQueryHookResult = ReturnType<typeof useGetUserTasksSuspenseQuery>;
export type GetUserTasksQueryResult = Apollo.QueryResult<GetUserTasksQuery, GetUserTasksQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetSignedUrlForPutDocument = gql`
    mutation GetSignedUrlForPut($data: S3SignedUrlInput!) {
  getSignedS3UrlForPut(data: $data) {
    url
    uploadUrl
  }
}
    `;
export function useGetSignedUrlForPutMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>(GetSignedUrlForPutDocument, options);
      }
export type GetSignedUrlForPutMutationHookResult = ReturnType<typeof useGetSignedUrlForPutMutation>;
export type GetSignedUrlForPutMutationResult = Apollo.MutationResult<GetSignedUrlForPutMutation>;
export type GetSignedUrlForPutMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>;
export const GetBulkSignedUrlForPutDocument = gql`
    mutation GetBulkSignedUrlForPut($data: S3BulkSignedUrlInput!) {
  getBulkSignedS3UrlForPut(data: $data) {
    url
    uploadUrl
    key
  }
}
    `;
export function useGetBulkSignedUrlForPutMutation(baseOptions?: Apollo.MutationHookOptions<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>(GetBulkSignedUrlForPutDocument, options);
      }
export type GetBulkSignedUrlForPutMutationHookResult = ReturnType<typeof useGetBulkSignedUrlForPutMutation>;
export type GetBulkSignedUrlForPutMutationResult = Apollo.MutationResult<GetBulkSignedUrlForPutMutation>;
export type GetBulkSignedUrlForPutMutationOptions = Apollo.BaseMutationOptions<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>;
export const AdminGetAdminDocument = gql`
    query AdminGetAdmin($where: UserWhereInput) {
  admin(where: $where) {
    ...AdminAdminDetail
  }
}
    ${AdminAdminDetailFragmentDoc}`;
export function useAdminGetAdminQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAdminQuery, AdminGetAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAdminQuery, AdminGetAdminQueryVariables>(AdminGetAdminDocument, options);
      }
export function useAdminGetAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAdminQuery, AdminGetAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAdminQuery, AdminGetAdminQueryVariables>(AdminGetAdminDocument, options);
        }
export function useAdminGetAdminSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetAdminQuery, AdminGetAdminQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetAdminQuery, AdminGetAdminQueryVariables>(AdminGetAdminDocument, options);
        }
export type AdminGetAdminQueryHookResult = ReturnType<typeof useAdminGetAdminQuery>;
export type AdminGetAdminLazyQueryHookResult = ReturnType<typeof useAdminGetAdminLazyQuery>;
export type AdminGetAdminSuspenseQueryHookResult = ReturnType<typeof useAdminGetAdminSuspenseQuery>;
export type AdminGetAdminQueryResult = Apollo.QueryResult<AdminGetAdminQuery, AdminGetAdminQueryVariables>;
export const AdminGetAdminsDocument = gql`
    query AdminGetAdmins($take: Int, $skip: Int, $orderBy: [UserOrderByWithRelationInput!], $where: UserWhereInput) {
  admins(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
    items {
      ...AdminAdminItem
    }
    count
  }
}
    ${AdminAdminItemFragmentDoc}`;
export function useAdminGetAdminsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>(AdminGetAdminsDocument, options);
      }
export function useAdminGetAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>(AdminGetAdminsDocument, options);
        }
export function useAdminGetAdminsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>(AdminGetAdminsDocument, options);
        }
export type AdminGetAdminsQueryHookResult = ReturnType<typeof useAdminGetAdminsQuery>;
export type AdminGetAdminsLazyQueryHookResult = ReturnType<typeof useAdminGetAdminsLazyQuery>;
export type AdminGetAdminsSuspenseQueryHookResult = ReturnType<typeof useAdminGetAdminsSuspenseQuery>;
export type AdminGetAdminsQueryResult = Apollo.QueryResult<AdminGetAdminsQuery, AdminGetAdminsQueryVariables>;
export const AdminGetEntityAttachmentsDocument = gql`
    query AdminGetEntityAttachments($take: Int, $skip: Int, $where: AttachmentWhereInput, $orderBy: [AttachmentOrderByWithRelationInput!]) {
  attachments(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
    items {
      ...AdminAttachmentItem
    }
    count
    allItemIds
  }
}
    ${AdminAttachmentItemFragmentDoc}`;
export function useAdminGetEntityAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>(AdminGetEntityAttachmentsDocument, options);
      }
export function useAdminGetEntityAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>(AdminGetEntityAttachmentsDocument, options);
        }
export function useAdminGetEntityAttachmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>(AdminGetEntityAttachmentsDocument, options);
        }
export type AdminGetEntityAttachmentsQueryHookResult = ReturnType<typeof useAdminGetEntityAttachmentsQuery>;
export type AdminGetEntityAttachmentsLazyQueryHookResult = ReturnType<typeof useAdminGetEntityAttachmentsLazyQuery>;
export type AdminGetEntityAttachmentsSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityAttachmentsSuspenseQuery>;
export type AdminGetEntityAttachmentsQueryResult = Apollo.QueryResult<AdminGetEntityAttachmentsQuery, AdminGetEntityAttachmentsQueryVariables>;
export const AdminBulkUpdateAttachmentsDocument = gql`
    mutation AdminBulkUpdateAttachments($entityId: String!, $ids: [String!]!, $data: AttachmentUpdateInput!) {
  updateAttachments(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useAdminBulkUpdateAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkUpdateAttachmentsMutation, AdminBulkUpdateAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkUpdateAttachmentsMutation, AdminBulkUpdateAttachmentsMutationVariables>(AdminBulkUpdateAttachmentsDocument, options);
      }
export type AdminBulkUpdateAttachmentsMutationHookResult = ReturnType<typeof useAdminBulkUpdateAttachmentsMutation>;
export type AdminBulkUpdateAttachmentsMutationResult = Apollo.MutationResult<AdminBulkUpdateAttachmentsMutation>;
export type AdminBulkUpdateAttachmentsMutationOptions = Apollo.BaseMutationOptions<AdminBulkUpdateAttachmentsMutation, AdminBulkUpdateAttachmentsMutationVariables>;
export const AdminArchiveAttachmentDocument = gql`
    mutation AdminArchiveAttachment($id: String!) {
  archiveAttachment(id: $id) {
    ...AdminAttachmentItem
  }
}
    ${AdminAttachmentItemFragmentDoc}`;
export function useAdminArchiveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AdminArchiveAttachmentMutation, AdminArchiveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminArchiveAttachmentMutation, AdminArchiveAttachmentMutationVariables>(AdminArchiveAttachmentDocument, options);
      }
export type AdminArchiveAttachmentMutationHookResult = ReturnType<typeof useAdminArchiveAttachmentMutation>;
export type AdminArchiveAttachmentMutationResult = Apollo.MutationResult<AdminArchiveAttachmentMutation>;
export type AdminArchiveAttachmentMutationOptions = Apollo.BaseMutationOptions<AdminArchiveAttachmentMutation, AdminArchiveAttachmentMutationVariables>;
export const AdminUnarchiveAttachmentDocument = gql`
    mutation AdminUnarchiveAttachment($id: String!) {
  unarchiveAttachment(id: $id) {
    ...AdminAttachmentItem
  }
}
    ${AdminAttachmentItemFragmentDoc}`;
export function useAdminUnarchiveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AdminUnarchiveAttachmentMutation, AdminUnarchiveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUnarchiveAttachmentMutation, AdminUnarchiveAttachmentMutationVariables>(AdminUnarchiveAttachmentDocument, options);
      }
export type AdminUnarchiveAttachmentMutationHookResult = ReturnType<typeof useAdminUnarchiveAttachmentMutation>;
export type AdminUnarchiveAttachmentMutationResult = Apollo.MutationResult<AdminUnarchiveAttachmentMutation>;
export type AdminUnarchiveAttachmentMutationOptions = Apollo.BaseMutationOptions<AdminUnarchiveAttachmentMutation, AdminUnarchiveAttachmentMutationVariables>;
export const AdminUpdateAttachmentDocument = gql`
    mutation AdminUpdateAttachment($data: AttachmentUpdateInput!, $id: String!) {
  updateAttachment(data: $data, id: $id) {
    ...AdminAttachmentItem
  }
}
    ${AdminAttachmentItemFragmentDoc}`;
export function useAdminUpdateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateAttachmentMutation, AdminUpdateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateAttachmentMutation, AdminUpdateAttachmentMutationVariables>(AdminUpdateAttachmentDocument, options);
      }
export type AdminUpdateAttachmentMutationHookResult = ReturnType<typeof useAdminUpdateAttachmentMutation>;
export type AdminUpdateAttachmentMutationResult = Apollo.MutationResult<AdminUpdateAttachmentMutation>;
export type AdminUpdateAttachmentMutationOptions = Apollo.BaseMutationOptions<AdminUpdateAttachmentMutation, AdminUpdateAttachmentMutationVariables>;
export const AdminGetEntityBankStatementsDocument = gql`
    query AdminGetEntityBankStatements($take: Int, $skip: Int, $where: BankStatementWhereInput, $orderBy: [BankStatementOrderByWithRelationInput!]) {
  bankStatements(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
    items {
      ...AdminBankStatementItem
    }
    count
    allItemIds
  }
}
    ${AdminBankStatementItemFragmentDoc}`;
export function useAdminGetEntityBankStatementsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>(AdminGetEntityBankStatementsDocument, options);
      }
export function useAdminGetEntityBankStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>(AdminGetEntityBankStatementsDocument, options);
        }
export function useAdminGetEntityBankStatementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>(AdminGetEntityBankStatementsDocument, options);
        }
export type AdminGetEntityBankStatementsQueryHookResult = ReturnType<typeof useAdminGetEntityBankStatementsQuery>;
export type AdminGetEntityBankStatementsLazyQueryHookResult = ReturnType<typeof useAdminGetEntityBankStatementsLazyQuery>;
export type AdminGetEntityBankStatementsSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityBankStatementsSuspenseQuery>;
export type AdminGetEntityBankStatementsQueryResult = Apollo.QueryResult<AdminGetEntityBankStatementsQuery, AdminGetEntityBankStatementsQueryVariables>;
export const AdminBulkUpdateBankStatementsDocument = gql`
    mutation AdminBulkUpdateBankStatements($entityId: String!, $ids: [String!]!, $data: BankStatementUpdateInput!) {
  updateBankStatements(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useAdminBulkUpdateBankStatementsMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkUpdateBankStatementsMutation, AdminBulkUpdateBankStatementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkUpdateBankStatementsMutation, AdminBulkUpdateBankStatementsMutationVariables>(AdminBulkUpdateBankStatementsDocument, options);
      }
export type AdminBulkUpdateBankStatementsMutationHookResult = ReturnType<typeof useAdminBulkUpdateBankStatementsMutation>;
export type AdminBulkUpdateBankStatementsMutationResult = Apollo.MutationResult<AdminBulkUpdateBankStatementsMutation>;
export type AdminBulkUpdateBankStatementsMutationOptions = Apollo.BaseMutationOptions<AdminBulkUpdateBankStatementsMutation, AdminBulkUpdateBankStatementsMutationVariables>;
export const AdminUpdateBankStatementDocument = gql`
    mutation AdminUpdateBankStatement($data: BankStatementUpdateInput!, $id: String!) {
  updateBankStatement(data: $data, id: $id) {
    ...AdminBankStatementItem
  }
}
    ${AdminBankStatementItemFragmentDoc}`;
export function useAdminUpdateBankStatementMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateBankStatementMutation, AdminUpdateBankStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateBankStatementMutation, AdminUpdateBankStatementMutationVariables>(AdminUpdateBankStatementDocument, options);
      }
export type AdminUpdateBankStatementMutationHookResult = ReturnType<typeof useAdminUpdateBankStatementMutation>;
export type AdminUpdateBankStatementMutationResult = Apollo.MutationResult<AdminUpdateBankStatementMutation>;
export type AdminUpdateBankStatementMutationOptions = Apollo.BaseMutationOptions<AdminUpdateBankStatementMutation, AdminUpdateBankStatementMutationVariables>;
export const AdminGetEntityExpensesDocument = gql`
    query AdminGetEntityExpenses($take: Int, $skip: Int, $where: ExpenseWhereInput, $orderBy: [ExpenseOrderByWithRelationInput!]) {
  expenses(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
    items {
      ...AdminExpenseItem
    }
    count
    allItemIds
  }
}
    ${AdminExpenseItemFragmentDoc}`;
export function useAdminGetEntityExpensesQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>(AdminGetEntityExpensesDocument, options);
      }
export function useAdminGetEntityExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>(AdminGetEntityExpensesDocument, options);
        }
export function useAdminGetEntityExpensesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>(AdminGetEntityExpensesDocument, options);
        }
export type AdminGetEntityExpensesQueryHookResult = ReturnType<typeof useAdminGetEntityExpensesQuery>;
export type AdminGetEntityExpensesLazyQueryHookResult = ReturnType<typeof useAdminGetEntityExpensesLazyQuery>;
export type AdminGetEntityExpensesSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityExpensesSuspenseQuery>;
export type AdminGetEntityExpensesQueryResult = Apollo.QueryResult<AdminGetEntityExpensesQuery, AdminGetEntityExpensesQueryVariables>;
export const AdminExportExpensesDocument = gql`
    mutation AdminExportExpenses($entityId: String!) {
  exportExpenses(entityId: $entityId)
}
    `;
export function useAdminExportExpensesMutation(baseOptions?: Apollo.MutationHookOptions<AdminExportExpensesMutation, AdminExportExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminExportExpensesMutation, AdminExportExpensesMutationVariables>(AdminExportExpensesDocument, options);
      }
export type AdminExportExpensesMutationHookResult = ReturnType<typeof useAdminExportExpensesMutation>;
export type AdminExportExpensesMutationResult = Apollo.MutationResult<AdminExportExpensesMutation>;
export type AdminExportExpensesMutationOptions = Apollo.BaseMutationOptions<AdminExportExpensesMutation, AdminExportExpensesMutationVariables>;
export const CreateActivitiesDocument = gql`
    mutation CreateActivities($data: [ActivityCreateManyInput!]!) {
  createActivities(data: $data)
}
    `;
export function useCreateActivitiesMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivitiesMutation, CreateActivitiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivitiesMutation, CreateActivitiesMutationVariables>(CreateActivitiesDocument, options);
      }
export type CreateActivitiesMutationHookResult = ReturnType<typeof useCreateActivitiesMutation>;
export type CreateActivitiesMutationResult = Apollo.MutationResult<CreateActivitiesMutation>;
export type CreateActivitiesMutationOptions = Apollo.BaseMutationOptions<CreateActivitiesMutation, CreateActivitiesMutationVariables>;
export const AdminBulkUpdateExpensesDocument = gql`
    mutation AdminBulkUpdateExpenses($entityId: String!, $ids: [String!]!, $data: ExpenseUpdateInput!) {
  updateExpenses(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useAdminBulkUpdateExpensesMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkUpdateExpensesMutation, AdminBulkUpdateExpensesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkUpdateExpensesMutation, AdminBulkUpdateExpensesMutationVariables>(AdminBulkUpdateExpensesDocument, options);
      }
export type AdminBulkUpdateExpensesMutationHookResult = ReturnType<typeof useAdminBulkUpdateExpensesMutation>;
export type AdminBulkUpdateExpensesMutationResult = Apollo.MutationResult<AdminBulkUpdateExpensesMutation>;
export type AdminBulkUpdateExpensesMutationOptions = Apollo.BaseMutationOptions<AdminBulkUpdateExpensesMutation, AdminBulkUpdateExpensesMutationVariables>;
export const AdminGetEntityIncomeDataDetailDocument = gql`
    query AdminGetEntityIncomeDataDetail($where: IncomeDataWhereInput) {
  incomeData(where: $where) {
    ...AdminIncomeDataDetail
  }
}
    ${AdminIncomeDataDetailFragmentDoc}`;
export function useAdminGetEntityIncomeDataDetailQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>(AdminGetEntityIncomeDataDetailDocument, options);
      }
export function useAdminGetEntityIncomeDataDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>(AdminGetEntityIncomeDataDetailDocument, options);
        }
export function useAdminGetEntityIncomeDataDetailSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>(AdminGetEntityIncomeDataDetailDocument, options);
        }
export type AdminGetEntityIncomeDataDetailQueryHookResult = ReturnType<typeof useAdminGetEntityIncomeDataDetailQuery>;
export type AdminGetEntityIncomeDataDetailLazyQueryHookResult = ReturnType<typeof useAdminGetEntityIncomeDataDetailLazyQuery>;
export type AdminGetEntityIncomeDataDetailSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityIncomeDataDetailSuspenseQuery>;
export type AdminGetEntityIncomeDataDetailQueryResult = Apollo.QueryResult<AdminGetEntityIncomeDataDetailQuery, AdminGetEntityIncomeDataDetailQueryVariables>;
export const AdminUpdateIncomeDataDocument = gql`
    mutation AdminUpdateIncomeData($id: String!, $data: IncomeDataUpdateInput!) {
  updateIncomeData(id: $id, data: $data) {
    ...AdminIncomeDataDetail
  }
}
    ${AdminIncomeDataDetailFragmentDoc}`;
export function useAdminUpdateIncomeDataMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateIncomeDataMutation, AdminUpdateIncomeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateIncomeDataMutation, AdminUpdateIncomeDataMutationVariables>(AdminUpdateIncomeDataDocument, options);
      }
export type AdminUpdateIncomeDataMutationHookResult = ReturnType<typeof useAdminUpdateIncomeDataMutation>;
export type AdminUpdateIncomeDataMutationResult = Apollo.MutationResult<AdminUpdateIncomeDataMutation>;
export type AdminUpdateIncomeDataMutationOptions = Apollo.BaseMutationOptions<AdminUpdateIncomeDataMutation, AdminUpdateIncomeDataMutationVariables>;
export const AdminGetEntityIncomeDataDocument = gql`
    query AdminGetEntityIncomeData($take: Int, $skip: Int, $where: IncomeDataWhereInput, $orderBy: [IncomeDataOrderByWithRelationInput!]) {
  allIncomeData(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
    items {
      ...AdminIncomeDataItem
    }
    count
  }
}
    ${AdminIncomeDataItemFragmentDoc}`;
export function useAdminGetEntityIncomeDataQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>(AdminGetEntityIncomeDataDocument, options);
      }
export function useAdminGetEntityIncomeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>(AdminGetEntityIncomeDataDocument, options);
        }
export function useAdminGetEntityIncomeDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>(AdminGetEntityIncomeDataDocument, options);
        }
export type AdminGetEntityIncomeDataQueryHookResult = ReturnType<typeof useAdminGetEntityIncomeDataQuery>;
export type AdminGetEntityIncomeDataLazyQueryHookResult = ReturnType<typeof useAdminGetEntityIncomeDataLazyQuery>;
export type AdminGetEntityIncomeDataSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityIncomeDataSuspenseQuery>;
export type AdminGetEntityIncomeDataQueryResult = Apollo.QueryResult<AdminGetEntityIncomeDataQuery, AdminGetEntityIncomeDataQueryVariables>;
export const AdminDownloadIncomeDataDocument = gql`
    mutation AdminDownloadIncomeData($id: String!) {
  downloadIncomeData(id: $id)
}
    `;
export function useAdminDownloadIncomeDataMutation(baseOptions?: Apollo.MutationHookOptions<AdminDownloadIncomeDataMutation, AdminDownloadIncomeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDownloadIncomeDataMutation, AdminDownloadIncomeDataMutationVariables>(AdminDownloadIncomeDataDocument, options);
      }
export type AdminDownloadIncomeDataMutationHookResult = ReturnType<typeof useAdminDownloadIncomeDataMutation>;
export type AdminDownloadIncomeDataMutationResult = Apollo.MutationResult<AdminDownloadIncomeDataMutation>;
export type AdminDownloadIncomeDataMutationOptions = Apollo.BaseMutationOptions<AdminDownloadIncomeDataMutation, AdminDownloadIncomeDataMutationVariables>;
export const AdminCreateIncomeDataDocument = gql`
    mutation AdminCreateIncomeData($data: IncomeDataCreateInput!) {
  createIncomeData(data: $data) {
    id
  }
}
    `;
export function useAdminCreateIncomeDataMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateIncomeDataMutation, AdminCreateIncomeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateIncomeDataMutation, AdminCreateIncomeDataMutationVariables>(AdminCreateIncomeDataDocument, options);
      }
export type AdminCreateIncomeDataMutationHookResult = ReturnType<typeof useAdminCreateIncomeDataMutation>;
export type AdminCreateIncomeDataMutationResult = Apollo.MutationResult<AdminCreateIncomeDataMutation>;
export type AdminCreateIncomeDataMutationOptions = Apollo.BaseMutationOptions<AdminCreateIncomeDataMutation, AdminCreateIncomeDataMutationVariables>;
export const AdminGetEntityDocument = gql`
    query AdminGetEntity($where: EntityWhereInput) {
  entity(where: $where) {
    ...AdminEntityDetail
  }
}
    ${AdminEntityDetailFragmentDoc}`;
export function useAdminGetEntityQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityQuery, AdminGetEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityQuery, AdminGetEntityQueryVariables>(AdminGetEntityDocument, options);
      }
export function useAdminGetEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityQuery, AdminGetEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityQuery, AdminGetEntityQueryVariables>(AdminGetEntityDocument, options);
        }
export function useAdminGetEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityQuery, AdminGetEntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityQuery, AdminGetEntityQueryVariables>(AdminGetEntityDocument, options);
        }
export type AdminGetEntityQueryHookResult = ReturnType<typeof useAdminGetEntityQuery>;
export type AdminGetEntityLazyQueryHookResult = ReturnType<typeof useAdminGetEntityLazyQuery>;
export type AdminGetEntitySuspenseQueryHookResult = ReturnType<typeof useAdminGetEntitySuspenseQuery>;
export type AdminGetEntityQueryResult = Apollo.QueryResult<AdminGetEntityQuery, AdminGetEntityQueryVariables>;
export const AdminArchiveEntityDocument = gql`
    mutation AdminArchiveEntity($id: String!) {
  archiveEntity(id: $id) {
    ...AdminEntityDetail
  }
}
    ${AdminEntityDetailFragmentDoc}`;
export function useAdminArchiveEntityMutation(baseOptions?: Apollo.MutationHookOptions<AdminArchiveEntityMutation, AdminArchiveEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminArchiveEntityMutation, AdminArchiveEntityMutationVariables>(AdminArchiveEntityDocument, options);
      }
export type AdminArchiveEntityMutationHookResult = ReturnType<typeof useAdminArchiveEntityMutation>;
export type AdminArchiveEntityMutationResult = Apollo.MutationResult<AdminArchiveEntityMutation>;
export type AdminArchiveEntityMutationOptions = Apollo.BaseMutationOptions<AdminArchiveEntityMutation, AdminArchiveEntityMutationVariables>;
export const AdminUnArchiveEntityDocument = gql`
    mutation AdminUnArchiveEntity($id: String!) {
  unArchiveEntity(id: $id) {
    ...AdminEntityDetail
  }
}
    ${AdminEntityDetailFragmentDoc}`;
export function useAdminUnArchiveEntityMutation(baseOptions?: Apollo.MutationHookOptions<AdminUnArchiveEntityMutation, AdminUnArchiveEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUnArchiveEntityMutation, AdminUnArchiveEntityMutationVariables>(AdminUnArchiveEntityDocument, options);
      }
export type AdminUnArchiveEntityMutationHookResult = ReturnType<typeof useAdminUnArchiveEntityMutation>;
export type AdminUnArchiveEntityMutationResult = Apollo.MutationResult<AdminUnArchiveEntityMutation>;
export type AdminUnArchiveEntityMutationOptions = Apollo.BaseMutationOptions<AdminUnArchiveEntityMutation, AdminUnArchiveEntityMutationVariables>;
export const AdminRemoveEntityUserDocument = gql`
    mutation AdminRemoveEntityUser($id: String!, $userId: String!) {
  removeUserFromEntity(id: $id, userId: $userId)
}
    `;
export function useAdminRemoveEntityUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminRemoveEntityUserMutation, AdminRemoveEntityUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminRemoveEntityUserMutation, AdminRemoveEntityUserMutationVariables>(AdminRemoveEntityUserDocument, options);
      }
export type AdminRemoveEntityUserMutationHookResult = ReturnType<typeof useAdminRemoveEntityUserMutation>;
export type AdminRemoveEntityUserMutationResult = Apollo.MutationResult<AdminRemoveEntityUserMutation>;
export type AdminRemoveEntityUserMutationOptions = Apollo.BaseMutationOptions<AdminRemoveEntityUserMutation, AdminRemoveEntityUserMutationVariables>;
export const AdminCreateInvitationsDocument = gql`
    mutation AdminCreateInvitations($emails: [String!]!, $entityId: String!, $userEntityRole: String!) {
  createInvitations(
    emails: $emails
    entityId: $entityId
    userEntityRole: $userEntityRole
  ) {
    id
  }
}
    `;
export function useAdminCreateInvitationsMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateInvitationsMutation, AdminCreateInvitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateInvitationsMutation, AdminCreateInvitationsMutationVariables>(AdminCreateInvitationsDocument, options);
      }
export type AdminCreateInvitationsMutationHookResult = ReturnType<typeof useAdminCreateInvitationsMutation>;
export type AdminCreateInvitationsMutationResult = Apollo.MutationResult<AdminCreateInvitationsMutation>;
export type AdminCreateInvitationsMutationOptions = Apollo.BaseMutationOptions<AdminCreateInvitationsMutation, AdminCreateInvitationsMutationVariables>;
export const GetInviteMembersDocument = gql`
    query GetInviteMembers($where: UserWhereInput) {
  users(where: $where) {
    items {
      ...InviteMember
    }
  }
}
    ${InviteMemberFragmentDoc}`;
export function useGetInviteMembersQuery(baseOptions?: Apollo.QueryHookOptions<GetInviteMembersQuery, GetInviteMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInviteMembersQuery, GetInviteMembersQueryVariables>(GetInviteMembersDocument, options);
      }
export function useGetInviteMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInviteMembersQuery, GetInviteMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInviteMembersQuery, GetInviteMembersQueryVariables>(GetInviteMembersDocument, options);
        }
export function useGetInviteMembersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInviteMembersQuery, GetInviteMembersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInviteMembersQuery, GetInviteMembersQueryVariables>(GetInviteMembersDocument, options);
        }
export type GetInviteMembersQueryHookResult = ReturnType<typeof useGetInviteMembersQuery>;
export type GetInviteMembersLazyQueryHookResult = ReturnType<typeof useGetInviteMembersLazyQuery>;
export type GetInviteMembersSuspenseQueryHookResult = ReturnType<typeof useGetInviteMembersSuspenseQuery>;
export type GetInviteMembersQueryResult = Apollo.QueryResult<GetInviteMembersQuery, GetInviteMembersQueryVariables>;
export const AdminArchiveInvitationDocument = gql`
    mutation AdminArchiveInvitation($id: String!) {
  archiveInvitation(id: $id)
}
    `;
export function useAdminArchiveInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AdminArchiveInvitationMutation, AdminArchiveInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminArchiveInvitationMutation, AdminArchiveInvitationMutationVariables>(AdminArchiveInvitationDocument, options);
      }
export type AdminArchiveInvitationMutationHookResult = ReturnType<typeof useAdminArchiveInvitationMutation>;
export type AdminArchiveInvitationMutationResult = Apollo.MutationResult<AdminArchiveInvitationMutation>;
export type AdminArchiveInvitationMutationOptions = Apollo.BaseMutationOptions<AdminArchiveInvitationMutation, AdminArchiveInvitationMutationVariables>;
export const AdminGetEntityInvoicesDocument = gql`
    query AdminGetEntityInvoices($take: Int, $skip: Int, $where: InvoiceWhereInput, $orderBy: [InvoiceOrderByWithRelationInput!]) {
  invoices(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
    items {
      ...AdminInvoiceItem
    }
    count
    allItemIds
  }
}
    ${AdminInvoiceItemFragmentDoc}`;
export function useAdminGetEntityInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>(AdminGetEntityInvoicesDocument, options);
      }
export function useAdminGetEntityInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>(AdminGetEntityInvoicesDocument, options);
        }
export function useAdminGetEntityInvoicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>(AdminGetEntityInvoicesDocument, options);
        }
export type AdminGetEntityInvoicesQueryHookResult = ReturnType<typeof useAdminGetEntityInvoicesQuery>;
export type AdminGetEntityInvoicesLazyQueryHookResult = ReturnType<typeof useAdminGetEntityInvoicesLazyQuery>;
export type AdminGetEntityInvoicesSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityInvoicesSuspenseQuery>;
export type AdminGetEntityInvoicesQueryResult = Apollo.QueryResult<AdminGetEntityInvoicesQuery, AdminGetEntityInvoicesQueryVariables>;
export const AdminExportInvoicesDocument = gql`
    mutation AdminExportInvoices($entityId: String!) {
  exportInvoices(entityId: $entityId)
}
    `;
export function useAdminExportInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<AdminExportInvoicesMutation, AdminExportInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminExportInvoicesMutation, AdminExportInvoicesMutationVariables>(AdminExportInvoicesDocument, options);
      }
export type AdminExportInvoicesMutationHookResult = ReturnType<typeof useAdminExportInvoicesMutation>;
export type AdminExportInvoicesMutationResult = Apollo.MutationResult<AdminExportInvoicesMutation>;
export type AdminExportInvoicesMutationOptions = Apollo.BaseMutationOptions<AdminExportInvoicesMutation, AdminExportInvoicesMutationVariables>;
export const AdminArchiveInvoiceDocument = gql`
    mutation AdminArchiveInvoice($id: String!) {
  archiveInvoice(id: $id) {
    ...AdminInvoiceItem
  }
}
    ${AdminInvoiceItemFragmentDoc}`;
export function useAdminArchiveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AdminArchiveInvoiceMutation, AdminArchiveInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminArchiveInvoiceMutation, AdminArchiveInvoiceMutationVariables>(AdminArchiveInvoiceDocument, options);
      }
export type AdminArchiveInvoiceMutationHookResult = ReturnType<typeof useAdminArchiveInvoiceMutation>;
export type AdminArchiveInvoiceMutationResult = Apollo.MutationResult<AdminArchiveInvoiceMutation>;
export type AdminArchiveInvoiceMutationOptions = Apollo.BaseMutationOptions<AdminArchiveInvoiceMutation, AdminArchiveInvoiceMutationVariables>;
export const AdminUnarchiveInvoiceDocument = gql`
    mutation AdminUnarchiveInvoice($id: String!) {
  unarchiveInvoice(id: $id) {
    ...AdminInvoiceItem
  }
}
    ${AdminInvoiceItemFragmentDoc}`;
export function useAdminUnarchiveInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<AdminUnarchiveInvoiceMutation, AdminUnarchiveInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUnarchiveInvoiceMutation, AdminUnarchiveInvoiceMutationVariables>(AdminUnarchiveInvoiceDocument, options);
      }
export type AdminUnarchiveInvoiceMutationHookResult = ReturnType<typeof useAdminUnarchiveInvoiceMutation>;
export type AdminUnarchiveInvoiceMutationResult = Apollo.MutationResult<AdminUnarchiveInvoiceMutation>;
export type AdminUnarchiveInvoiceMutationOptions = Apollo.BaseMutationOptions<AdminUnarchiveInvoiceMutation, AdminUnarchiveInvoiceMutationVariables>;
export const AdminUpdateInvoiceIsProcessedDocument = gql`
    mutation AdminUpdateInvoiceIsProcessed($id: String!, $isProcessed: Boolean!) {
  updateInvoiceIsProcessed(id: $id, isProcessed: $isProcessed) {
    ...AdminInvoiceItem
  }
}
    ${AdminInvoiceItemFragmentDoc}`;
export function useAdminUpdateInvoiceIsProcessedMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateInvoiceIsProcessedMutation, AdminUpdateInvoiceIsProcessedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateInvoiceIsProcessedMutation, AdminUpdateInvoiceIsProcessedMutationVariables>(AdminUpdateInvoiceIsProcessedDocument, options);
      }
export type AdminUpdateInvoiceIsProcessedMutationHookResult = ReturnType<typeof useAdminUpdateInvoiceIsProcessedMutation>;
export type AdminUpdateInvoiceIsProcessedMutationResult = Apollo.MutationResult<AdminUpdateInvoiceIsProcessedMutation>;
export type AdminUpdateInvoiceIsProcessedMutationOptions = Apollo.BaseMutationOptions<AdminUpdateInvoiceIsProcessedMutation, AdminUpdateInvoiceIsProcessedMutationVariables>;
export const AdminBulkUpdateInvoicesDocument = gql`
    mutation AdminBulkUpdateInvoices($entityId: String!, $ids: [String!]!, $data: InvoiceUpdateInput!) {
  updateInvoices(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useAdminBulkUpdateInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkUpdateInvoicesMutation, AdminBulkUpdateInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkUpdateInvoicesMutation, AdminBulkUpdateInvoicesMutationVariables>(AdminBulkUpdateInvoicesDocument, options);
      }
export type AdminBulkUpdateInvoicesMutationHookResult = ReturnType<typeof useAdminBulkUpdateInvoicesMutation>;
export type AdminBulkUpdateInvoicesMutationResult = Apollo.MutationResult<AdminBulkUpdateInvoicesMutation>;
export type AdminBulkUpdateInvoicesMutationOptions = Apollo.BaseMutationOptions<AdminBulkUpdateInvoicesMutation, AdminBulkUpdateInvoicesMutationVariables>;
export const AdminCreateReportDocument = gql`
    mutation AdminCreateReport($data: ReportCreateInput!) {
  createReport(data: $data) {
    id
    year
    quarter
    type
    status
    entityId
    file
  }
}
    `;
export function useAdminCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateReportMutation, AdminCreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateReportMutation, AdminCreateReportMutationVariables>(AdminCreateReportDocument, options);
      }
export type AdminCreateReportMutationHookResult = ReturnType<typeof useAdminCreateReportMutation>;
export type AdminCreateReportMutationResult = Apollo.MutationResult<AdminCreateReportMutation>;
export type AdminCreateReportMutationOptions = Apollo.BaseMutationOptions<AdminCreateReportMutation, AdminCreateReportMutationVariables>;
export const AdminGetEntityReportsDocument = gql`
    query AdminGetEntityReports($take: Int, $skip: Int, $where: ReportWhereInput, $orderBy: [ReportOrderByWithRelationInput!]) {
  reports(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {
    items {
      ...AdminReportItem
    }
    count
    allItemIds
  }
}
    ${AdminReportItemFragmentDoc}`;
export function useAdminGetEntityReportsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>(AdminGetEntityReportsDocument, options);
      }
export function useAdminGetEntityReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>(AdminGetEntityReportsDocument, options);
        }
export function useAdminGetEntityReportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>(AdminGetEntityReportsDocument, options);
        }
export type AdminGetEntityReportsQueryHookResult = ReturnType<typeof useAdminGetEntityReportsQuery>;
export type AdminGetEntityReportsLazyQueryHookResult = ReturnType<typeof useAdminGetEntityReportsLazyQuery>;
export type AdminGetEntityReportsSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntityReportsSuspenseQuery>;
export type AdminGetEntityReportsQueryResult = Apollo.QueryResult<AdminGetEntityReportsQuery, AdminGetEntityReportsQueryVariables>;
export const AdminBulkUpdateReportsDocument = gql`
    mutation AdminBulkUpdateReports($entityId: String!, $ids: [String!]!, $data: ReportUpdateInput!) {
  updateReports(entityId: $entityId, ids: $ids, data: $data)
}
    `;
export function useAdminBulkUpdateReportsMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkUpdateReportsMutation, AdminBulkUpdateReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkUpdateReportsMutation, AdminBulkUpdateReportsMutationVariables>(AdminBulkUpdateReportsDocument, options);
      }
export type AdminBulkUpdateReportsMutationHookResult = ReturnType<typeof useAdminBulkUpdateReportsMutation>;
export type AdminBulkUpdateReportsMutationResult = Apollo.MutationResult<AdminBulkUpdateReportsMutation>;
export type AdminBulkUpdateReportsMutationOptions = Apollo.BaseMutationOptions<AdminBulkUpdateReportsMutation, AdminBulkUpdateReportsMutationVariables>;
export const AdminArchiveReportDocument = gql`
    mutation AdminArchiveReport($id: String!) {
  archiveReport(id: $id) {
    ...AdminReportItem
  }
}
    ${AdminReportItemFragmentDoc}`;
export function useAdminArchiveReportMutation(baseOptions?: Apollo.MutationHookOptions<AdminArchiveReportMutation, AdminArchiveReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminArchiveReportMutation, AdminArchiveReportMutationVariables>(AdminArchiveReportDocument, options);
      }
export type AdminArchiveReportMutationHookResult = ReturnType<typeof useAdminArchiveReportMutation>;
export type AdminArchiveReportMutationResult = Apollo.MutationResult<AdminArchiveReportMutation>;
export type AdminArchiveReportMutationOptions = Apollo.BaseMutationOptions<AdminArchiveReportMutation, AdminArchiveReportMutationVariables>;
export const AdminUnarchiveReportDocument = gql`
    mutation AdminUnarchiveReport($id: String!) {
  unarchiveReport(id: $id) {
    ...AdminReportItem
  }
}
    ${AdminReportItemFragmentDoc}`;
export function useAdminUnarchiveReportMutation(baseOptions?: Apollo.MutationHookOptions<AdminUnarchiveReportMutation, AdminUnarchiveReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUnarchiveReportMutation, AdminUnarchiveReportMutationVariables>(AdminUnarchiveReportDocument, options);
      }
export type AdminUnarchiveReportMutationHookResult = ReturnType<typeof useAdminUnarchiveReportMutation>;
export type AdminUnarchiveReportMutationResult = Apollo.MutationResult<AdminUnarchiveReportMutation>;
export type AdminUnarchiveReportMutationOptions = Apollo.BaseMutationOptions<AdminUnarchiveReportMutation, AdminUnarchiveReportMutationVariables>;
export const AdminUpdateReportDocument = gql`
    mutation AdminUpdateReport($data: ReportUpdateInput!, $id: String!) {
  updateReport(data: $data, id: $id) {
    ...AdminReportItem
  }
}
    ${AdminReportItemFragmentDoc}`;
export function useAdminUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateReportMutation, AdminUpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateReportMutation, AdminUpdateReportMutationVariables>(AdminUpdateReportDocument, options);
      }
export type AdminUpdateReportMutationHookResult = ReturnType<typeof useAdminUpdateReportMutation>;
export type AdminUpdateReportMutationResult = Apollo.MutationResult<AdminUpdateReportMutation>;
export type AdminUpdateReportMutationOptions = Apollo.BaseMutationOptions<AdminUpdateReportMutation, AdminUpdateReportMutationVariables>;
export const AdminGetEntitiesDocument = gql`
    query AdminGetEntities($take: Int, $skip: Int, $orderBy: [EntityOrderByWithRelationInput!], $where: EntityWhereInput) {
  entities(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
    items {
      ...AdminEntityItem
    }
    count
  }
}
    ${AdminEntityItemFragmentDoc}`;
export function useAdminGetEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>(AdminGetEntitiesDocument, options);
      }
export function useAdminGetEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>(AdminGetEntitiesDocument, options);
        }
export function useAdminGetEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>(AdminGetEntitiesDocument, options);
        }
export type AdminGetEntitiesQueryHookResult = ReturnType<typeof useAdminGetEntitiesQuery>;
export type AdminGetEntitiesLazyQueryHookResult = ReturnType<typeof useAdminGetEntitiesLazyQuery>;
export type AdminGetEntitiesSuspenseQueryHookResult = ReturnType<typeof useAdminGetEntitiesSuspenseQuery>;
export type AdminGetEntitiesQueryResult = Apollo.QueryResult<AdminGetEntitiesQuery, AdminGetEntitiesQueryVariables>;
export const AdminGetUserEntitiesDocument = gql`
    query AdminGetUserEntities($where: EntityWhereInput) {
  entities(where: $where) {
    items {
      ...AdminUserEntity
    }
  }
}
    ${AdminUserEntityFragmentDoc}`;
export function useAdminGetUserEntitiesQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>(AdminGetUserEntitiesDocument, options);
      }
export function useAdminGetUserEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>(AdminGetUserEntitiesDocument, options);
        }
export function useAdminGetUserEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>(AdminGetUserEntitiesDocument, options);
        }
export type AdminGetUserEntitiesQueryHookResult = ReturnType<typeof useAdminGetUserEntitiesQuery>;
export type AdminGetUserEntitiesLazyQueryHookResult = ReturnType<typeof useAdminGetUserEntitiesLazyQuery>;
export type AdminGetUserEntitiesSuspenseQueryHookResult = ReturnType<typeof useAdminGetUserEntitiesSuspenseQuery>;
export type AdminGetUserEntitiesQueryResult = Apollo.QueryResult<AdminGetUserEntitiesQuery, AdminGetUserEntitiesQueryVariables>;
export const AdminGetUserDocument = gql`
    query AdminGetUser($where: UserWhereInput) {
  user(where: $where) {
    ...AdminUserDetail
  }
}
    ${AdminUserDetailFragmentDoc}`;
export function useAdminGetUserQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
      }
export function useAdminGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
        }
export function useAdminGetUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetUserQuery, AdminGetUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetUserQuery, AdminGetUserQueryVariables>(AdminGetUserDocument, options);
        }
export type AdminGetUserQueryHookResult = ReturnType<typeof useAdminGetUserQuery>;
export type AdminGetUserLazyQueryHookResult = ReturnType<typeof useAdminGetUserLazyQuery>;
export type AdminGetUserSuspenseQueryHookResult = ReturnType<typeof useAdminGetUserSuspenseQuery>;
export type AdminGetUserQueryResult = Apollo.QueryResult<AdminGetUserQuery, AdminGetUserQueryVariables>;
export const AdminUpdateUserDocument = gql`
    mutation AdminUpdateUser($id: String!, $data: UserUpdateInput!) {
  updateUser(id: $id, data: $data) {
    ...AdminUserDetail
  }
}
    ${AdminUserDetailFragmentDoc}`;
export function useAdminUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>(AdminUpdateUserDocument, options);
      }
export type AdminUpdateUserMutationHookResult = ReturnType<typeof useAdminUpdateUserMutation>;
export type AdminUpdateUserMutationResult = Apollo.MutationResult<AdminUpdateUserMutation>;
export type AdminUpdateUserMutationOptions = Apollo.BaseMutationOptions<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>;
export const AdminDeleteUserDocument = gql`
    mutation AdminDeleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export function useAdminDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>(AdminDeleteUserDocument, options);
      }
export type AdminDeleteUserMutationHookResult = ReturnType<typeof useAdminDeleteUserMutation>;
export type AdminDeleteUserMutationResult = Apollo.MutationResult<AdminDeleteUserMutation>;
export type AdminDeleteUserMutationOptions = Apollo.BaseMutationOptions<AdminDeleteUserMutation, AdminDeleteUserMutationVariables>;
export const AdminGetUserNotificationSettingsDocument = gql`
    query AdminGetUserNotificationSettings($where: UserWhereInput) {
  user(where: $where) {
    ...AdminUserNotifcationSettings
  }
}
    ${AdminUserNotifcationSettingsFragmentDoc}`;
export function useAdminGetUserNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>(AdminGetUserNotificationSettingsDocument, options);
      }
export function useAdminGetUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>(AdminGetUserNotificationSettingsDocument, options);
        }
export function useAdminGetUserNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>(AdminGetUserNotificationSettingsDocument, options);
        }
export type AdminGetUserNotificationSettingsQueryHookResult = ReturnType<typeof useAdminGetUserNotificationSettingsQuery>;
export type AdminGetUserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useAdminGetUserNotificationSettingsLazyQuery>;
export type AdminGetUserNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useAdminGetUserNotificationSettingsSuspenseQuery>;
export type AdminGetUserNotificationSettingsQueryResult = Apollo.QueryResult<AdminGetUserNotificationSettingsQuery, AdminGetUserNotificationSettingsQueryVariables>;
export const GetAdminUserTasksDocument = gql`
    query GetAdminUserTasks($where: UserWhereInput, $userId: String!) {
  user(where: $where) {
    ...AdminUserTasks
  }
}
    ${AdminUserTasksFragmentDoc}`;
export function useGetAdminUserTasksQuery(baseOptions: Apollo.QueryHookOptions<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables> & ({ variables: GetAdminUserTasksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables>(GetAdminUserTasksDocument, options);
      }
export function useGetAdminUserTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables>(GetAdminUserTasksDocument, options);
        }
export function useGetAdminUserTasksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables>(GetAdminUserTasksDocument, options);
        }
export type GetAdminUserTasksQueryHookResult = ReturnType<typeof useGetAdminUserTasksQuery>;
export type GetAdminUserTasksLazyQueryHookResult = ReturnType<typeof useGetAdminUserTasksLazyQuery>;
export type GetAdminUserTasksSuspenseQueryHookResult = ReturnType<typeof useGetAdminUserTasksSuspenseQuery>;
export type GetAdminUserTasksQueryResult = Apollo.QueryResult<GetAdminUserTasksQuery, GetAdminUserTasksQueryVariables>;
export const GetUserUpdatesDocument = gql`
    query GetUserUpdates($take: Int, $skip: Int, $orderBy: [UpdateOrderByWithRelationInput!], $where: UserWhereInput) {
  user(where: $where) {
    id
    fullName
    updates(take: $take, skip: $skip, orderBy: $orderBy) {
      items {
        ...UpdateOverview
      }
      count
    }
  }
}
    ${UpdateOverviewFragmentDoc}`;
export function useGetUserUpdatesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>(GetUserUpdatesDocument, options);
      }
export function useGetUserUpdatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>(GetUserUpdatesDocument, options);
        }
export function useGetUserUpdatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>(GetUserUpdatesDocument, options);
        }
export type GetUserUpdatesQueryHookResult = ReturnType<typeof useGetUserUpdatesQuery>;
export type GetUserUpdatesLazyQueryHookResult = ReturnType<typeof useGetUserUpdatesLazyQuery>;
export type GetUserUpdatesSuspenseQueryHookResult = ReturnType<typeof useGetUserUpdatesSuspenseQuery>;
export type GetUserUpdatesQueryResult = Apollo.QueryResult<GetUserUpdatesQuery, GetUserUpdatesQueryVariables>;
export const AdminGetUsersDocument = gql`
    query AdminGetUsers($take: Int, $skip: Int, $orderBy: [UserOrderByWithRelationInput!], $where: UserWhereInput) {
  users(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
    items {
      ...AdminUserItem
    }
    count
  }
}
    ${AdminUserItemFragmentDoc}`;
export function useAdminGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, options);
      }
export function useAdminGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, options);
        }
export function useAdminGetUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminGetUsersQuery, AdminGetUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminGetUsersQuery, AdminGetUsersQueryVariables>(AdminGetUsersDocument, options);
        }
export type AdminGetUsersQueryHookResult = ReturnType<typeof useAdminGetUsersQuery>;
export type AdminGetUsersLazyQueryHookResult = ReturnType<typeof useAdminGetUsersLazyQuery>;
export type AdminGetUsersSuspenseQueryHookResult = ReturnType<typeof useAdminGetUsersSuspenseQuery>;
export type AdminGetUsersQueryResult = Apollo.QueryResult<AdminGetUsersQuery, AdminGetUsersQueryVariables>;
export const RefreshTokenDocument = gql`
    query RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
  }
}
    `;
export function useRefreshTokenQuery(baseOptions: Apollo.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables> & ({ variables: RefreshTokenQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
      }
export function useRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export function useRefreshTokenSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenSuspenseQueryHookResult = ReturnType<typeof useRefreshTokenSuspenseQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const GetReportDocument = gql`
    query GetReport($where: ReportWhereInput) {
  report(where: $where) {
    ...ReportOverview
  }
}
    ${ReportOverviewFragmentDoc}`;
export function useGetReportQuery(baseOptions?: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export function useGetReportSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportSuspenseQueryHookResult = ReturnType<typeof useGetReportSuspenseQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetAttachmentDocument = gql`
    query GetAttachment($where: AttachmentWhereInput) {
  attachment(where: $where) {
    ...AttachmentOverview
  }
}
    ${AttachmentOverviewFragmentDoc}`;
export function useGetAttachmentQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, options);
      }
export function useGetAttachmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, options);
        }
export function useGetAttachmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAttachmentQuery, GetAttachmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAttachmentQuery, GetAttachmentQueryVariables>(GetAttachmentDocument, options);
        }
export type GetAttachmentQueryHookResult = ReturnType<typeof useGetAttachmentQuery>;
export type GetAttachmentLazyQueryHookResult = ReturnType<typeof useGetAttachmentLazyQuery>;
export type GetAttachmentSuspenseQueryHookResult = ReturnType<typeof useGetAttachmentSuspenseQuery>;
export type GetAttachmentQueryResult = Apollo.QueryResult<GetAttachmentQuery, GetAttachmentQueryVariables>;
export const GetBankStatementDocument = gql`
    query GetBankStatement($where: BankStatementWhereInput) {
  bankStatement(where: $where) {
    ...BankStatementOverview
  }
}
    ${BankStatementOverviewFragmentDoc}`;
export function useGetBankStatementQuery(baseOptions?: Apollo.QueryHookOptions<GetBankStatementQuery, GetBankStatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBankStatementQuery, GetBankStatementQueryVariables>(GetBankStatementDocument, options);
      }
export function useGetBankStatementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBankStatementQuery, GetBankStatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBankStatementQuery, GetBankStatementQueryVariables>(GetBankStatementDocument, options);
        }
export function useGetBankStatementSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetBankStatementQuery, GetBankStatementQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBankStatementQuery, GetBankStatementQueryVariables>(GetBankStatementDocument, options);
        }
export type GetBankStatementQueryHookResult = ReturnType<typeof useGetBankStatementQuery>;
export type GetBankStatementLazyQueryHookResult = ReturnType<typeof useGetBankStatementLazyQuery>;
export type GetBankStatementSuspenseQueryHookResult = ReturnType<typeof useGetBankStatementSuspenseQuery>;
export type GetBankStatementQueryResult = Apollo.QueryResult<GetBankStatementQuery, GetBankStatementQueryVariables>;
export const GetIncomeDataDocument = gql`
    query GetIncomeData($where: IncomeDataWhereInput) {
  incomeData(where: $where) {
    ...IncomeDataDetail
  }
}
    ${IncomeDataDetailFragmentDoc}`;
export function useGetIncomeDataQuery(baseOptions?: Apollo.QueryHookOptions<GetIncomeDataQuery, GetIncomeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomeDataQuery, GetIncomeDataQueryVariables>(GetIncomeDataDocument, options);
      }
export function useGetIncomeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomeDataQuery, GetIncomeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomeDataQuery, GetIncomeDataQueryVariables>(GetIncomeDataDocument, options);
        }
export function useGetIncomeDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetIncomeDataQuery, GetIncomeDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIncomeDataQuery, GetIncomeDataQueryVariables>(GetIncomeDataDocument, options);
        }
export type GetIncomeDataQueryHookResult = ReturnType<typeof useGetIncomeDataQuery>;
export type GetIncomeDataLazyQueryHookResult = ReturnType<typeof useGetIncomeDataLazyQuery>;
export type GetIncomeDataSuspenseQueryHookResult = ReturnType<typeof useGetIncomeDataSuspenseQuery>;
export type GetIncomeDataQueryResult = Apollo.QueryResult<GetIncomeDataQuery, GetIncomeDataQueryVariables>;
export const UpdateIncomeDataDocument = gql`
    mutation UpdateIncomeData($id: String!, $data: IncomeDataUpdateInput!) {
  updateIncomeData(id: $id, data: $data) {
    ...IncomeDataDetail
  }
}
    ${IncomeDataDetailFragmentDoc}`;
export function useUpdateIncomeDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIncomeDataMutation, UpdateIncomeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIncomeDataMutation, UpdateIncomeDataMutationVariables>(UpdateIncomeDataDocument, options);
      }
export type UpdateIncomeDataMutationHookResult = ReturnType<typeof useUpdateIncomeDataMutation>;
export type UpdateIncomeDataMutationResult = Apollo.MutationResult<UpdateIncomeDataMutation>;
export type UpdateIncomeDataMutationOptions = Apollo.BaseMutationOptions<UpdateIncomeDataMutation, UpdateIncomeDataMutationVariables>;
export const CreateManyAttachmentsDocument = gql`
    mutation CreateManyAttachments($data: [AttachmentCreateManyInput!]!) {
  createManyAttachments(data: $data)
}
    `;
export function useCreateManyAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyAttachmentsMutation, CreateManyAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyAttachmentsMutation, CreateManyAttachmentsMutationVariables>(CreateManyAttachmentsDocument, options);
      }
export type CreateManyAttachmentsMutationHookResult = ReturnType<typeof useCreateManyAttachmentsMutation>;
export type CreateManyAttachmentsMutationResult = Apollo.MutationResult<CreateManyAttachmentsMutation>;
export type CreateManyAttachmentsMutationOptions = Apollo.BaseMutationOptions<CreateManyAttachmentsMutation, CreateManyAttachmentsMutationVariables>;
export const ArchiveAttachmentDocument = gql`
    mutation ArchiveAttachment($id: String!) {
  archiveAttachment(id: $id) {
    ...AdminAttachmentItem
  }
}
    ${AdminAttachmentItemFragmentDoc}`;
export function useArchiveAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveAttachmentMutation, ArchiveAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveAttachmentMutation, ArchiveAttachmentMutationVariables>(ArchiveAttachmentDocument, options);
      }
export type ArchiveAttachmentMutationHookResult = ReturnType<typeof useArchiveAttachmentMutation>;
export type ArchiveAttachmentMutationResult = Apollo.MutationResult<ArchiveAttachmentMutation>;
export type ArchiveAttachmentMutationOptions = Apollo.BaseMutationOptions<ArchiveAttachmentMutation, ArchiveAttachmentMutationVariables>;
export const IsCurrentUserManagerDocument = gql`
    query IsCurrentUserManager($where: EntityWhereInput) {
  entity(where: $where) {
    id
    isCurrentUserManager
    type
  }
}
    `;
export function useIsCurrentUserManagerQuery(baseOptions?: Apollo.QueryHookOptions<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>(IsCurrentUserManagerDocument, options);
      }
export function useIsCurrentUserManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>(IsCurrentUserManagerDocument, options);
        }
export function useIsCurrentUserManagerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>(IsCurrentUserManagerDocument, options);
        }
export type IsCurrentUserManagerQueryHookResult = ReturnType<typeof useIsCurrentUserManagerQuery>;
export type IsCurrentUserManagerLazyQueryHookResult = ReturnType<typeof useIsCurrentUserManagerLazyQuery>;
export type IsCurrentUserManagerSuspenseQueryHookResult = ReturnType<typeof useIsCurrentUserManagerSuspenseQuery>;
export type IsCurrentUserManagerQueryResult = Apollo.QueryResult<IsCurrentUserManagerQuery, IsCurrentUserManagerQueryVariables>;
export const GetEntityDocument = gql`
    query GetEntity($where: EntityWhereInput) {
  entity(where: $where) {
    ...EntityOverview
  }
}
    ${EntityOverviewFragmentDoc}`;
export function useGetEntityQuery(baseOptions?: Apollo.QueryHookOptions<GetEntityQuery, GetEntityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityQuery, GetEntityQueryVariables>(GetEntityDocument, options);
      }
export function useGetEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityQuery, GetEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityQuery, GetEntityQueryVariables>(GetEntityDocument, options);
        }
export function useGetEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEntityQuery, GetEntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityQuery, GetEntityQueryVariables>(GetEntityDocument, options);
        }
export type GetEntityQueryHookResult = ReturnType<typeof useGetEntityQuery>;
export type GetEntityLazyQueryHookResult = ReturnType<typeof useGetEntityLazyQuery>;
export type GetEntitySuspenseQueryHookResult = ReturnType<typeof useGetEntitySuspenseQuery>;
export type GetEntityQueryResult = Apollo.QueryResult<GetEntityQuery, GetEntityQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetInvitationDocument = gql`
    query GetInvitation($id: String!) {
  invitation(id: $id) {
    id
    email
    entityName
    createdAt
    updatedAt
  }
}
    `;
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables> & ({ variables: GetInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export function useGetInvitationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationSuspenseQueryHookResult = ReturnType<typeof useGetInvitationSuspenseQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($data: RegisterInput!, $code: String!) {
  register(data: $data, code: $code) {
    user {
      ...Me
    }
    token
    refreshToken
  }
}
    ${MeFragmentDoc}`;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const VerifyPhoneDocument = gql`
    mutation VerifyPhone($phone: String!) {
  verifyPhone(phone: $phone)
}
    `;
export function useVerifyPhoneMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPhoneMutation, VerifyPhoneMutationVariables>(VerifyPhoneDocument, options);
      }
export type VerifyPhoneMutationHookResult = ReturnType<typeof useVerifyPhoneMutation>;
export type VerifyPhoneMutationResult = Apollo.MutationResult<VerifyPhoneMutation>;
export type VerifyPhoneMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneMutation, VerifyPhoneMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!, $code: String) {
  login(data: $data, code: $code) {
    user {
      ...Me
    }
    token
    refreshToken
  }
}
    ${MeFragmentDoc}`;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const SendVerificationDocument = gql`
    mutation SendVerification($data: LoginInput!) {
  sendVerification(data: $data) {
    skip2fa
  }
}
    `;
export function useSendVerificationMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationMutation, SendVerificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationMutation, SendVerificationMutationVariables>(SendVerificationDocument, options);
      }
export type SendVerificationMutationHookResult = ReturnType<typeof useSendVerificationMutation>;
export type SendVerificationMutationResult = Apollo.MutationResult<SendVerificationMutation>;
export type SendVerificationMutationOptions = Apollo.BaseMutationOptions<SendVerificationMutation, SendVerificationMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($data: UserUpdateInput!) {
  updateMe(data: $data) {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const GetUserNotificationSettingsDocument = gql`
    query GetUserNotificationSettings {
  me {
    ...UserNotifcationSettings
  }
}
    ${UserNotifcationSettingsFragmentDoc}`;
export function useGetUserNotificationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
      }
export function useGetUserNotificationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
        }
export function useGetUserNotificationSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>(GetUserNotificationSettingsDocument, options);
        }
export type GetUserNotificationSettingsQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsQuery>;
export type GetUserNotificationSettingsLazyQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsLazyQuery>;
export type GetUserNotificationSettingsSuspenseQueryHookResult = ReturnType<typeof useGetUserNotificationSettingsSuspenseQuery>;
export type GetUserNotificationSettingsQueryResult = Apollo.QueryResult<GetUserNotificationSettingsQuery, GetUserNotificationSettingsQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data)
}
    `;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;